import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/front/standard/index",
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login/QrCodeLogin.vue"),
    },
    {
        path: "/front",
        name: "front",
        component: () => import("../Front.vue"),
        children: [
            {
                path: "standard/index",
                name: "standard-index",
                component: () =>
                    import("../views/FrontSkin/01-Standard/Index/Index.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "招聘系统",
                },
            },
            {
                path: "posts",
                name: "front-posts",
                component: () => import("../views/Posts/Posts.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "岗位列表",
                },
            },
            {
                path: "posts-ceshi",
                name: "front-posts-ceshi",
                component: () => import("../views/Posts/Posts.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "岗位列表",
                },
            },
            {
                path: "front-dimapage/:pageId",
                name: "front-dimapage",
                component: () => import("../views/Dima/Dima.vue"),
                props: true,
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                },
            },
            {
                path: "enterPersonal",
                name: "enterPersonal",
                component: () => import("../views/EnterPersonal/Index2.vue"),
                // props: true,
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "个人中心",
                },
            },
            // {
            //     path: "enterPersonal2",
            //     name: "enterPersonal2",
            //     component: () => import("../views/EnterPersonal/Index2.vue"),
            //     // props: true,
            //     meta: {
            //         navComponent: "standard-nav",
            //         contactComponent: "standard-contact",
            //         title: "个人中心",
            //     },
            // },
            {
                path: "MsgNotice",
                name: "msgNotice",
                component: () => import("../views/MsgNotice/MsgNotice.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "消息通知",
                },
            },
            {
                path: "DeliverRecord",
                name: "deliverRecord",
                component: () =>
                    import("../views/DeliverRecord/DeliverRecord.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "投岗记录",
                },
            },
            {
                path: "questionsPage",
                name: "questionsPage",
                props: true,
                component: () =>
                    import("../views/QuestionsPage/QuestionsPage.vue"),
                meta: {
                    navComponent: "standard-nav",
                    contactComponent: "standard-contact",
                    title: "留言板",
                },
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    Store.dispatch("user/initLoginUser")
    .then(next) 
    .catch((err) => {
        console.error(err);
    });
});

router.afterEach((to, from, next) => {
    let title = "";
    if (to.name === "standard-index") {
        title = Store.getters.systemName || to?.meta?.title;
    } else {
        title = Store.getters.pageName || to?.meta?.title || "招聘系统";
    }
    if (title) {
        document.title = title;
    }
});

export default router;
