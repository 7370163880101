import { render, staticRenderFns } from "./KqParamConfig.vue?vue&type=template&id=5eb84041&scoped=true&"
import script from "./KqParamConfig.vue?vue&type=script&lang=js&"
export * from "./KqParamConfig.vue?vue&type=script&lang=js&"
import style0 from "./KqParamConfig.vue?vue&type=style&index=0&id=5eb84041&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../kooci-saas-recruit/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb84041",
  null
  
)

export default component.exports