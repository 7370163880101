var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'kq-search-scale v-bg-bg': true,
        'kq-search-scale--active v-primary-border-color v-primary-color':
            _vm.searchActive,
        'v-label-color v-bg-border-color': !_vm.searchActive,
        'kq-search-scale--circle': _vm.size === 'circle',
    },on:{"mouseenter":function($event){_vm.searchEnter = true},"mouseleave":function($event){_vm.searchEnter = false}}},[_c('i',{staticClass:"iconfont icon-search-line"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.current),expression:"current"}],ref:"input",staticClass:"v-title-color",attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":(_vm.current)},on:{"focus":function($event){_vm.searchInputFocus = true},"blur":_vm.blur,"input":[function($event){if($event.target.composing)return;_vm.current=$event.target.value},function($event){return _vm.$emit('change', _vm.current)}]}})])
}
var staticRenderFns = []

export { render, staticRenderFns }