/* eslint-disable */

/**
 * 字段类型相关配置
 * @see 封装了常用的判断函数
 * @see 传递字段对象可以直接获取字段类型与判断
 * @author tonny
 * @date 2021-10-09
 */
export default {
    /** 类型列表 */
    typeList: [
        /** 文本-字符型 */
        "text-text", // 0
        "text-big", // 1
        "text-number", // 2
        "text-number-money", // 3
        "text-news", // 4
        "text-encrypt", // 5
        "review", // 6
        "date", // 7
        "select-single-1-switch", // 8
        "select-single-1", // 9
        "select-multi-1", // 10
        "select-single-2", // 11
        "select-multi-2", // 12
        "file-image-single", // 13
        "file-file-multi", // 14
        "file-file-single", // 15
        "rowset", // 16
        "file-signature", // 17
        "rowset-fushu", // 18 附属行集
        "color", // 19 颜色
        "icon", // 20 图标
        "code", // 21 代码
        "select-single-input", // 22 码表输入型-单选
        "select-multi-input", // 23 码表输入型-多选
    ],
    /**
     * 获取字段类型
     * @param {string|number|object} nodeType string<number>: nodeType, string:类型, object:字段对象
     * @param {string|number} [checkId] 字段类型2
     * @returns {string} 成功返回字段类型字符串,失败返回""
     */
    getType(nodeType, checkId) {
        const params = this._paramConvert(nodeType, checkId);
        if (!params) {
            console.warn(
                new kq.Err({
                    type: false,
                    message:
                        "字段类型转换失败,参数中不包含checkId,nodeType类型",
                    console: false,
                    current: arguments,
                    from: "FormConfig.getType",
                })
            );
            return "";
        }
        nodeType = params.nodeType;
        checkId = params.checkId;
        if (params.type && (nodeType === undefined || checkId === undefined)) {
            return params.type;
        }
        switch (nodeType) {
            case this.nodeType.INPUT:
            case this.nodeType.INPUT_SYS:
                switch (checkId) {
                    case this.checkId.TEXT:
                        return this.typeList[0];
                    case this.checkId.TEXT_BIG:
                        return this.typeList[1];
                    case this.checkId.NUMBER:
                        return this.typeList[2];
                    case this.checkId.NUMBER_MONEY:
                        return this.typeList[3];
                    case this.checkId.NEWS:
                        return this.typeList[4];
                    case this.checkId.ENCRYPT:
                        return this.typeList[5];
                    case this.checkId.REVIEW:
                        return this.typeList[6];
                    case this.checkId.DATE:
                        return this.typeList[7];
                    case this.checkId.SIGNATURE:
                        return this.typeList[17];
                    case this.checkId.COLOR:
                        return this.typeList[19];
                    case this.checkId.ICON:
                        return this.typeList[20];
                    case this.checkId.CODE:
                        return this.typeList[21];
                    default:
                        break;
                }
            case this.nodeType.SELECT_SINGLE_LEVEL:
                switch (checkId) {
                    case this.checkId.SELECT_SINGLE_SWITCH:
                        return this.typeList[8];
                    case this.checkId.SELECT_SINGLE:
                        return this.typeList[9];
                    case this.checkId.SELECT_MULTI:
                        return this.typeList[10];
                    default:
                        break;
                }
            case this.nodeType.SELECT_MULTI_LEVEL:
                switch (checkId) {
                    case this.checkId.SELECT_SINGLE:
                        return this.typeList[11];
                    case this.checkId.SELECT_MULTI:
                        return this.typeList[12];
                    default:
                        break;
                }
            case this.nodeType.FILE:
                switch (checkId) {
                    case this.checkId.FILE_IMG:
                        return this.typeList[13];
                    case this.checkId.FILE_MULTI:
                        return this.typeList[14];
                    case this.checkId.FILE_SINGLE:
                        return this.typeList[15];
                    default:
                        break;
                }
            case this.nodeType.ROWSET:
                return checkId === this.checkId.ROWSET_F
                    ? this.typeList[18]
                    : this.typeList[16];
            default:
                break;
        }
        return "";
    },
    /**
     * 是否为字符型
     * @param {string|number|object} nodeType 字段类型1
     * @param {string|number} [checkId] 字段类型2
     * @returns {boolean} ture:是, false: 不是
     */
    isString(nodeType, checkId) {
        const params = this._paramConvert(nodeType, checkId);
        if (!params) {
            return false;
        }
        if (params.nodeType !== undefined && params.checkId !== undefined) {
            return (
                [this.nodeType.INPUT, this.nodeType.INPUT_SYS].some(
                    ntype => ntype == params.nodeType
                ) &&
                [
                    this.checkId.TEXT_BIG,
                    this.checkId.TEXT,
                    this.checkId.REVIEW,
                ].some(n => n == params.checkId)
            );
        }
        if (params.type) {
            return ["text"].some(type2 => String(params.type).includes(type2));
        }
    },
    /**
     * 是否为码表型
     * @param {string|number|object} nodeType 字段类型1
     * @param {string|number} [checkId] 字段类型2
     * @returns {boolean} ture:是, false: 不是
     */
    isSelect(nodeType, checkId) {
        const params = this._paramConvert(nodeType, checkId);
        if (!params) {
            return false;
        }
        if (params.nodeType !== undefined && params.checkId !== undefined) {
            return [
                this.checkId.SELECT_MULTI,
                this.checkId.SELECT_SINGLE,
                this.checkId.SELECT_SINGLE_SWITCH,
            ].some(n => n == params.checkId);
        }
        if (params.type) {
            return ["select", "cascader"].some(type2 =>
                String(params.type).includes(type2)
            );
        }
    },
    /**
     * 是否为终审型
     * @param {string|number|object} nodeType 字段类型1
     * @param {string|number} [checkId] 字段类型2
     * @returns {boolean} ture:是, false: 不是
     */
     isReviewEnd(nodeType, checkId) {
      const params = this._paramConvert(nodeType, checkId);
      if (!params) {
          return false;
      }
      if (params.nodeType !== undefined && params.checkId !== undefined) {
          return (
              (params.nodeType === this.nodeType.INPUT ||
                  params.nodeType === this.nodeType.INPUT_SYS) &&
              params.checkId == this.checkId.REVIEW
          );
      }
      if (params.type) {
          return ["end_audit"].some(type2 =>
              String(params.type).includes(type2)
          );
      }
  },
    /**
     * 是否为数字
     * @param {string|number|object} nodeType 字段类型1
     * @param {string|number} [checkId] 字段类型2
     * @returns {boolean} ture:是, false: 不是
     */
    isNumber(nodeType, checkId) {
        const params = this._paramConvert(nodeType, checkId);
        if (!params) {
            return false;
        }
        if (params.nodeType !== undefined && params.checkId !== undefined) {
            return (
                params.nodeType === this.nodeType.INPUT &&
                (params.checkId == this.checkId.NUMBER ||
                    params.checkId === this.checkId.NUMBER_MONEY)
            );
        }
        if (params.type) {
            return ["InputNumber", "number"].some(type2 =>
                String(params.type).includes(type2)
            );
        }
    },
    /**
     * 是否为行级
     * @param {string|number|object} nodeType 字段类型1
     * @param {string|number} [checkId] 字段类型2
     * @returns {boolean} ture:是, false: 不是
     */
    isRowset(nodeType, checkId) {
        const params = this._paramConvert(nodeType, checkId);
        if (!params) {
            return false;
        }
        if (params.nodeType !== undefined && params.checkId !== undefined) {
            return params.nodeType === this.nodeType.ROWSET;
        }
        if (params.type) {
            return ["rowset", "rowset-fushu", "table"].some(type2 =>
                String(params.type).includes(type2)
            );
        }
    },
    /**
     * 是否为日期
     * @param {string|number|object} nodeType 字段类型1
     * @param {string|number} [checkId] 字段类型2
     * @returns {boolean} ture:是, false: 不是
     */
    isDate(nodeType, checkId) {
        const params = this._paramConvert(nodeType, checkId);
        if (!params) {
            return false;
        }
        if (params.nodeType !== undefined && params.checkId !== undefined) {
            return (
                params.nodeType === this.nodeType.INPUT &&
                params.checkId == this.checkId.DATE
            );
        }
        if (params.type) {
            return ["DatePicker", "date"].some(type2 =>
                String(params.type).includes(type2)
            );
        }
    },
    /**
     * 是否为审核型
     * @param {string|number|object} nodeType 字段类型1(type转换后类型, checkId, 字段对象)
     * @param {string|number} [checkId] 字段类型2
     * @returns {boolean} ture:是, false: 不是
     */
    isReview(nodeType, checkId) {
        const params = this._paramConvert(nodeType, checkId);
        if (!params) {
            return false;
        }
        if (params.nodeType !== undefined && params.checkId !== undefined) {
            return (
                (params.nodeType === this.nodeType.INPUT ||
                    params.nodeType === this.nodeType.INPUT_SYS) &&
                params.checkId == this.checkId.REVIEW
            );
        }
        if (params.type) {
            return ["review", "audit"].some(type2 =>
                String(params.type).includes(type2)
            );
        }
    },
    /**
     * 是否为附件型
     * @param {string|number|object} nodeType 字段类型1(type转换后类型, checkId, 字段对象)
     * @param {string|number} [checkId] 字段类型2
     * @returns {boolean} ture:是, false: 不是
     */
    isFile(nodeType, checkId) {
        const params = this._paramConvert(nodeType, checkId);
        if (!params) {
            return false;
        }
        if (params.nodeType !== undefined && params.checkId !== undefined) {
            return this.getType(nodeType, checkId).includes("file");
        }
        if (params.type) {
            return ["file", "upload"].some(type2 =>
                String(params.type).includes(type2)
            );
        }
    },
    /**
     * 参数转换
     * @throws {Error} 失败抛错
     * @returns {object} 成功返回参数对象(仅包含nodeType与checkId),失败抛错
     */
    _paramConvert() {
        /** 参数列表 */
        const argList = Array.prototype.slice.call(arguments, 0);
        /** 结果 */
        let result = {};
        // 两个数字型参数:第1个参数nodeType,第2个参数checkId
        if (
            argList.length === 2 &&
            argList.slice(0, 2).every(arg => !Number.isNaN(Number(arg)))
        ) {
            result = {
                nodeType: Number(argList[0]),
                checkId: Number(argList[1]),
            };
        }
        // 第一个对象型参数->抽取对应nodeType和checkId
        else if (
            _.isPlainObject(argList[0]) &&
            ((argList[0].nodeType !== undefined &&
                argList[0].checkId !== undefined) ||
                argList[0].type !== undefined)
        ) {
            result = {
                nodeType: Number(argList[0].nodeType),
                checkId: Number(argList[0].checkId),
                type: argList[0].type,
            };
        }
        // 第一个参数直接为具体的类型
        else if (_.isString(argList[0]) && Number.isNaN(Number(argList[0]))) {
            result = {
                type: argList[0],
            };
        } else {
            return;
        }
        return result;
    },
    /** 类型1 @enum */
    nodeType: {
        /** 一级码表 */
        SELECT_SINGLE_LEVEL: 0,
        /** 多级码表 */
        SELECT_MULTI_LEVEL: 7,
        /** 输入型 */
        INPUT: 1,
        /** 系统输入型,和1相同 */
        INPUT_SYS: 3,
        /** 行集属性 */
        ROWSET: 5,
        /** 附件 */
        FILE: 4,
    },
    checkId: {
        /** 单选项(切换平铺) */
        SELECT_SINGLE_SWITCH: 15,
        /** 单选码表 */
        SELECT_SINGLE: 96,
        /** 多选码表 */
        SELECT_MULTI: 97,
        /** 文字型 */
        TEXT: 0,
        /** 大文本型 */
        TEXT_BIG: 6,
        /** 数字型 */
        NUMBER: 1,
        /** 货币 */
        NUMBER_MONEY: 5,
        /** 身份证型 */
        IDENTITY_CARD: 3,
        /** 日期型(具体格式参照其他类型) */
        DATE: 2,
        /** 多媒体-图片 */
        FILE_IMG: 4,
        /** 多媒体-单附件 */
        FILE_SINGLE: 7,
        /** 多媒体-多附件 */
        FILE_MULTI: 8,
        /** 审核型 */
        REVIEW: 9,
        /** 行集 */
        ROWSET: 98,
        /** 附属行集 */
        ROWSET_F: 100,
        /** 新闻型 */
        NEWS: 13,
        /** 加密型 */
        ENCRYPT: 14,
        /** 分类型 */
        CLASSIFY: 95,
        /** 签名类型 */
        SIGNATURE: 17,
        /** 颜色类型 */
        COLOR: 101,
        /** 图标类型 */
        ICON: 110,
        /** 代码类型 */
        CODE: 120,
    },
};

// 后端配置

/**
 * nodeType
 * 0、一级码表
 * 1、输入型
 * 4、附件
 * 5、行级属性
 * 7、多级码表
 */

//     CHAR(1, 0, "文字（字符型）"),
//     NUMBER(1, 1, "数字（数字型）"),
//     DATA(1, 2, "日期（日期型）"),
//     IDENTITY_CARD(0, 3, "身份证（自定义）"),
//     MULTI_MEDIA_IMG(4, 4, "多媒体（图片）"),
//     CURRENCY(1, 5, "货币（数字型）"),
//     BIG_CHAR(1, 6, "大文本"),
//     MULTI_MEDIA_ACCESSORY(4, 7, "多媒体（单附件）"),
//     MULTI_MEDIA_MORE_ACCESSORY(4, 8, "多媒体（多附件）"),
//     CHECK(1, 9, "审核信息"),
//     VOTING_A(1, 10, "投票型A"),
//     VOTING_B(1, 11, "投票型B"),
//     YEAR_MONTH(1, 12, "日期（年月型）"),
//     NEWS(1, 13, "新闻型"),
//     ENCRYPTION(1, 14, "加密型"),
//     UNKNOWN(0, 15, "单选择（平铺码表）"),
//     CLASSIFY(0, 95, "分类型"),
//     MULTI_MEDIA_RADIO(0, 96, "单选择（码表型）"),
//     MULTI_MEDIA_MULTIPLE(0, 97, "多选择（码表型）"),
//     ROW_SETS(5, 98, "行集属性");
