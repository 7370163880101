/* eslint-disable */
import { ENCRYPT_KEY } from "../global.js";

/**
 * @description 数据模块-钩子类
 * @see 此类仅包含处理json返回结果的情况,不包含xml,如果需要继承
 * @see 依赖mock初始化,此时mock拦截才有效
 * @see 用来提供给数据请求初始化的基础功能(拦截器,转换器,配置解析器)
 * @desc 支持请求超时监听进行重新请求操作支持
 * @author tonny
 * @date 2021-10-08
 */

import Utils from "../Utils/tools";
import axios from "axios";
// import Mock from "mockjs2";
import Notify from "../Notify/Notify.vue";
import qs from "qs";
import Proxy from "kooci-lib/proxy.config.js";
import Config from "kooci-lib/src/PubComponents/config.js";
import Convert from "../Mixin/Data/Convert";
import ConvertExtend from "../Mixin/Data/Convert/Extend";
import _ from "lodash";
import Vue from "vue";
import md5 from "js-md5";

/** 错误上报忽略接口 */
const ERR_SEND_IGNORE_APIS = ["/auth/login", "/auth/info"];

export default class Base {
    /**
     * 构造函数
     * @param {object} params 参数对象
     * @returns void
     */
    constructor(params) {
        this._request = null; // 请求对象
        this.vm = null; // vue实例
        this.baseUrl = ""; // 基础url(axios代理使用)
        this.converts = {}; // 转换器对象(存储转换器便于用来)
        if (params instanceof Vue) {
            this.vm = params;
        } else if (_.isPlainObject(params)) {
            this.vm = params.vm;
            this.baseUrl = params.baseUrl;
            this._loginDialogDisabled = params.loginDialogDisabled;
        }
        this._init(this.vm);
    }
    /**
     * 请求核心函数
     * @param {object} params 请求参数对象
     * @param {object} [other={}] 其他参数
     * @returns {Promise<any>} 成功返回data结果,失败抛错
     */
    request(params, other = {}) {
        if (_.isString(params)) {
            params = {
                url: params,
                _other: other,
            };
        } else if (_.isPlainObject(params)) {
            params = { ...params, _other: other };
        } else {
            return Promise.reject(
                new Error(
                    `[Data.request]参数错误!第一个参数必须为:url字符串或配置对象,当前:${JSON.stringify(
                        params
                    )}`
                )
            );
        }
        if (_.isPlainObject(other)) {
            params.name = other.name;
            // 用户拦截器覆盖
            if (other.convert === false) {
                params.convert = "";
            } else if (other.convert === true) {
                params.convert = params.url;
            } else if (other.convert) {
                params.convert = other.convert;
            }
            // 超时设置
            if (_.isPlainObject(other.timeoutConfig)) {
                params.timeoutConfig = other.timeoutConfig;
                params.timeout =
                    other.timeoutConfig.timeout ||
                    (kq.Tools.isNumber(other.timeout) ? other.timeout : null);
            }
        } else if (_.isString(other) && !other.includes("/")) {
            params.name = other;
        }
        if (!this._request) {
            this._axiosInit();
        }
        // 调用请求逻辑(axios)
        if (other.token) {
            if (!params.headers) {
                params.headers = {};
            }
            params.headers.Authorization = other.token;
        }
        return this._request(params);
    }
    /**
     * 获取host
     * @returns {string}
     */
    getBaseUrl() {
        if (
            window.kqConfig &&
            _.isFunction(window.kqConfig.getBaseUrl) &&
            process.env.NODE_ENV === "development"
        ) {
            return window.kqConfig.getBaseUrl();
        }
        return window.location.origin + "/api";
    }
    /**
     * 错误处理
     * @param {Error} err 错误对象
     */
    errHand(err) {
        console.error(err);
        // 非自定义的系统报错!
        if (err.message && err.stack && err.name === undefined) {
            const [, row, col] = err.stack.split("\n")[1].match(/:(\d+):(\d+)/);
            err.message += `错误详情代码行==行:${row} 列:${col}`;
        }
        const message = err.message || err.msg || err;
        Notify.open({
            title: this.constructor.name + "错误",
            type: "error",
            info: message,
            message,
        });
    }
    /**
     * 登出
     */
    loginOut() {
        if (window.kqConfig && _.isFunction(window.kqConfig.loginOut)) {
            window.kqConfig.loginOut();
        } else {
            const className = this.constructor.name;
            const error = new Error(
                `[${className}]参数错误!必须重写登出(loginOut)函数!请通过:kq.Ruquest.use({loginOut(){...}})进行注入或者使用派生类!`
            );
            this.errHand(error);
        }
    }
    /**
     * 获取token
     */
    getToken() {
        if (window.kqConfig && _.isFunction(window.kqConfig.getToken)) {
            return window.kqConfig.getToken();
        } else {
            const className = this.constructor.name;
            const error = new Error(
                `[${className}]参数错误!必须重写获取token(getToken)函数!请通过:kq.Ruquest.use({getToken(){...}})进行注入或者使用派生类!`
            );
            this.errHand(error);
        }
    }
    /**
     * 获取md5校验值
     * @param {object} config参数对象
     * @returns {string}
     */
    _getMd5__backup({ url, method, params, data }) {
        /** 加密秘钥 */
        const md5key = ENCRYPT_KEY;
        /** 加密的值 */
        let queryValue = "";
        // 参数拼接到地址栏
        if (/.+\?.+/g.test(url)) {
            queryValue = url.split("?")[1];
        }
        // get请求
        else if (method.toLowerCase() === "get") {
            if (!_.isEmpty(params)) {
                queryValue = _.map(params, (value, key) => `${key}=${value}`)
                    .filter(
                        (val) =>
                            !val.includes("undefined") && !val.includes("null")
                    )
                    .join("&");
            }
        }
        // post+其他请求
        else if (data) {
            queryValue = JSON.stringify(data).replace(
                new RegExp(
                    "[\\s~·`!！@#￥$%^…&*()\\-—_=+【\\[\\]】｛{}｝、\\\\；;：:‘'“”\"，,<>《》/？?]",
                    "g"
                ),
                ""
            );
        }
        return md5(md5key + encodeURIComponent(queryValue));
    }
    /**
     * 获取md5校验值
     * @param {object} config参数对象
     * @returns {string}
     */
    _getMd5(config) {
        const md5key = "hduawidhjkah"; // 密钥
        let md5value = null;
        if (config.method == "get") {
            if (config.url.indexOf("?") != -1) {
                md5value = md5(
                    md5key +
                        encodeURIComponent(
                            this._md5Trans(config.url.split("?")[1])
                        )
                );
            } else {
                const arr = [];
                let val = "";
                if (config.params) {
                    for (const key in config.params) {
                        if (config.params[key] != null) {
                            arr.push(key + "=" + config.params[key]);
                        }
                    }
                    val = arr.join("&");
                }
                md5value = md5(
                    md5key + encodeURIComponent(this._md5Trans(val))
                );
            }
        } else {
            if (config.url.indexOf("?") != -1) {
                md5value = md5(
                    md5key +
                        encodeURIComponent(
                            this._md5Trans(config.url.split("?")[1])
                        )
                );
            } else if (config.data) {
                md5value = md5(
                    md5key + encodeURIComponent(this._md5Trans(config.data))
                );
            } else if (config.params) {
                const arr = [];
                let val = "";
                if (config.params) {
                    for (const key in config.params) {
                        if (config.params[key] != null) {
                            arr.push(key + "=" + config.params[key]);
                        }
                    }
                    val = arr.join("&");
                }
                md5value = md5(
                    md5key + encodeURIComponent(this._md5Trans(val))
                );
            } else {
                md5value = md5(md5key);
            }
        }
        return md5value;
    }
    _md5Trans(data) {
        const str = JSON.stringify(data);
        var pattern = new RegExp(
            "[\\s~·`!！@#￥$%^…&*()\\-—_=+【\\[\\]】｛{}｝、\\\\；;：:‘'“”\"，,<>《》/？?]"
        );
        var rs = "";
        for (var i = 0; i < str.length; i++) {
            rs = rs + str.substr(i, 1).replace(pattern, "");
        }
        return rs;
    }
    /**
     * axios初始化操作
     */
    _axiosInit() {
        this._request = axios.create({
            baseURL: this.baseUrl || this.getBaseUrl(),
            timeout: 0,
            timeoutErrorMessage: "抱歉,请求超时",
            transitional: {
                clarifyTimeoutError: true,
            },
        });
        // 请求拦截器初始化
        this._requestInit();
        // 响应拦截器初始化
        this._resonseInit();
    }
    /**
     * 响应初始化
     * @see 增加响应拦截器
     * @returns void
     */
    _resonseInit() {
        this._request.interceptors.response.use(
            (response) => this._responseSuccessHandler(response),
            (error) => this._responseErrorHandler(error)
        );
    }
    /**
     * 响应成功处理
     * @param {object} response 响应对象
     * @returns {Promise<Error>|object} 成功返回data对象,失败抛错
     */
    _responseSuccessHandler(response) {
        if (response.status == 200) {
            // 判断不需要拦截器
            if (response.config.notInterceptor) {
                return response.data;
            }
            // 错误判断
            const err = this._responseErrorJudge(response);
            if (err) {
                return Promise.reject(new kq.Err(err));
            }
            // 响应转化器
            this._responseConvert(response);
            // 响应调试log日志
            this._responseDevelopmentLog(response);
            return this._getData(response);
        } else {
            return Promise.reject(
                new Error(`接口响应错误!错误码:${response.status}`)
            );
        }
    }
    /**
     * 响应错误处理
     * @param {object} err 错误对象
     * @returns {Promise<object>} 抛错错误对象
     */
    _responseErrorHandler(err) {
        const config = err.response ? err.response.config : err.config || {};
        if (err.message.includes("Network Error")) {
            return Promise.reject(
                new kq.Err({
                    type: false,
                    title: "服务器维护",
                    message: "抱歉,服务器正在维护,请等待通知",
                    console: false,
                    from: "502",
                })
            );
        }
        let isLoginOut = false;
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = "请求错误";
                    break;
                case 401:
                    // 注销登录
                    if (!this._loginDialogDisabled) {
                        this.loginOut();
                    }
                    isLoginOut = true;
                    err.message = "请重新登录";
                    break;
                case 413:
                    err.message = "上传文件超出最大限制";
                    break;
                case 403:
                    err.message = "拒绝访问";
                    break;
                case 404:
                    err.message = `请求地址出错: ${err.response.config.url}`;
                    break;
                case 408:
                    err.message = "请求超时";
                    break;
                case 500:
                    err.message = "服务器内部错误";
                    break;
                case 501:
                    err.message = "服务未实现";
                    break;
                case 502:
                    err.message = "网关错误";
                    window.kqConfig.handler502 &&
                        window.kqConfig.handler502(config);
                    break;
                case 503:
                    err.message = "服务不可用";
                    break;
                case 504:
                    err.message = "网关超时";
                    break;
                case 505:
                    err.message = "HTTP版本不受支持";
                    break;
                default:
                    break;
            }
        }
        if (
            err.code === "ETIMEDOUT" ||
            err.message.includes("timeout of") ||
            err.message.includes("超时")
        ) {
            return this._timeoutHandler(config);
        }
        /** 错误类型,false不上报 */
        let errType = "API";
        if (
            ERR_SEND_IGNORE_APIS.some((api) => config.url.includes(api)) ||
            isLoginOut
        ) {
            errType = false;
        }
        // 非网络错误
        if (
            !err.message.includes("Network Error") &&
            !err.message.includes("请求超时")
        ) {
            return Promise.reject(
                new kq.Err({
                    title: "接口错误",
                    type: errType,
                    from: "kq.Request",
                    message: err.message,
                    console: false,
                    code: err.response.status,
                    current: `${config.method}:${config.url}?${qs.stringify(
                        config.params || config.data
                    )}`,
                })
            );
        }
    }
    /**
     * 超时处理
     * @param {object} config 请求配置
     * @param {object} [config.timeoutConfig={}] 超时配置
     * @returns {Promise<any>} 重新请求则返回对应的请求结果,超时则返回kq.Err对象抛错
     */
    _timeoutHandler(config) {
        const { timeoutConfig: { reRequest = {} } = {} } = config;
        /**
         * maxCount:最大重新请求此处
         * enabled 重新请求是否可用
         */
        const { maxCount = 10, enabled = false } = reRequest;
        /** currentCount:当前重新请求的次数 */
        let currentCount = _.get(this._reRequestMaps, [
            config.url,
            "currentCount",
        ]);
        if (currentCount === undefined) {
            if (!this._reRequestMaps) {
                this._reRequestMaps = {};
            }
            _.set(this._reRequestMaps, [config.url, "currentCount"], 0);
        }
        if (
            enabled &&
            this._reRequestMaps[config.url].currentCount++ < maxCount - 1
        ) {
            return this.request(config, config._other);
        }
        return Promise.reject(
            new kq.Err({
                type: false,
                title: "请求超时",
                message: "请求超时,检查网络后稍后重试吧~",
                console: false,
                from: "kq.Request",
                current: config,
            })
        );
    }
    /**
     * 请求调试日志
     * @param {*} data 数据
     * @param {object} config 请求配置
     * @returns void
     */
    _requestDevelopmentLog(config) {
        if (
            process.env.NODE_ENV === "development" &&
            config.name !== undefined
        ) {
            const { method, data, params } = config;
            config.uuid = Utils.uuid();
            console.purple(
                `↑↑↑↑↑上行[${method}](${
                    config.name || config.uuid.slice(-6)
                })↑↑↑↑↑\n`,
                `${Proxy.host}${config.url}?${qs.stringify(
                    config.data || config.params
                )}`
            );
            // const resultParam = data || params;
            console.table(data || params);
        }
    }
    /**
     * 响应调试日志
     * @param {*} data 数据
     * @param {object} config 请求配置
     * @returns void
     */
    _responseDevelopmentLog({ data, config }) {
        // 调试环境
        if (
            process.env.NODE_ENV === "development" &&
            config.name !== undefined
        ) {
            // 调试
            console.green(
                `↓↓↓↓↓下行(${config.name || config.uuid.slice(-6)})↓↓↓↓↓\n`,
                config.convertName ? `转换器: ${config.convertName}\n` : "",
                data
            );
        }
    }
    /**
     * 响应错误判断
     * @param {*} data 响应数据
     * @returns void
     */
    _responseErrorJudge({ data, result, config }) {
        let type = "API";
        const from = `kq.Request`,
            title = "请求错误";
        let current = `${config.method}:${config.url}?${qs.stringify(
            config.params || config.data
        )}`;
        data = data || result;
        if (!data) {
            return {
                title,
                message: "接口未返回数据",
                type,
                from,
                current,
                console: false,
            };
        }
        const { code, msg } = data;
        if (
            code &&
            ([50008, 50012, 50014, 401].some((n) => n == code) ||
                (code === 1 && msg && msg.includes("未绑定公众号")))
        ) {
            // 登出
            this.loginOut();
            return {
                title,
                message: `登录超时!`,
                type: false, // 不上报
                from,
                current,
                console: false,
            };
        } else if (code) {
            if (ERR_SEND_IGNORE_APIS.some((api) => config.url.includes(api))) {
                type = false;
            }
            if (msg) {
                const apiParamErrs = msg.match(/'(.+)' is not present/);
                if (apiParamErrs && apiParamErrs.length > 1) {
                    return {
                        message: `接口调用出错!缺少${apiParamErrs[1]}参数!`,
                        title,
                        from,
                        current,
                        type,
                        console: false,
                    };
                }
            }
            return {
                message: msg,
                msg,
                code,
                title,
                from,
                current,
                type,
                console: false,
            };
        }
    }
    /**
     * 请求初始化
     * @see 基本请求拦截器
     */
    _requestInit() {
        this._request.interceptors.request.use((config) => {
            // 设置token
            if (!config.headers["Authorization"]) {
                config.headers["Authorization"] = this.getToken();
            }
            config.headers.md5 = this._getMd5(config);
            // log日志
            this._requestDevelopmentLog(config);
            // 请求转换器
            this._requestConvert(config);
            // 注释是因为mockjs会对下载造成影响,使用mockjs2会导致无限循环
            // mock初始化
            // this._mockInit(config);
            return config;
        });
    }
    /**
     * 请求转换器
     * @see axios请求拦截
     * @param {*} config 请求配置对象
     * @returns {object}
     */
    _requestConvert(config) {
        if (_.isPlainObject(config.timeoutConfig)) {
            config.timeout = config.timeoutConfig.timeout || config.timeout;
        }
        return config;
    }
    /**
     * 响应转换器
     * @param {object} response 请求对象
     * @returns {object} 响应对象
     */
    _responseConvert(response) {
        try {
            // 对结果进行转换,isJSON的作用是转换data为object,转换失败返回false(data可以是字符串类型的json)
            let result = Utils.isJSON(response.data);
            // 转换成功
            if (result) {
                response.data = result;
            }
            let { convert } = response.config;
            if (!convert) {
                return response;
            }
            /** 最终数据 */
            let data = this._getData(response);
            /** 最终数据来自data中的键 */
            let dataFrom = "";
            // 字符串转换器:指定了预设
            if (_.isString(convert)) {
                let convertItem = Config.Convert.methods.getApiConvert(convert);
                if (!convertItem) {
                    console.warn(
                        new kq.Err({
                            type: false,
                            title: "转换器错误",
                            message: "未查询到指定转换器",
                            current: convert,
                            from: "Request._responseConvert",
                        })
                    );
                    return response;
                }
                response.config.convertName = convertItem.name;
                convert = new Convert(convertItem);
                dataFrom = convertItem.from;
            }
            // 传入了转换器配置对象
            else if (
                _.isPlainObject(convert) &&
                !(convert instanceof Convert)
            ) {
                if (convert.api) {
                    convert = new ConvertExtend(convert.api);
                } else {
                    convert = new Convert(convert);
                }
                response.config.convertName =
                    convert.name || convert.convertName;
                dataFrom = convert.from || convert.dataFrom;
            }
            // 传入了错误类型
            else if (!(convert instanceof Convert)) {
                throw new kq.Err({
                    title: "转换器解析错误",
                    message: "当前转换器类型未受支持",
                    type: "DEV",
                    from: "Request._responseConvert",
                    current: convert,
                    console: false,
                });
            }
            // 转换器转换
            result = convert.convert(
                dataFrom ? data[dataFrom] : data // 判断数据的源key(比如目标数据不再data中,而在data的子元素中)
            );
            // 设置结果(将result并入data中)
            if (
                response.data.data !== undefined ||
                response.data.result !== undefined
            ) {
                response.data.data = result;
            } else {
                response.data = result;
            }
            return response;
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
    _getData(response) {
        if (response.data.code !== undefined) {
            return response.data.data || response.data.result;
        }
        return response.data;
    }
    /**
     * 字段列表验证
     * @param {[object]} fieldList 字段列表
     * @returns 失败抛错
     */
    _fieldListValidate(fieldList) {
        const from = "kq.Request.fieldListValidate",
            title = "参数错误";
        if (!_.isArray(fieldList)) {
            throw new kq.Err({
                title,
                message: "字段列表必须为数组",
                console: false,
                from,
                current: Object.prototype.toString.call(fieldList),
            });
        }
        const errList = [];
        fieldList.forEach((field) => {
            const { zid, value, rvalue } = field;
            if (!zid || value === undefined) {
                errList.push(field);
            } else if (
                value !== null &&
                !_.isString(value) &&
                !_.isNumber(value) &&
                !_.isArray(value)
            ) {
                errList.push(field);
            }
        });
        if (!_.isEmpty(errList)) {
            console.error("参数错误", errList);
            throw new kq.Err({
                title: "参数错误",
                message:
                    "字段列表必须每项都包含字段号(id),字段值(value):非undefined的字符串或数字",
                from,
                current: errList,
                console: false,
            });
        }
    }
    /**
     * 字段列表转换
     * @param {[object]} fieldList 字段列表
     * @returns {[object]} 转换后更新接口调用的字段列表
     */
    _fieldListConvert(fieldList, uid) {
        this._fieldListValidate(fieldList);
        return fieldList.map(({ zid, value, type = "", rvalue, uid: uid2 }) => {
            if (_.isString(value) && value) {
                value = value.replace(/ /g, " ");
            }
            const result = {
                nodeId: zid,
                nodeValue: value || null,
            };
            if (rvalue) {
                if (_.isString(rvalue)) {
                    result.strValue = rvalue;
                } else if (_.isPlainObject(rvalue)) {
                    result.strValue = [
                        rvalue.comment,
                        rvalue.signature,
                        rvalue.datetime,
                        rvalue.uname,
                    ]
                        .map((val) => val || "")
                        .join(";");
                }
            }
            if (uid2 || uid) {
                result.rootBillId = kq.Tools.isEqual(uid2 || uid, 0)
                    ? null
                    : uid2 || uid;
            }
            if (type.includes("file") && _.isArray(value)) {
                result.nodeValue = value.join(";");
            }
            if (type.includes("select") && _.isArray(value)) {
                if (_.isArray(value[0])) {
                    result.nodeValue = value
                        .map((val) => val.kqLastItem())
                        .join(",");
                } else {
                    result.nodeValue = value.join(",");
                }
            }
            return result;
        });
    }
    /**
     * mock配置初始化
     * @see mock模式加统一前缀便于开关
     */
    _mockInit(config) {
        // 判断已经进行了mock初始化
        if (
            process.env.VUE_APP_ENV.includes("mock") &&
            Mock.kq &&
            Mock.kq.urlList.some((url) => url.includes(config.url))
        ) {
            config.url = "/mock" + config.url;
            return config;
        }
    }
    beforeInit() {}
    init() {}
    afterInit() {}
    _init(vm) {
        Utils.asyncChain([this.beforeInit, this.init, this.afterInit], vm);
    }
}
