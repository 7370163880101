var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`kq-card-number kq-card-shadow ${_vm.focusCss} ${
        _vm.theme === 'gray' ? 'v-wbg-bg' : 'v-white-bg'
    }`,style:({
        height: _vm.formatLength__(_vm.height),
        borderRadius: _vm.formatLength__(_vm.borderRadius),
    }),on:{"mouseenter":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false},"click":function($event){return _vm.$emit('click')}}},[_vm._t("default",function(){return [_c('span',{class:{
                'kq-card-number-num v-label-light-color kq-center': true,
                'v-primary-color-important': _vm.isHover || _vm.isFocus,
            }},[_vm._v(_vm._s(_vm.sortNumber))]),_c('div',{staticClass:"kq-card-number-content"},[_c('span',{directives:[{name:"kq-color",rawName:"v-kq-color.title",modifiers:{"title":true}}],staticClass:"v-ellipsis",staticStyle:{"text-align":"left"},attrs:{"title":_vm.name}},[_c('span',[_vm._v(_vm._s(_vm.name))]),_c('span',{staticClass:"v-error-color"},[_vm._t("nameAfter")],2)]),(_vm.subname)?_c('label',{directives:[{name:"kq-color",rawName:"v-kq-color.label.light",modifiers:{"label":true,"light":true}}],staticClass:"v-ellipsis",attrs:{"title":_vm.subname}},[_vm._v(_vm._s(_vm.subname))]):_vm._e()]),(!_vm.statDisabled)?_c('div',{staticClass:"kq-card-number-statbtns"},_vm._l((_vm.formatStatBtnList),function(btn){return _c('kq-pop-confirm',_vm._g(_vm._b({key:btn.name,attrs:{"offset":-100,"disabled":btn.confirm.disableHandler(btn) ||
                    !!btn.props.disableHandler(btn)}},'kq-pop-confirm',btn.confirm.props,false),btn.confirm.listeners),[_c('kq-square-icon-btn',_vm._g(_vm._b({class:`kq-card-number-statbtns-btn ${btn.css}`,style:(btn.style),attrs:{"id":btn.id,"name":btn.name,"disabled":!!btn.props.disableHandler(btn),"loading":!!btn.loading,"width":btn.props.width || 20,"icon-size":btn.props.iconSize || 12,"stop-propagation":false},on:{"click":function($event){return _vm.onStatClick(btn)}}},'kq-square-icon-btn',btn.props,false),btn.listeners))],1)}),1):_vm._e(),_vm._t("after")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }