/**
 * @desc 按钮类
 * @desc 将按钮进行格式化管理
 * @author tonny
 * @date 2022-08-19
 */

import Tools from "../Utils/tools";

/**
 * 按钮类
 */
export default class Button {
    /**
     * 构造函数
     * @desc 将按钮对象转换为标准按钮对象
     * @desc 支持对每一个属性进行扩展
     * @desc 支持重复转换
     * @desc 函数类型扩展,只要函数返回false,就会直接中断调用连
     * @desc 函数扩展默认异步调用,如果设置扩展函数的sync为true,则会同步调用(前一个函数返回数据后才会调用后一个函数)
     * @class
     * @param {string|number|object} key 关键字(防止重复)|按钮对象
     * @param {object} [btn] 按钮对象
     * @example
     * // 直接新建按钮
     * const btn = new Button({name: "按钮名称", icon: "按钮图标", props: {loading: true}});
     * // 新建带有关键字key的按钮对象
     * const btn = new kq.Button("myKey", {name: "按钮名称", icon: "按钮图标", props: {loading: true}});
     * // 按钮属性扩展
     * btn.extendProps("id", {plain: true});
     * btn.extendClick("id", () => {});
     * // 也可以直接不适用key进行扩展(在涉及到相同的函数名称不同的调用逻辑时不建议使用,可能会导致判断后无法追加的情况)
     * btn.extendClick(() => {})
     * // 链式调用
     * btn.extendProps({plain:true}).extendClick(() => {}).extendConfirm({loading: false});
     * // 合并直接使用
     * btn.extend("id", {click: () => {}, props: {plain: true}});
     */
    constructor(key, btn) {
        this._extendPropList = [];
        this._extendClickList = [];
        this._extendPopoverList = [];
        this._extendChildList = [];
        this._extendComponentList = [];
        this._extendTooltipList = [];
        this._extendCustomList = [];
        this._extendConfirmList = [];
        this._extendEnabledList = [];
        this._extendDisabledList = [];
        // 兼容性传参
        if (_.isObject(key)) {
            btn = key;
        } else {
            this._key = key;
        }
        /** 已经是格式化按钮了 */
        const isFormat = btn._isFormat;
        // 已经格式化的按钮对象
        if (isFormat) {
            this._btn = btn._btn;
            this._prototype = btn;
            // 循环原按钮进行扩展表继承
            _.forEach(this, (value, name) => {
                if (/^_extend[A-Z]\w+List$/.test(name)) {
                    // 使用clone防止派生按钮扩展后会影响原按钮
                    this[name] = _.clone(btn[name] || []);
                }
            });
            this._enabled = btn._enabled;
            this._disabled = btn._disabled;
            this._click = btn._click;
        }
        // 未格式化的按钮对象
        else {
            this._btn = btn;
            // 将点击函数直接加到调用表中,方便进行unshift和push追加
            this._extendClickList = [
                { key: "_button", value: btn.click || btn.onClick },
            ];
            /** 可用源方法 */
            this._enabledHandler = this._getAbledHandler(
                this._btn
            ).enabledHandler;
            // 设置默认的可用判断
            this._extendEnabledList = [
                {
                    key: this._key || "kqButton",
                    value: {
                        enabledHandler: this._enabledHandler,
                    },
                },
            ];
            /** 禁用原方法 */
            this._disabledHandler = this._getAbledHandler(
                this._btn
            ).disabledHandler;
            // 设置默认的禁用判断
            this._extendDisabledList = [
                {
                    key: this._key || "kqButton",
                    value: {
                        disabledHandler: this._disabledHandler,
                    },
                },
            ];
            this._click = this._btn.click || this._btn.onClick;
        }
        this.init();
    }
    /**
     * 按钮排序
     * @static
     * @param {[object]} btnList 按钮列表(每个按钮对象必须包含sort属性才可以正常排序)
     * @returns {[object]} 排序后的按钮列表
     */
    static sort(btnList) {
        return (btnList || []).sort(
            ({ sort: prev = 1 }, { sort: next = 1 }) => {
                if (prev < 0) {
                    prev = 10e5 + prev;
                }
                if (next < 0) {
                    next = 10e5 + next;
                }
                return (prev || 1000) - (next || 1000);
            }
        );
    }
    /**
     * 初始化
     * @desc 根据入参初始化每一个参数对象
     * @returns void
     */
    init() {
        this._isFormat = true;
        this.name = this._btn.name;
        this._initProps();
        this._initConfirm();
        this._initClick();
        this._initCustom();
        this._initComponent();
        this._initChild();
        this._initPopover();
        this._initEnabledHandler();
        this._initDisabledHandler();
        this._initTooltip();
    }
    /**
     * 属性扩展
     * @param {string|number|object} key 关键字(防止重复)|按钮的子集
     * @param {object} attrs 扩展的模块集
     * @param {object} [attrs.props] 按钮属性集
     * @param {object} [attrs.click] 按钮的点击函数
     * @param {object} [attrs.confirm] 按钮的确认集
     * @param {object} [attrs.component] 组件集
     * @param {object} [attrs.custom] 自定义集
     * @param {function|boolean} [attrs.enabled] 按钮是否可用
     * @param {function|boolean} [attrs.disabled] 按钮是否禁用
     * @param {object} [attrs.popover] 弹层集
     * @param {object} [attrs.tooltip] 提示文本集
     * @param {object} [attrs.child] 子集
     * @returns {object} 按钮实例
     * @example
     * btn.extend("id", {click: () => {}, props: {plain: true}});
     */
    extend(key, attrs) {
        const convert = this._extendParamConvert(key, attrs, "extend");
        if (convert) {
            _.forEach(convert.value, (attr, name) => {
                if (
                    [
                        "props",
                        "click",
                        "confirm",
                        "component",
                        "custom",
                        "enabled",
                        "disabled",
                        "popover",
                        "child",
                        "tooltip",
                    ].includes(name)
                ) {
                    this[
                        `extend${name.replace(/^[a-z]/, ($) =>
                            $.toUpperCase()
                        )}`
                    ](convert.key, attr);
                }
            });
        }
        return this;
    }
    /**
     * 获取点击函数
     * @desc 此处获取的是按钮的原始点击函数
     * @desc 原始点击函数不受确认集的影响
     * @returns {function} 原始点击函数
     */
    getClick() {
        return this._click;
    }
    /**
     * 获取原禁用方法
     * @returns {function} 源禁用方法
     */
    getDisabledHandler() {
        return this._disabledHandler;
    }
    /**
     * 替换点击函数
     * @desc 此操作将直接覆盖原点击函数
     * @param {string|number|fucntion} key 关键字(防止重复)|点击函数
     * @param {function } [click] 点击函数
     * @returns {function} 按钮实例
     */
    replaceClick(key, click) {
        const convert = this._extendParamConvert(key, click);
        this._click = convert.value;
        if (convert) {
            this._extendClickList = [{ ...convert }];
            this._initClick();
        }
        return this;
    }
    /**
     * 向前追加按钮
     * @desc 在源点击函数之前追加
     * @param {string|number|fucntion} key 关键字(防止重复)|点击函数
     * @param {function} [click] 点击函数
     * @returns {object} 按钮实例
     */
    unshiftClick(key, click) {
        const convert = this._extendParamConvert(key, click, "unshiftClick");
        if (convert) {
            this._addExpandList(
                this._extendClickList,
                convert.key,
                convert.value,
                "unshift"
            );
            this._initClick();
        }
        return this;
    }
    /**
     * 扩展点击方法
     * @desc 在源点击函数之后添加
     * @param {string|number|fucntion} key 关键字(防止重复)|点击函数
     * @param {function } [click] 点击函数
     * @returns {object} 按钮实例对象
     */
    extendClick(key, click) {
        const convert = this._extendParamConvert(key, click, "extendClick");
        if (convert) {
            this._addExpandList(
                this._extendClickList,
                convert.key,
                convert.value
            );
            this._initClick();
        }
        return this;
    }
    /**
     * 替换禁用判断
     * @param {string|number|boolean|string|function} key 关键字key|禁用参数|禁用函数 |禁用原因
     * @param {boolean|string|function} [disabled] 是否禁用|禁用函数|禁用原因
     * @returns {object} 按钮实例对象
     */
    replaceDisabled(key, disabled) {
        const convert = this._extendParamConvert(
            key,
            disabled,
            "extendDisabled"
        );
        if (convert) {
            this._extendDisabledList = [
                {
                    key: convert.key,
                    value: {
                        disabledHandler: this._getAbledHandler({
                            disabled: convert.value,
                        }).disabledHandler,
                    },
                },
            ];
            this._initDisabledHandler();
        }
        return this;
    }
    /**
     * 插入禁用判断
     * @desc 在默认禁用之前扩展禁用判断
     * @param {string|number|boolean|function} key 关键字(防止重复)|禁用属性(函数)
     * @param {function|boolean} [disabled] 是否禁用
     * @returns {object} 按钮实例
     */
    unshiftDisabled(key, disabled) {
        const convert = this._extendParamConvert(
            key,
            disabled,
            "extendDisabled"
        );
        if (convert) {
            this._addExpandList(
                this._extendDisabledList,
                convert.key,
                {
                    disabledHandler: convert.value,
                },
                "unshift"
            );
            this._initDisabledHandler();
        }
        return this;
    }
    /**
     * 扩展禁用
     * @param {string|number|boolean|function} key 关键字(防止重复)|禁用属性(函数)
     * @param {function|boolean} [disabled] 是否禁用
     * @returns {object} 按钮实例
     */
    extendDisabled(key, disabled) {
        const convert = this._extendParamConvert(
            key,
            disabled,
            "extendDisabled"
        );
        if (convert) {
            this._addExpandList(this._extendDisabledList, convert.key, {
                disabledHandler: convert.value,
            });
            this._initDisabledHandler();
        }
        return this;
    }
    /**
     * 获取可用判断的原始数据
     * @return {function} 按钮原始判断可用函数
     */
    getEnabledHandler() {
        return this._enabledHandler;
    }
    /**
     * 替换可用判断
     * @param {string|number|boolean|function} key 关键字(防止重复)|可用属性(函数)
     * @param {function|boolean} [enabled] 是否可用
     * @returns {object} 按钮实例
     */
    replaceEnabled(key, enabled) {
        const convert = this._extendParamConvert(
            key,
            disabled,
            "extendDisabled"
        );
        if (convert) {
            this._extendEnabledList = [
                {
                    key: convert.key,
                    value: {
                        enabledHandler: this._getAbledHandler({
                            enabled: convert.value,
                        }).enabledHandler,
                    },
                },
            ];
            this._initDisabledHandler();
        }
        return this;
    }
    /**
     * 插入是否可用(在默认插入之前)
     * @param {string|number|boolean|function} key 关键字(防止重复)|可用属性(函数)
     * @param {function|boolean} [enabled] 是否可用
     * @returns {object} 按钮实例
     */
    unshiftEnabled(key, enabled) {
        const convert = this._extendParamConvert(key, enabled, "extendEnabled");
        if (convert) {
            this._addExpandList(
                this._extendEnabledList,
                convert.key,
                {
                    enabledHandler: convert.value,
                },
                "unshift"
            );
            this._initEnabledHandler();
        }
        return this;
    }
    /**
     * 扩展是否可用
     * @param {string|number|boolean|function} key 关键字(防止重复)|可用属性(函数)
     * @param {function|boolean} [enabled] 是否可用
     * @returns {object} 按钮实例
     */
    extendEnabled(key, enabled) {
        const convert = this._extendParamConvert(key, enabled, "extendEnabled");
        if (convert) {
            this._addExpandList(this._extendEnabledList, convert.key, {
                enabledHandler: convert.value,
            });
            this._initEnabledHandler();
        }
        return this;
    }
    /**
     * 扩展按钮的props属性
     * @param {string|number|object} key 关键字(防止重复)|按钮的props属性对象
     * @param {object} [props] 扩展的属性
     * @returns {object} 按钮实例
     */
    extendProps(key, props) {
        const convert = this._extendParamConvert(key, props, "extendProps");
        if (convert) {
            this._addExpandList(
                this._extendPropList,
                convert.key,
                convert.value
            );
            this._initProps();
        }
        return this;
    }
    /**
     * 扩展按钮的确认属性
     * @param {string|number|object} key 关键字(防止重复)|按钮的confirm属性对象
     * @param {object} [confirm] 提问属性
     * @returns {object} 按钮实例
     */
    extendConfirm(key, confirm) {
        const convert = this._extendParamConvert(key, confirm, "extendConfirm");
        if (convert) {
            this._addExpandList(
                this._extendConfirmList,
                convert.key,
                convert.value
            );
            this._initConfirm();
        }
        return this;
    }
    /**
     * 扩展按钮弹层属性
     * @param {string|number|object} key 关键字(防止重复)|按钮的popover属性对象
     * @param {object} [popover] 弹层属性
     * @returns {object} 按钮实例
     */
    extendPopover(key, popover) {
        const convert = this._extendParamConvert(key, popover, "extendPopover");
        if (convert) {
            this._addExpandList(
                this._extendPopoverList,
                convert.key,
                convert.value
            );
            this._initPopover();
        }
        return this;
    }
    /**
     * 扩展按钮的组件属性
     * @param {string|number|object} key 关键字(防止重复)|按钮的componet属性对象
     * @param {object} [component] 组件属性对象
     * @returns {object} 按钮实例
     */
    extendComponent(key, component) {
        const convert = this._extendParamConvert(
            key,
            component,
            "extendComponent"
        );
        if (convert) {
            this._addExpandList(
                this._extendComponentList,
                convert.key,
                convert.value
            );
            this._initComponent();
        }
        return this;
    }
    /**
     * 扩展按钮的提示语集(属性)
     * @param {string|number|object} key 关键字(防止重复)|按钮的提示语属性
     * @param {object} [tooltip] 提示语属性
     * @returns {object} 按钮实例
     */
    extendTooltip(key, tooltip) {
        const convert = this._extendParamConvert(key, tooltip, "extendTooltip");
        if (convert) {
            this._addExpandList(
                this._extendComponentList,
                convert.key,
                convert.value
            );
            this._initTooltip();
        }
        return this;
    }
    /**
     * 扩展按钮的子集属性
     * @param {string|number|object} key 关键字(防止重复)|按钮的子集
     * @param {object} [child] 子集属性
     * @returns {object} 按钮实例
     */
    extendChild(key, child) {
        const convert = this._extendParamConvert(key, child, "extendChild");
        if (convert) {
            this._addExpandList(
                this._extendChildList,
                convert.key,
                convert.value
            );
            this._initChild();
        }
        return this;
    }
    /**
     * 添加扩展列表
     * @desc 扩展的内容无重复的添加存储数组中
     * @param {[object]} expandList 存储扩展属性的对象数组
     * @param {string|number} key 关键字(防止重复)
     * @param {object} props 属性对象
     * @param {string} [addType='push'] 怎样的方式追加
     */
    _addExpandList(expandList, key, props, addType = "push") {
        expandList.kqSet(
            { key, value: props, _isExpand: true },
            (prop) => {
                if (new RegExp(`^${this._key || "kqButton"}$`).test(prop.key)) {
                    return Tools.isEqual(prop.value, props);
                }
                return Tools.isEqual(prop.key, key);
            },
            addType
        );
    }
    /**
     * 初始化点击函数
     * @desc 设置click属性
     * @desc 将点击函数按钮链式调用进行初始化
     * @desc 将确认条件加入到点击判断中
     * @desc 初始化确认集
     * @reurns void
     */
    _initClick() {
        const formatClick = this._getExtendClick();
        this.click = (...params) => {
            if (this.confirm.disabledHandler()) {
                formatClick(...params);
            }
        };
        this.onClick = this.click;
        this._initConfirm();
    }
    /**
     * 初始化自定义属性
     * @desc 将扩展属性合并后设置自定义属性集
     * @returns void
     */
    _initCustom() {
        this.custom = this._getExtendAttrs(
            _.merge({}, this._btn.custom),
            this._extendCustomList,
            "_formatCustom"
        );
    }
    /**
     * 初始化可用判断
     * @desc 将可用判断合并后设置可用属性
     * @returns void
     */
    _initEnabledHandler() {
        this.enabledHandler = (...params) =>
            this._extendEnabledList.every(({ value: { enabledHandler } }) =>
                enabledHandler(...params)
            );
    }
    /**
     * 初始化不可用判断
     * @desc 将不可用判断合并后设置不可用判断集
     * @returns void
     */
    _initDisabledHandler() {
        this.disabledHandler = (...params) => {
            let res = false;
            this._extendDisabledList.some(({ value: { disabledHandler } }) => {
                res = disabledHandler(...params);
                return res;
            });
            return res;
        };
    }
    /**
     * 初始化props属性
     * @desc 将props属性合并后设置props集
     * @returns void
     */
    _initProps() {
        this.props = this._getExtendAttrs(
            this._formatProps(this._btn),
            this._extendPropList,
            "_formatProps"
        );
    }
    /**
     * 格式化props属性集
     * @desc 针对按钮对象进行props属性抽取
     * @param {object} btn 源按钮对象
     * @param {[string]} [presetParams] 预设参数表
     * @returns {object} props属性
     */
    _formatProps(
        btn,
        presetParams = [
            "disabled",
            "enabled",
            "disabledHandler",
            "enabledHandler",
            "confirm",
            "component",
            "props",
            "popover",
            "onClick",
            "click",
            "child",
            "outClick",
            "custom",
            "sort",
        ]
    ) {
        return {
            ...this._getResultByPresetParams(btn, presetParams),
            ...this._getResultByPresetParams(btn.props, [
                "disabled",
                "disabledHandler",
                "enabled",
                "enabledHanlder",
            ]),
        };
    }
    /**
     * 初始化确认集
     * @desc 将扩展集合并后设置确认集
     * @returns {object} 融合后的确认对象
     */
    _initConfirm() {
        this.confirm = this._getExtendAttrs(
            this._formatConfirm(this._btn.confirm),
            this._extendConfirmList,
            "_formatConfirm"
        );
    }
    /**
     * 格式化确认集
     * @desc 将确认属性全部转换为函数
     * @param {object} confirm 源确认集对象
     * @param {[string]} [presetPrams] 预设参数表(预设的参数表将不会被加入到属性合并中)
     * @returns {object}
     */
    _formatConfirm(
        confirm,
        presetPrams = [
            "props",
            "listeners",
            "enabled",
            "enabledHandler",
            "disabled",
            "disabledHandler",
        ]
    ) {
        confirm = confirm || {};
        const formatProps = this._getResultByPresetParams(confirm, presetPrams);
        const abledHandlers = this._getAbledHandler(
            confirm,
            !kq.Tools.isEmpty(confirm),
            kq.Tools.isEmpty(confirm)
        );
        return {
            ...abledHandlers,
            props: {
                ...formatProps,
                ...(confirm.props || {}),
                yes: formatProps.yes || this._getExtendClick(),
                no: formatProps.no || (() => {}),
            },
            listeners: confirm.listeners || {},
        };
    }
    /**
     * 初始化弹层属性
     * @desc 将弹层属性集合并后设置当前弹层属性集
     * @returns void
     */
    _initPopover() {
        const fromPopover = this._formatPopover(this._btn.popover);
        this.popover = {
            ...this._getExtendAttrs(
                fromPopover,
                this._extendPopoverList,
                "_formatPopover"
            ),
        };
    }
    /**
     * 格式化弹层属性集
     * @desc 抽取属性对象中非预设熟悉至props,转换可用于禁用为函数
     * @param {object} popover 弹层属性对象
     * @param {[string]} presetParams 预设参数列表
     * @returns {object} 合并后的弹层属性集
     */
    _formatPopover(
        popover,
        presetParams = [
            "props",
            "listeners",
            "component",
            "enabled",
            "enabledHandler",
            "disabled",
            "disabledHandler",
        ]
    ) {
        popover = popover || {};
        /** 合并预设数据 */
        const formatProps = this._getResultByPresetParams(
            popover,
            presetParams
        );
        const defaultEnabled = !kq.Tools.isEmptyMaybe(
            popover,
            popover.component
        );
        return {
            component: popover.component,
            ...this._getAbledHandler(popover, defaultEnabled, !defaultEnabled),
            props: {
                ...formatProps,
                ...(popover.props || {}),
            },
            listeners: popover.listeners || {},
        };
    }
    /**
     * 初始化子集
     * @desc 将扩展的子集属性转换合并后设置当前子集属性
     * @returns void
     */
    _initChild() {
        this.child = this._getExtendAttrs(
            this._formatChild(this._btn.child),
            this._extendChildList,
            "_formatChild"
        );
    }
    /**
     * 格式化子集
     * @desc 子集按钮也会经过同样的按钮转换
     * @param {object} child 子集对象
     * @param {[string]} presetParams 预设参数表
     * @returns {object} 合并后的子集对象
     */
    _formatChild(
        child,
        presetParams = [
            "btnList",
            "props",
            "listeners",
            "enabled",
            "enabledHandler",
            "disabled",
            "disabledHandler",
        ]
    ) {
        child = child || {};
        const defaultEnabled = !kq.Tools.isEmpty(child.btnList);
        const formatProps = this._getResultByPresetParams(child, presetParams);
        return {
            ...this._getAbledHandler(child, defaultEnabled, !defaultEnabled),
            props: {
                ...formatProps,
                ...(child.props || {}),
            },
            listeners: child.listeners || {},
            // 将所有子集按钮进行转换
            btnList: (child.btnList || []).map((btn) => new Button(btn)),
        };
    }
    /**
     * 初始化提示信息集
     * @desc 将扩展的提示信息合并后设置当前的提示信息集
     * @returns void
     */
    _initTooltip() {
        this.tooltip = this._getExtendAttrs(
            this._btn.tooltip,
            this._extendTooltipList,
            "_formatTooltip"
        );
    }
    /**
     * 格式化提示信息集
     * @desc 将预设外的所有数据合并至props中,listeners保持不变,可用和禁用全部转换为函数判断
     * @param {object} tooltip 提示集对象
     * @param {[string]} presetParams 预设参数表
     * @returns {object} 合并后的信息提示对象
     */
    _formatTooltip(
        tooltip,
        presetParams = [
            "props",
            "listeners",
            "enabled",
            "enabledHandler",
            "disabled",
            "disabledHandler",
        ]
    ) {
        tooltip = tooltip || {};
        const formatProps = this._getResultByPresetParams(
            tooltip,
            presetParams
        );
        return {
            ...this._getAbledHandler(tooltip, true, false),
            props: {
                ...formatProps,
                ...(tooltip.props || {}),
            },
            listeners: tooltip.listeners || {},
        };
    }
    /**
     * 初始化组件信息集
                        let oldDisabledHandler = btn.disabledHandler;
     * @desc 将扩展的组件信息集合并后设置到当期那的组件信息集中
     * @return void
     */
    _initComponent() {
        this.component = this._getExtendAttrs(
            this._formatComponent(this._btn.component),
            this._extendComponentList,
            "_formatComponent"
        );
    }
    /**
     * 格式化组件集
     * @desc 将预设之外的参数合并至props属性中,其他保持部不变
     * @param {object} component 组件属性对象
     * @param {[string]} presetParams 预设参数表
     * @returns {object} 合并后的标准属性对象
     * @example {is<string>:组件所属, lazy<boolean>:是否懒加载, vshow<boolean>:是否显示, ref<string>:ref属性, props<object>:绑定组件的props属性(v-bind), listeners<object>:绑定组建的事件属性对象(v-on)}
     */
    _formatComponent(
        component,
        presetParams = ["props", "listeners", "is", "lazy", "vshow", "ref"]
    ) {
        component = component || {};
        const formatProps = this._getResultByPresetParams(
            component,
            presetParams
        );
        return {
            is: component.is,
            lazy: component.lazy,
            vshow: component.vshow,
            ref: component.ref,
            props: {
                ...formatProps,
                ...(component.props || {}),
            },
            listeners: component.listeners || {},
        };
    }
    /**
     *
     * @param {string|number|object|function} param1 第一个未确认入参(string|number:表示key关键字,object|function:表示属性集)
     * @param {object|function} [param2] 可能传入的属性集
     * @param {string} from 更改原函数方法名称
     * @returns {object} {key<string|number>:key关键字(不传时会自动生成), value:<object|function>:属性集}
     */
    _extendParamConvert(param1, param2, from) {
        const error = {
            type: false,
            console: false,
            from: `kq.Button.${from}`,
            current: param1,
        };
        if (Tools.isEmpty(param1)) {
            console.warn(
                new kq.Err({
                    ...error,
                    message: `扩展的第一个参数不能为空,这将导致该扩展毫无意义`,
                })
            );
            return false;
        }
        let key, value;
        // 判断第一个参数为属性对象
        if (_.isObject(param1)) {
            if (Tools.isEmpty(this._key)) {
                key = "kqButton";
                value = param1;
                // console.warn(
                //     new kq.Err({
                //         ...error,
                //         message: `当前按钮缺少唯一标识符(key),可以在new的时候或者调用extend相关方法的时候添加key参数,没有key可能会导致出现重复扩展的bug`,
                //     }).message
                // );
            } else {
                key = this._key;
                value = param1;
            }
        }
        // 属性对象不为空(指定了key与value)
        else if (!Tools.isEmpty(param2)) {
            key = param1;
            value = param2;
        } else {
            console.warn(
                new kq.Err({
                    ...error,
                    message: `当前扩展毫无意义,因为未传扩展参数`,
                }).message
            );
            return false;
        }
        return { key, value };
    }
    /**
     * 获取可用|禁用
     * @param {object} [target={}] 存储结果目标对象
     * @param {boolean} enabledDefault 默认是否可用(再入参不存在相关属性时使用)
     * @param {boolean} disabledDefault 默认是否禁用
     * @returns {object} {enabledHandler<function>: 是否可用, disabledHandler<function>: 是否禁用}
     */
    _getAbledHandler(
        target = {},
        enabledDefault = true,
        disabledDefault = false
    ) {
        let result = {};
        const props = target;
        [
            { type: "enable", default: enabledDefault },
            { type: "disable", default: disabledDefault },
        ].forEach(({ type, default: defaultValue }) => {
            /** 存储结果 */
            let res;
            /** 调用结果(根据预设好的方法集挨个查询) */
            let handler = [
                props[`${type}Handler`],
                props[`${type}dHandler`],
                props[`${type}d`],
                props[type],
            ].find((val) => !kq.Tools.isEmpty(val));
            if (!_.isFunction(handler)) {
                res = () =>
                    kq.Tools.isEmpty(handler) ? defaultValue : handler;
                // 未配置是否禁用:设置标记
                if (handler === undefined) {
                    res._isHandlerNull = true;
                }
            } else {
                res = handler;
            }
            result[`${type}dHandler`] = res;
        });
        return result;
    }
    /**
     * 同一个的转换结果集函数
     * @desc 会根据预设参数表进行自动屏蔽
     * @param {object} target 转换的目标
     * @param {[string]} presetParams 预设参数表(预设的不会被转换)
     * @returns {object} 合并后的属性
     */
    _getResultByPresetParams(target, presetParams) {
        const result = {};
        _.forEach(target, (value, key) => {
            if (!presetParams.includes(key)) {
                result[key] = value;
            }
        });
        return result;
    }
    /**
     * 获取扩展属性
     * @desc 使用_.mergeWith进行合并操作,遇到函数时会形成链式调用与判断
     * @param {object} target 转换的目标
     * @param {[object]} extendList 扩展的属性列表([{key<string>: 关键字, value<object|function>:属性集}, ...])
     * @param {string} [formatMethod] 每个属性集进行转换的方法(会在每一次遍历扩展数组时调用进行每个扩展集的转换,不传则使用原值进行合并操作)
     * @returns {object} 合并后的对象
     */
    _getExtendAttrs(target, extendList, formatMethod) {
        let result = target;
        /** 可用函数列表 */
        const enabledList = [];
        /** 禁用函数列表 */
        const disabledList = [];
        extendList.forEach(({ value: item }) => {
            // 进行可用于禁用函数合并判断
            if (formatMethod && _.isString(formatMethod)) {
                item = this[formatMethod](item);
                const { enabledHandler, disabledHandler } = item;
                if (
                    _.isFunction(enabledHandler) &&
                    !enabledHandler._isHandlerNull
                ) {
                    enabledList.push(enabledHandler);
                }
                if (
                    _.isFunction(disabledHandler) &&
                    !disabledHandler._isHandlerNull
                ) {
                    disabledList.push(disabledHandler);
                }
            }
            // 合并其它属性值
            result = _.merge(result, item);
        });
        // 可用合并
        if (!Tools.isEmpty(enabledList)) {
            result.enabledHandler = (...params) =>
                enabledList.every((handler) => handler(...params));
        }
        // 禁用合并
        if (!Tools.isEmpty(disabledList)) {
            result.disabledHandler = (...params) => {
                let res = false;
                disabledList.some((handler) => {
                    res = handler(...params);
                    return res;
                });
                return res;
            };
        }
        return result;
    }
    /**
     * 获取扩展点击函数
     * @desc 将点击扩展做成调用链进行依次调用
     * @desc 同异步属性判断
     * @returns {function} 扩展点击函数
     */
    _getExtendClick() {
        return (...params) => {
            Tools.asyncChain(
                this._extendClickList.map(({ value: click }) => {
                    let newClick = click;
                    if (!click.sync) {
                        newClick = (...params2) => {
                            click(...params2);
                        };
                    }
                    return newClick;
                }),
                ...params
            );
        };
    }
}
