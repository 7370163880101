var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeInDown","leave-active-class":"animate__animated animate__fadeOutRight"},on:{"after-enter":_vm.afterOpen,"after-leave":_vm.afterClose}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current),expression:"current"}],class:{
            'kq-pub-notify animate__animated animate__fadeInDown': true,
            'kq-pub-notify--error': _vm.errorText,
        },attrs:{"notify-id":_vm.id_},on:{"mouseenter":function($event){_vm.isMouseOver = true},"mouseleave":function($event){_vm.isMouseOver = false}}},[_c('div',{staticClass:"pn-main",attrs:{"title":_vm.copyTip}},[_c('i',{class:_vm.iconStyle}),_vm._t("default",function(){return [_c('div',{staticClass:"pn-main-label"},[_c('span',{staticClass:"pn-main-label-title"},[_vm._v(_vm._s(_vm.formatTitle))]),_c('span',{class:{
                            'pn-main-label-message': true,
                            show: _vm.msgShow,
                        },on:{"mouseenter":_vm.collapse,"mouseleave":_vm.collapse}},[_vm._v(_vm._s(_vm.formatMessage))])])]},null,_vm.$props),_c('div',{staticClass:"pn-main-close",attrs:{"title":"关闭提示"},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('i',{staticClass:"iconfont-kq-lib kq-icon-close-line"})])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.copyTipShow),expression:"copyTipShow"}],ref:"tip",staticClass:"kq-pub-notify__tip",on:{"click":_vm.onCopy}},[_vm._v(" "+_vm._s(_vm.copyTipShow === "success" ? "信息已复制" : "点击我复制详情信息上报吧")+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }