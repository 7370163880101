/**
 * @description 表单转换器基类
 * @description 适用于kq-inputcell组件的转换
 * @author tonny
 * @date 2022-05-09
 */

import _ from "lodash";
import moment from "moment";

export default class Convert {
    /**
     * 转换器
     * @constructor
     * @param {object} vm vue组件实例对象
     */
    constructor(vm) {
        /** vm实例 */
        this._vm = vm;
        /** 字段类型 */
        this._type = this._vm.$props.type;
    }
    /**
     * 入参:vmodel输入值转换
     * @virtucal
     * @public
     * @param {*} value 输入值
     * @returns {*} 输入值
     */
    inModel(value) {
        if (this._type.includes("select")) {
            return this._inModelSelect(value);
        }
        return value;
    }
    /**
     * 出参:vModel输出值转换
     * @virtucal
     * @public
     * @param {*} value 输出值
     * @returns {*} 转换后的值
     */
    outModel(value) {
        return value;
    }
    /**
     * 出参:change输出转换
     * @virtucal
     * @public
     * @param {object} field 输出字段
     * @returns {object}
     */
    outChange(field) {
        return field;
    }
    /**
     * 入参:显示值转换
     * @virtucal
     * @public
     * @param {*} value 字段值
     * @returns {string}
     */
    inSvalue(value) {
        if (this._type.includes("date")) {
            return this._inSvalueDate(value);
        }
        if (this._type.includes("select")) {
            return this._inSvalueSelect(value);
        }
        return value;
    }
    /**
     * 出参:显示值转换
     * @virtucal
     * @public
     * @param {*} value 字段值
     * @returns {*}
     */
    outSvalue(value) {
        return value;
    }
    /**
     * 是否为多选(范围)
     * @param {*} value 字段值
     * @returns {boolean} true:多选
     */
    isMulti(value) {
        return (
            this._type.includes("multi") ||
            this._vm.isRange ||
            (_.isArray(value) && !kq.Tools.isEmpty(value))
        );
    }
    /**
     * 入参:码表型vModel的value转换
     * @param {*} value 字段值
     * @returns {*}
     */
    _inModelSelect(value) {
        // 空值
        if (kq.Tools.isEmpty(value)) {
            if (this.isMulti(value)) {
                return [];
            }
        }
        // 非空值
        else {
            // 多选字符型
            if (this.isMulti(value) && _.isString(value)) {
                return value.split(",");
            }
        }
        return value;
    }
    /**
     * 入参:码表型转换
     * @param {*} svalue 显示值
     * @returns {string}
     */
    _inSvalueSelect(svalue) {
        if (kq.Tools.isEqual(svalue, this.inModel(this._vm.value))) {
            if (!kq.Tools.isEmpty(this._vm.svalue)) {
                return this._vm.svalue;
            }
            if (!kq.Tools.isEmpty(svalue)) {
                if (!_.isArray(svalue)) {
                    svalue = [svalue];
                }
                const selectList =
                    (this._vm.$attrs && this._vm.$attrs.selectList) ||
                    this._vm.selectList;
                let result;
                if (!kq.Tools.isEmpty(selectList)) {
                    svalue = svalue.map(
                        (sid) => {
                            result = selectList.kqFindDeep((select) =>
                                kq.Tools.isEqual(sid, select.value)
                            );
                            return result ? result.label : result;
                        }
                        // selectList.kqFindDeep((select) =>
                        //     kq.Tools.isEqual(sid, select.value)
                        // )?.label
                    );
                    return svalue.join("、");
                }
            }
            return "";
        }
        return svalue;
    }
    /**
     * 入参:日期显示值转换
     * @private
     * @param {string|[string]} value 日期值
     * @returns {string}
     */
    _inSvalueDate(value) {
        const momentValue = moment(value);
        // 无效日期
        if (!momentValue.isValid()) {
            return value;
        }
        // 至今日期
        if (value && moment(value).isSame("9999-01-01", "year")) {
            return "至今";
        }
        /** 日期格式化 */
        const dateFormat =
            this._vm.$props.dateFormat || this._vm.$attrs.dateFormat;
        if (dateFormat && !kq.Tools.isEmpty(value)) {
            // 范围日期
            if (
                this._vm.isRange ||
                _.isArray(value) ||
                this._type.includes("multi")
            ) {
                if (_.isArray(value)) {
                    const [start, end] = value;
                    if (!start) {
                        return `<span class="v-primary-color">小于</span>&nbsp;&nbsp;${moment(
                            end
                        ).format(dateFormat)}`;
                    } else if (!end) {
                        return `<span class="v-primary-color">大于</span>&nbsp;&nbsp;${moment(
                            start
                        ).format(dateFormat)}`;
                    } else {
                        return `${moment(start).format(
                            dateFormat
                        )}&nbsp;&nbsp;<span class="v-primary-color">至</span>&nbsp;&nbsp;${moment(
                            end
                        ).format(dateFormat)}`;
                    }
                } else {
                    return value;
                }
            }
            return moment(value).format(dateFormat);
        }
        return value;
    }
}
