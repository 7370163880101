/**
 * @description 图标自定义指令
 * @see 快速插入自定义图标
 * @see https://www.tapd.cn/62073498/documents/show/1162073498001004755
 * @author tonny
 * @date 2022-11-28
 * @example
 * <i v-kq-icon:[value] />
 * <i v-kq-icon="value" />
 * <i v-kq-icon.full:empty />
 * <i v-kq-icon.line:[value] />
 * @example value可以是如下字符串
 * kqapps kqapp-name // 指定库(指定库需要使用完整名称)
 * empty // 使用kq-lib库
 * empty-full // 指定填充
 * kqicon-empty-full // 使用kq-lib库指定前缀
 */

/**
 * 转换图标名称
 * @desc 根据值提取相应参数最终规范组合
 * @param {object} param0 入参对象
 * @param {string} param0.string 图标的值
 * @param {string} param0.fill 图标的后缀
 * @returns
 */
function convertClassName({ value, fill }) {
    if (!value) {
        return "";
    }
    if (typeof value !== "string") {
        console.warn(
            `[DirectiveBind-icon]参数错误!icon的名称必须为非空字符串!当前:${JSON.stringify(
                value
            )}`
        );
        return "";
    }
    /** 模块 */
    let module = "kqiconfont",
        /** 前缀 */
        prefix = "kqicon",
        /** 名称 */
        name = "";
    // 全部传的情况下,提出module
    if (/^[a-zA-Z-_]+ [a-zA-Z-_]+$/.test(value)) {
        const [$module, $value] = value.split(" ");
        value = $value;
        module = $module;
    }
    // 指定了以kq为前缀或icon-打头(旧库兼容)的情况下:提出前缀
    if (/^((kq[a-zA-Z]+)|(icon))-[a-zA-Z]+/.test(value)) {
        const [, $prefix, $value] = value.match(/^([^-]+)-(.*)$/);
        prefix = $prefix;
        value = $value;
    }
    // 指定了名称情况下:提出名称与后缀
    if (/[^-]+-?(.*)/.test(value)) {
        const [, $name, $fill] = value.match(/([^-]+)-?(.*)/);
        name = $name;
        fill = fill || $fill;
    }
    // 拼接完整结果
    return `${module} ${prefix}-${name}` + (fill ? `-${fill}` : "");
}

export default {
    install(Vue) {
        Vue.directive("kq-icon", {
            bind(el, { value, modifiers, arg }) {
                let fill = "";
                if (modifiers.full) {
                    fill = "full";
                } else if (modifiers.line) {
                    fill = "line";
                }
                const className = convertClassName({
                    value: value || arg,
                    fill,
                });
                if (className) {
                    el.dataset.kqIconClassName = className;
                    $(el).addClass(className);
                }
            },
            update(el, { value, arg, modifiers }) {
                let fill;
                if (modifiers.full) {
                    fill = "full";
                } else if (modifiers.line) {
                    fill = "line";
                }
                const className = convertClassName({
                    value: value || arg,
                    fill,
                });
                if (className) {
                    let oldClassName = el.dataset.kqIconClassName || "";
                    $(el).removeClass(oldClassName);
                    $(el).addClass(className);
                    el.dataset.kqIconClassName = className;
                }
            },
            ubind(el) {
                $(el).removeClass(el.dataset.kqIconClassName);
            },
        });
    },
};
