<script>
/**
 * @description 权限混入
 * @author tonny
 * @date 2021-12-06
 */
</script>

<script>
import _ from "lodash";
import { FtypeCompares } from "kooci-lib/src/PubComponents/global.js";
import ValidateConfig from "./Validate.js";

export default {
    props: {
        // /** 功能点号 */
        // fid: [String, Number],
        // /** 应用id */
        // appid: [String, Number],
        /** 功能类型(ftype)参照表 */
        ftypeCompares: {
            type: Object,
            default: () => FtypeCompares,
        },
        /** 数据权限 */
        dataAuth: [String, Number],
    },
    data() {
        return {
            /** 加载器 */
            permissionData: kq.Request.mixin({
                vm: this,
                modules: ["system", "view"],
            }),
            /** 权限标识 */
            permissionIdList: [],
            /** 是否为app管理员 */
            isAppAdmin: false,
            /** 是否为超级管理员 */
            isAdmin: false,
            /** 功能权限类型 */
            ftype: null,
            /** 是否显示单据状态 */
            isFiled: null,
            /** 是否可提交 */
            isSubmit: null,
            /** 是否可解锁 */
            isUnlock: null,
            /** 是否可编辑 */
            isEdit: null,
            /** 是否有竖线 */
            isVerticalLine: null,
            /** 数据权限(0:个人,1:本部门,3:全校(区别选择的子系统)) */
            // dataAuth: null,
            /** 是否可发送消息 */
            isSendMsg: null,
            /** 样张号 */
            tid: null,
            /** 视频播放地址 */
            videoUrl: "",
            /** 帮助文本 */
            help: "",
            /** 功能点绑定的视图号 */
            fvid: "",
            /** 视图名称 */
            vname: "",
            /** 消息推送权限表(1:短信,2:微信,3:邮件) */
            msgPermissions: {},
        };
    },
    methods: {
        /**
         * 获取是否禁用编辑状态
         * @returns {boolean} true:禁用
         */
        getEditDisabled(params = {}) {
            return ValidateConfig.getEditDisable(params);
        },
        /**
         * 权限校验
         * @see 在权限数据加载完成后调用
         * @see 此校验仅仅作为视图设置按钮权限表的规则校验,通过并不代表含有页面所有权限
         * @param {number} 校验目标的权限标识id
         * @returns {boolean} true:有权限,false:没有权限
         */
        checkPermission(permissionId) {
            if (!this.isEmpty__(permissionId) && _.isArray(permissionId)) {
                return permissionId.some(this.checkPermission);
            }
            if (!this.fid || !this.appid) {
                return true;
            }
            if (!permissionId || this.isAppAdmin || this.isAdmin) {
                return true;
            }
            const checkRes = (this.permissionIdList || []).some(
                (id) =>
                    (Math.abs(id) & permissionId) == permissionId ||
                    this.isEqual__(Math.abs(id), 0) // 0表示全部权限
            );
            return checkRes;
        },
        /**
         * 设置权限
         * @see 查询系统权限
         * @returns {Promise<void>} 失败抛错,成功设置权限
         */
        async permissionInit() {
            this.permissionIdList = [];
            if (this._paramValidate()) {
                const requestList = [
                    this.permissionData.getViewInfo({
                        fid: this.fid,
                        convert: false,
                    }),
                ];
                let roleList;
                // 仓库已经存在权限表
                if (!this.isEmpty__(this.$store.getters.roles)) {
                    roleList = this.$store.getters.roles;
                } else {
                    requestList.push(
                        this.permissionData.getLoginInfo({ convert: false })
                    );
                }
                // 请求权限信息
                const datas = await Promise.all(requestList).catch((err) => {
                    this.errHand__(err);
                    throw err;
                });
                if (datas[1]) {
                    roleList = datas[1].roles;
                }
                /** 权限id */
                const authorityId = datas[0].authorityId;
                if (!this.appid) {
                    console.warn(
                        "kqPermissionMixin - 未查询到appid(无应用id无视即可),这将开放所有按钮权限"
                    );
                    this.permissionIdList = [];
                }
                /** 默认所有的权限id */
                const defaultAllAuthId = await this._getDefaultAllAuth().catch(
                    (err) => {
                        this.errHand__(err);
                        throw err;
                    }
                );
                roleList.some((role) => {
                    if (!this.isEmpty__(role)) {
                        const [appId2, authorityId2, permissionId] =
                            role.split(":");
                        // 超管判断(已经设置过则不再判断)
                        if (appId2 === "0") {
                            this.isAdmin = true;
                        }
                        // 应用管理员判断(已经设置过则不再判断)
                        else if (appId2 == this.appid) {
                            // 设置是否app管理员
                            if (!this.isAppAdmin) {
                                this.isAppAdmin = this.isEqual__(
                                    authorityId2,
                                    0
                                );
                            }
                            if (this.isEqual__(authorityId2, authorityId)) {
                                /** 结果权限id */
                                let authId = Number(
                                    permissionId.kqMatch(/\{(-?\d+)\}/, 1) || -1
                                );
                                // 未设置权限:采用默认全部权限
                                if (this.isEqual__(authId, -1)) {
                                    authId = -defaultAllAuthId;
                                }
                                this.permissionIdList.push(authId);
                            }
                        }
                    }
                    return this.isAppAdmin && this.isAdmin;
                });

                // 初始化参数
                this._paramInit(datas[0]);
            }
        },
        /**
         * 获取默认全部权限id
         * @desc 当前应用没有配置权限时,权限值为全部权限,此时可以支持学校个性参数表配置全部权限为哪些
         * @desc 如果没有配置则使用503权限值
         * @returns {Promise<number>}
         */
        async _getDefaultAllAuth() {
            let authId = 503;
            const btnList = await this._getDefaultAuthConfig().catch((err) => {
                throw err;
            });
            if (!this.isEmpty__(btnList)) {
                authId = btnList.reduce((prev, next) =>
                    prev && prev.id
                        ? Number(prev.id) + Number(next.id)
                        : prev + Number(next.id)
                );
            }
            return authId;
        },
        _getDefaultAuthConfig() {
            return this.$store
                .dispatch(
                    "config/get",
                    "AUTH_BTNS_DEFAULT.defaultAuths.value.values"
                )
                .catch((err) => {
                    this.errHand__(err);
                });
        },
        /**
         * 初始化参数
         * @see 初始化权限参数
         * @see setPermission调用
         * @param {object} 权限对象
         * @returns void
         */
        _paramInit({
            dataAuthority,
            funcTempType,
            helpText,
            isBillStatus,
            isEdit,
            isLine,
            isSubmit,
            isUnlock,
            sendMSG,
            tempId,
            videoUrl,
            viewID,
            viewName,
        }) {
            // this.dataAuth = dataAuthority;
            this.ftype = funcTempType;
            this.help = helpText;
            this.isFiled = Boolean(Number(isBillStatus)); // 是否显示单据状态
            this.isEdit = Boolean(Number(isEdit)) && this.checkPermission(2); // 是否配置了编辑按钮
            this.isVerticalLine = Boolean(Number(isLine));
            this.isSubmit = Boolean(Number(isSubmit));
            this.isUnlock = Boolean(Number(isUnlock));
            this.isSendMsg = !_.isEmpty(sendMSG);
            this.tid = tempId;
            this.videoUrl = videoUrl;
            this.fvid = viewID;
            this.vname = viewName;
            let msgs = {};
            if (sendMSG) {
                sendMSG.split(",").forEach((auth) => {
                    msgs[auth] = true;
                });
            }
            this.msgPermissions = msgs;
        },
        /**
         * 参数校验
         * @returns {boolean} true:通过
         */
        _paramValidate() {
            const title = "参数校验",
                from = "kqPermissionMixin";
            let res = true;
            if (!this.fid) {
                console.warn(
                    new kq.Err({
                        title,
                        message: `功能点号(fid)为空,这将跳过所有按钮权限校验~`,
                        console: false,
                        from,
                    }).message
                );
                res = false;
            }
            if (!this.appid) {
                console.warn(
                    new kq.Err({
                        title,
                        message: `应用id(appid)为空,这将跳过应用级按钮权限校验~`,
                        console: false,
                        from,
                    }).message
                );
            }
            return res;
        },
    },
};
</script>
