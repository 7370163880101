import moment from "moment";

/**
 * 日期格式化
 * @param {string} format 格式串(参考moment)
 * @returns {string}
 */
export function dateFormat(format) {
    return moment(this).format(format);
}

/**
 * 清除脚本
 * @returns {string}
 */
export function clearScript() {
    return unescape(
        this.replace(/&#xA0;/g, "&nbsp;").replace(/&#x(.*?);/g, "%u$1")
    );
}

/**
 * 清除html标签
 * @returns {string}
 */
export function clearHtml() {
    return this.replace(/<br ?\/>/gi, "\n")
        .replace(/(<[^>]*>)/gi, "")
        .replace(/&nbsp;/gi, " ");
}

/**
 * 将字符串转换为html
 * @see 替换\n空格与h标题
 * @returns {string}
 */
export function toHtml() {
    let result = this;
    // 本身不是html
    if (!/<.+>/.test(result)) {
        result = result
            .replace(
                /#([1-9]) ([^\n]+)\n/g,
                ($, $1, $2) => `<h${$1}>${$2}</h${$1}>`
            )
            .replace(/ /g, "&nbsp;");
    }
    result = result.replace(/\n/g, "<br/>");
    return result;
}

/**
 * 获取query参数
 * @returns {object}
 */
export function query() {
    const result = {};
    let str = this;
    if (str.includes("%u")) {
        str = decodeURI(unescape(str));
    } else {
        str = unescape(decodeURI(str));
    }
    const querys = str.match(/[?&]{1}[^=]+=[^?&#\/]+/g);
    if (querys) {
        querys.forEach((queryStr) => {
            const [key, value] = queryStr.split("=");
            result[key.slice(1)] = value;
        });
    }
    return result;
}

/**
 * 添加query参数
 * @param {string} key query参数的key
 * @param {string|number} value query参数的value
 * @returns {string}
 */
export function addQuery(key, value) {
    return this.replace(
        /((\?|&)([^&#\/]+=[^&#\/]+))?$/,
        ($, $1, $2, $3) => `${$}${$2 === "?" || "&" ? "&" : "?"}${key}=${value}`
    );
}
