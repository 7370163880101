import { render, staticRenderFns } from "./Sort.vue?vue&type=template&id=19b027db&scoped=true&"
import script from "./Sort.vue?vue&type=script&lang=js&"
export * from "./Sort.vue?vue&type=script&lang=js&"
import style0 from "./Sort.vue?vue&type=style&index=0&id=19b027db&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../kooci-saas-recruit/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19b027db",
  null
  
)

export default component.exports