/**
 * 视图相关接口
 * @see 视图查询,修改
 */

export default class View {
    /**
     * 查询视图号
     * @param {object} params
     * @param {string|number} params.fid 功能点号
     * @param {string} params.vlabel 视图别名
     * @returns {Promise<number>} 成功返回视图号,失败抛错
     */
    async getVid(params) {
        const data = await this.getViewInfo({ ...params, convert: true }).catch(
            (err) => {
                throw err;
            }
        );
        return data.vid;
    }
    /**
     * 根据功能点id查询视图信息
     * @see 对应诚迈
     * @param {object} params
     * @param {string|number} params.fid 功能点号
     * @param {string} params.vlabel 视图别名
     * @param {string} params.vid 视图号
     * @param {boolean} [params.isPerson=false] 是否是个人数据的视图详情
     * @returns {Promise<object>} 失败抛错,成功返回视图信息对象
     */
    async getViewInfo({ fid, vlabel, vid, isPerson = false }) {
        // 参数校验
        if (!fid && !vlabel && !vid) {
            return Promise.reject(
                new Error(
                    `[Request.getVid]参数错误!查询视图号必须提供功能点号(fid)或视图别名(vlabel)或视图号(vid)`
                )
            );
        }
        const params = { params: {}, convert: "viewInfo" };
        if (!vid && fid) {
            params.url = "/api/common/dict/queryViewsByFuncId";
            params.params.funcId = fid;
        } else if (vlabel && !vid) {
            vid = await this.request({
                url: "/api/view/getViewIdByLabel",
                params: {
                    label: vlabel,
                },
            }).catch((err) => {
                throw err;
            });
        }
        if (vid) {
            params.url = isPerson ? "/api/view/DIY" : "/api/view";
            params.params.viewId = vid;
        }
        const data = await this.request(
            {
                ...params,
                timeout: 8000,
                timeoutConfig: {
                    reRequest: {
                        enabled: true,
                        maxCount: 3,
                    },
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        if (_.isEmpty(data)) {
            throw new kq.Err({
                title: "配置错误",
                type: "CONFIG",
                message: "该功能点未绑定视图信息,请联系管理员确认",
                from: "kq.Request.View.getViewInfo",
                current: arguments[0],
                console: false,
            });
        }
        if (_.isArray(data)) {
            data[0].fid = fid;
            return data[0];
        }
        data.fid = fid;
        if (!data.vid && !params.url.includes("DIY")) {
            console.warn(
                new kq.Err({
                    title: "配置错误",
                    message: "该功能点没有绑定视图号",
                    current: fid,
                    from: "kq.Request.getViewInfoByVid",
                    type: "CONFIG",
                    console: false,
                })
            );
        }
        return data;
    }
    async getViewInfoByVid({ fid, vid, isPerson }) {
        // 参数校验
        if (!fid && !vid) {
            return Promise.reject(
                new Error(
                    `[Request.getVid]参数错误!查询视图号必须提供功能点号(fid)或视图号(vid)`
                )
            );
        }
        if (!vid) {
            const info = await this.request({
                url: "/api/common/dict/queryViewsByFuncId",
                params: { funcId: fid },
                convert: "viewInfo",
            }).catch((err) => {
                throw err;
            });
            vid = info[0].vid;
            if (!vid) {
                throw new kq.Err({
                    title: "配置错误",
                    message: "该功能点没有绑定视图号",
                    current: fid,
                    from: "kq.Request.getViewInfoByVid",
                    type: "CONFIG",
                });
            }
        }
        return this.request(
            {
                url: isPerson ? "/api/view/DIY" : "/api/view",
                params: { viewId: vid },
                convert: "viewInfo",
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
    }
    /**
     * 更新视图
     * @param {object} param 参数表
     * @param {number} [param.fid] 功能点号
     * @param {string} [param.vlabel] 视图别名
     * @param {number} param.vid 视图号
     * @param {number} [param.uid] 人员号
     * @param {[object]} [param.fieldList] 字段列表
     * @param {[object]} [param.linkageList] 字段联动表
     * @param {boolean} [param.isPerson = false] 是否为个人视图更新
     * @returns {Promise<void>} 失败抛错
     */
    async updateView({
        fid,
        vlabel,
        vid,
        uid,
        fieldList,
        isPerson = false,
        linkageList,
    }) {
        const from = "kqRequest.View.updateView";
        const console = false;
        const current = arguments[0];
        if (!uid || (!fid && !vlabel && !vid)) {
            throw new kq.Err({
                message:
                    "更新视图的视图号(vid|fid|vlabel)与人员号(!uid)不能为空",
                from,
                console,
                current,
            });
        }
        if (!vid) {
            vid = await this.getVid({ fid, vlabel }).catch((err) => {
                throw err;
            });
        }
        let url, data;
        if (isPerson) {
            url = "/api/view/DIY/update";
            data = this._convertViewFieldList(fieldList).map((field) => ({
                ...field,
                viewId: field.vid || vid,
                userId: field.uid || uid,
                nodeId: field.zid,
            }));
        } else {
            url = "/api/view/update";
            data = {
                viewId: vid,
                viewTableInfo:
                    fieldList &&
                    this._convertViewFieldList(fieldList).map((field) => ({
                        ...field,
                        nodeId: field.zid,
                    })),
                lowCodeLayout: encodeURI(JSON.stringify(linkageList)),
            };
        }
        return this.request(
            {
                url,
                method: "post",
                data,
            },
            arguments[0]
        );
    }
    _convertViewFieldList(fieldList) {
        return (fieldList || []).map((field) => {
            const res = {};
            _.forEach(field, (value, key) => {
                if (["isQuery", "isSort", "isTitle"].includes(key)) {
                    res[key] = Number(value);
                } else {
                    res[key] = value;
                }
            });
            return res;
        });
    }
    /**
     * 不再提醒
     * @param {object} param 参数对象
     * @param {number} param.fid 功能点号
     * @returns {Promise<void>} 失败抛错
     */
    neverNotify({ fid }) {
        if (!fid) {
            throw new kq.Err({
                message: "功能点号(fid)必传",
                current: arguments[0],
                from: "kq.Request.View.neverNotify",
            });
        }
        return this.request(
            {
                url: "/api/app/addFuncHelpNotHint",
                params: { funcId: fid },
            },
            arguments[0]
        );
    }
    /**
     * 获取功能点号与视图号参数表
     * @protected
     * @param {object} param 参数对象
     * @param {number} [param.fid] 功能点号
     * @param {number} [param.vid] 视图号
     * @returns {Promise<object>} 成功返回功能点号和视图号,失败抛错
     */
    async _getFidVidParams({ fid, vid }) {
        if (!fid && !vid) {
            throw new kq.Err({
                title: "参数错误",
                message: "功能点号(fid)和视图号(vid)不能同时为空",
                current: arguments[0],
                from: "kq.Request.getRowsetFieldList",
                console: false,
            });
        }
        if (!vid) {
            vid = await this.getVid({ fid }).catch((err) => {
                throw err;
            });
        }
        if (!fid) {
            const res = await this.getViewInfo({ vid }).catch((err) => {
                throw err;
            });
            fid = res.fid;
        }
        return { fid, vid };
    }
}
