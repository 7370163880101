import $ from "jquery";
/**
 * dom滚动到可视区
 * @desc 会根据是否存在滚动条自动决定是否需要横向滚动和纵向滚动
 * @desc 内部使用了jquery动画
 * @param {object} [param0={}] 入参
 * @param {string|HTMLElement} [param0.scrollDom="html,body"] 滚动区dom
 * @param {number} [param0.offset=0] 滚动偏移(指的是滚动到指定位置的偏移量)
 * @param {number} [param0.jqueryAnimate] jquery的animate属性参数对象
 */
export function scrollToVisible({
    scrollDom = "html,body",
    offset = 0,
    jqueryAnimate,
} = {}) {
    /** 滚动操作的key(scrollTop|scrollLeft,根据) */
    const attrs = {};
    /** 滚动层的dom */
    const $scroll = $(scrollDom);
    /** 是否存在水平滚动条 */
    const hasHorticalScroll = $scroll.width() <= $scroll[0].scrollWidth;
    /** 是否存在 垂直滚动条 */
    const hasVerticalScroll = $scroll.height() <= $scroll[0].scrollHeight;
    /** 滚动区距离屏幕的坐标 */
    let { top: scrollTop, left: scrollLeft } =
        $scroll[0].getBoundingClientRect();
    /** 目标dom距离屏幕的坐标 */
    let { top: targetTop, left: targetLeft } = this.getBoundingClientRect();
    // 存在水平滚动条:设置滚动间距
    if (hasHorticalScroll) {
        attrs.scrollLeft = targetLeft - scrollLeft - offset + "px";
    }
    /** 存在垂直滚动条设置滚动间距 */
    if (hasVerticalScroll) {
        attrs.scrollTop = targetTop - scrollTop - offset + "px";
    }
    $scroll.animate(attrs, jqueryAnimate);
}
