/* eslint-disable */
/**
 * 字段接口类
 */

import _ from "lodash";
import Tools from "kooci-lib/src/PubComponents/Utils/tools";
import Template from "./Template";
import View from "./View";
import App from "./App";
import ConvertExtend from "kooci-lib/src/PubComponents/Mixin/Data/Convert/Extend";
import FormConfig from "kooci-lib/src/PubComponents/Form/config.js";

export default class Field extends Tools.mixin(Template, View, App) {
    /**
     * 获取行集类型(main:主行集/直属行集, child:附属行集/孙行集)
     * @param {object} param0 入参对象
     * @param {number} param0.zid 字段号
     * @returns {Promise<string>} 成功返回: main:主行集/直属行集, child:附属行集/孙行集,失败抛错
     */
    async getRowsetType({ zid }) {
        if (Tools.isEmpty(zid)) {
            throw new kq.Err({
                message: "字段号(zid)参数不能少",
                from: "kq.Request.Field.getRowsetType",
                current: arguments[0],
            });
        }
        const type = await this.request(
            {
                url: "/api/common/dict/getpropertyType",
                params: {
                    attrId: zid,
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        return Tools.isEqual(type, 1) ? "main" : "child";
    }
    /**
     * 获取行集字段详情
     * @description 对于getFieldList的优化与拆分,将行集字段单独查出来进行查询与优化,数据结构与原来一致不变
     * @param {object} param 参数表
     * @param {object} param 参数表
     * @param {number} param.uid 人员内部号
     * @param {number} param.zid 行集字段号
     * @param {number} [param.fid] 功能点号(不能与视图号同时为空)
     * @param {number} [param.vid] 视图号(不能与功能点号同时为空)
     * @param {number} [param.rid] 经历号
     * @returns {Promise<object>} 成功返回行集字段对象,失败抛错
     */
    async getRowsetField({ fid, vid, rid, uid, zid }) {
        if (Tools.isEmpty(uid) || !zid) {
            throw new kq.Err({
                title: "参数错误",
                message: "人员号(uid)与行集字段号(zid)不能为空",
                current: arguments[0],
                from: "kq.Request.getRowsetFieldList",
                console: false,
            });
        }
        /** 综合参数对象 */
        const params = await this._getFidVidParams({ fid, vid }).catch(
            (err) => {
                throw err;
            }
        );
        return this.request({
            url: "/api/common/dict/queryRowSetDetail",
            params: {
                funcId: params.fid,
                viewId: params.vid,
                billId: rid,
                attrId: zid,
                billRootId: uid,
            },
            convert: "Field",
        });
    }
    /**
     * 获取普通属性字段列表
     * @description 对于getFieldList的优化与拆分,将普通属性字段单独查出来进行查询与优化,数据结构与原来一致不变
     * @param {object} param 参数表
     * @param {number} param.uid 人员内部号
     * @param {number} [param.fid] 功能点号(不能与视图号同时为空)
     * @param {number} [param.vid] 视图号(不能与功能点号同时为空)
     * @param {number} [param.rid] 经历号
     * @returns {Promise<[object]>} 失败抛错
     */
    async getNormalFieldList({ uid, fid, vid, rid }) {
        if (Tools.isEmpty(uid)) {
            throw new kq.Err({
                title: "参数错误",
                message: "人员号(uid)不能为空",
                current: arguments[0],
                from: "kq.Request.getRowsetFieldList",
                console: false,
            });
        }
        const params = await this._getFidVidParams({ fid, vid }).catch(
            (err) => {
                throw err;
            }
        );
        return this.request({
            url: "/api/common/dict/queryGeneralProperties",
            params: {
                billRootId: Tools.isEqual(0, uid) ? undefined : uid,
                viewId: params.vid,
                funcId: params.fid,
                billId: kq.Tools.isEqual(rid, 0) ? undefined : rid,
            },
            convert: "Field",
        });
    }
    /**
     * 查询字段表信息
     * @see 不要同时传所有的参数,否则会导致出现未知的问题
     * @param {object} params
     * @param {string|number} [params.fid] 功能点号(单独+联合查询: 传fid表示获取功能点绑定的普通或行集字段内容,和vid可以一起传入查询当前登录人的字段与值)
     * @param {string|number} [params.vid] 视图号(单独+联合查询:传vid表示查询视图下配置的信息,没有值,和fid配置可以查询当前登录人的字段值,和rid与uid可以查询指定人和经历的值(需要权限))
     * @param {string|number} [params.uid] 用户号(联合查询:指定则查询指定用户,需要和fid,vid联合查询)
     * @param {string} [params.vlabel] 视图别名(单独查询:查询视图别名绑定的视图下的字段内容,不包含值)
     * @param {string|number} [params.rid] 经历号(指定则查询指定经历信息, 需要和fid, vid联合查询)
     * @param {string|number} [params.pid] 流程号(独立查询流程下的行集样张下的字段)
     * @param {string|number} [params.tid] 样张号(独立查询样张下所有字段)
     * @param {boolean} [params.isConvertSelect] 是否将结果转为码表类型(提供给element组件使用)
     * @param {boolean} [params.isPerson=false] 是否为个信息
     * @param {boolean} [params.isOnlyNormal=false] 是否只查询普通属性信息
     * @param {boolean} [params.useRowsetApi=false] 指定使用行集查询api
     * @returns {Promise<object>} 成功返回信息对象,失败抛错
     */
    async getFieldList({
        tid,
        fid,
        vid,
        vlabel,
        pid,
        uid,
        rid,
        prid,
        isConvertSelect,
        isPerson = false,
        useRowsetApi = false,
    }) {
        if (!tid && !fid && !vid && !pid) {
            throw new kq.Err({
                title: "参数错误",
                message:
                    "样张号(tid),视图号(vid),功能点号(fid),流程号(pid)至少有一个不能为空",
                current: arguments[0],
                from: "Request.getFieldList",
                console: false,
            });
        }
        const VIEW_API = isPerson ? "/api/view/DIY" : "/api/view";
        const TEMP_API = "/api/temp/view";
        const ROWSET_API =
            "/api/common/dict/queryFormDataByRootBillIdAndBillIdAndViewId";
        const NORMAL_API =
            "/api/common/dict/queryFormDataByViewIdAndRootBillId";
        /** api */
        let url = "";
        /** 参数 */
        let params = {};
        // 无功能点号
        if (!fid) {
            // 根据样张号查询
            if (tid) {
                url = TEMP_API;
                params = { tempId: tid };
            }
            // 查询流程下的样张字段
            else if (pid) {
                tid = await this.getTid({ pid }).catch((err) => {
                    throw err;
                });
                url = TEMP_API;
                params = { tempId: tid };
            }
            // 根据视图号查询
            else if (vid || vlabel) {
                if (!vid) {
                    vid = await this.getVid({ vlabel }).catch((err) => {
                        throw err;
                    });
                }
                if (rid || useRowsetApi) {
                    url = ROWSET_API;
                } else if (uid) {
                    url = NORMAL_API;
                } else {
                    url = VIEW_API;
                }
                params = { viewId: vid, rootBillId: uid, billId: rid };
            } else {
                throw new kq.Err({
                    title: "未知的查询条件",
                    message: "当前传入的参数不存在联合查询类型,请查看阅读文档",
                    from: "Request.getFieldList",
                    console: false,
                    current: arguments[0],
                });
            }
        }
        // 有功能点号
        else {
            // 根据功能点号
            if (!vid) {
                vid = await this.getVid({ fid, vlabel }).catch((err) => {
                    throw err;
                });
                if (!vid) {
                    throw new kq.Err({
                        title: "查询错误",
                        message: "当前功能点未绑定视图号~",
                        type: "CONFIG",
                        from: "Request.getFieldList",
                        console: false,
                        current: fid,
                    });
                }
            }
            // 查询经历详情
            if (rid !== undefined) {
                url = ROWSET_API;
                params = { viewId: vid, funcId: fid };
                if (Number(uid)) {
                    params.billRootId = uid;
                }
                // 新经历必须这样查询,原因不知道接口怎么玩的
                if (kq.Tools.isEqual(rid, 0) && !useRowsetApi) {
                    url = NORMAL_API;
                } else {
                    params.billId = rid;
                }
            }
            // 功能点号是视图号都传入了
            else if (uid) {
                url = NORMAL_API;
                params = { viewId: vid, billRootId: uid, funcId: fid };
            }
            // 仅查询视图信息
            else {
                url = VIEW_API;
                params = { viewId: vid };
            }
            // else {
            //     throw new kq.Err({
            //         title: "未知的查询条件",
            //         message: "当前传入的参数不存在联合查询类型,请查看阅读文档",
            //         from: "Request.getFieldList",
            //         console: false,
            //         current: arguments[0],
            //     });
            // }
        }
        const data = await this.request(
            { url, params: { ...params, parentBillId: prid }, convert: url },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        if (isConvertSelect) {
            return new ConvertExtend("FieldToSelect").convert(data);
        }
        return data;
    }
    /**
     * 保存字段
     * @param {object} param
     * @param {string|number} [param.rid] 经历号
     * @param {string|number} [param.uid] 人员内部号
     * @param {[object]} param.fieldList 更新字段表
     * @param {string|number} param.vid 视图号
     * @param {string|number} param.fid 功能点号
     * @param {boolean} [param.isCommit] 是否提交锁定(行集专用)
     * @returns {Promise<void>} 失败抛错
     */
    async saveField({ rid, uid, fieldList, vid, vlabel, fid, isCommit }) {
        const from = "Request.saveField",
            current = arguments[0],
            title = "参数错误";
        if (!vid && !vlabel && !fid) {
            throw new kq.Err({
                title,
                from,
                console: false,
                message:
                    "视图号(vid),视图别名(vlabel),功能点号(fid)不能同时为空,接口需要视图号参数必填",
                current,
            });
        }
        // if (_.isEmpty(fieldList)) {
        //     throw new kq.Err({
        //         title,
        //         from,
        //         message: "字段信息表(fieldList)不能为空",
        //         console: false,
        //         current,
        //     });
        // }
        if (!rid && !uid && fieldList.some(({ uid: uid2 }) => !uid2)) {
            throw new kq.Err({
                title,
                from,
                message: "经历号(rid)与人员内部号(uid)至少一个不能为空",
                current: `uid: ${uid || "null"}, rid: ${rid || "null"}`,
                console: false,
            });
        }
        if (!vid) {
            vid = await this.getVid({ fid, vlabel }).catch((err) => {
                throw err;
            });
        }
        let url, params;
        if (rid) {
            url = "/api/common/dict/updateFormData104";
            params = { billId: rid };
        } else {
            url = "/api/common/dict/updateFormData102";
        }
        return this.request(
            {
                url,
                method: "post",
                data: {
                    ...params,
                    rootBillId: kq.Tools.isEqual(uid, 0) ? null : uid,
                    viewId: vid,
                    funcId: fid,
                    items: this._fieldListConvert(fieldList),
                    isSubmit: Number(isCommit) ? 1 : 0,
                },
            },
            arguments[0]
        );
    }
    /**
     * 创建行集
     * @param {object} param
     * @param {string|number} [param.uid] 经历属于的用户号(不传则使用当前登录人)
     * @param {string|number} [param.vid] 视图号
     * @param {string} [param.vlabel] 视图别名(和功能点号视图号必须传一个)
     * @param {number} [param.fid] 功能点号(视图号,视图别名必须传一个)
     * @param {[object]} [fieldList] 创建行集时需要同时更新的字段信息表
     * @param {boolean} [isCommit=false] 是否穿件已经提交的行集
     * @param {number} [param.prid] 父级的经历号(子孙行集使用)
     * @param {number} [param.zrid] 直属行集经历号(附属行集使用)
     * @param {Promise<object>} 成功返回{rid<number>:经历号,uid<number>:经历所属人员号},失败抛错
     */
    async createRowset({
        uid,
        vid,
        vlabel,
        fid,
        fieldList,
        isCommit,
        prid,
        zrid,
    }) {
        const title = "参数错误",
            from = "Request.createRowset",
            current = arguments[0];
        if (!fid && !vlabel && !vid) {
            throw new kq.Err({
                title,
                from,
                current,
                message:
                    "视图号(vid|vlabel),功能点号(fid)不能同时为空~因为接口视图号参数非空",
                console: false,
            });
        }
        // 补齐视图号
        if (!vid) {
            vid = await this.getVid({ fid, vlabel }).catch((err) => {
                throw err;
            });
        }
        // 字段列表补齐
        fieldList = fieldList || [];
        const info = await this.request(
            {
                url: "/api/common/dict/createListFormData",
                method: "post",
                data: {
                    rootBillId: kq.Tools.isEqual(uid, 0) ? null : uid,
                    viewId: vid,
                    funcId: fid,
                    items: this._fieldListConvert(fieldList),
                    isSubmit: Number(isCommit) ? 1 : 0,
                    parentBillId: prid,
                    parentTempRowId: zrid,
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        return {
            rid: info.billId,
            uid: info.rootBillId,
        };
    }
    /**
     * 删除行集(多个)
     * @param {object} param
     * @param {number} [appid] 应用号(和视图号,视图别名必传1)
     * @param {number} [fid] 功能点号(和视图号,视图别名必传1)
     * @param {number} [vid] 视图号(和功能点号,视图别名必传1)
     * @param {string} [vlabel] 视图别名(与功能点号视图号必传1)
     * @param {number} [rid] 经历号(与经历列表必传1)
     * @param {[number]} [ridList] 经历号表(与经历号必传1)
     * @returns {Promise<void>} 失败抛错
     */
    async deleteRowset({ appid, fid, vid, vlabel, rid, ridList }) {
        const title = "参数错误",
            from = "kq.Request.deleteRowset",
            current = arguments[0];
        if (!vid && !fid && !vlabel && !appid) {
            throw new kq.Err({
                title,
                from,
                current,
                message:
                    "视图号(vid|vlabel),功能点号(fid),应用号(appid)不能同时为空~因为接口视图号参数非空",
                console: false,
            });
        }
        if (!vid) {
            vid = await this.getVid({ fid, vlabel }).catch((err) => {
                throw err;
            });
        }
        if (!appid) {
            if (!fid) {
                const { fid: newFid } = await this._getFidVidParams({
                    vid,
                }).catch((err) => {
                    throw err;
                });
                fid = newFid;
            }
            appid = await this.getAppid({ fid }).catch((err) => {
                throw err;
            });
        }
        if (!rid && _.isEmpty(ridList)) {
            throw new kq.Err({
                title,
                from,
                current,
                message: "经历号(rid|ridList)不能同时为空",
                console: false,
            });
        }
        ridList = ridList || [rid];
        if (ridList.some((rid2) => Number.isNaN(Number(rid2)))) {
            throw new kq.Err({
                title,
                from,
                current: ridList,
                message: "每一个经历号必须为数字型:<string|number>",
                console: false,
            });
        }
        await this.request(
            {
                url: "/api/common/dict/deleteBill",
                method: "post",
                data: {
                    appId: appid,
                    viewId: vid,
                    funcId: fid,
                    deleteIds: ridList,
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
    }
    /**
     * 查询字段号
     * @param {object} param
     * @param {string} param.zlabel
     * @param {Promise<number>} 成功返回字段号,失败抛错
     */
    getZid({ zlabel }) {
        if (!zlabel) {
            throw new kq.Err({
                title: "参数错误",
                console: false,
                from: "kq.Request.getZid",
                current: arguments[0],
                message: "字段别名(zlabel)不能为空",
            });
        }
        return this.request(
            {
                url: "/api/common/dict/label",
                params: { label: zlabel },
            },
            arguments[0]
        );
    }
    /**
     * 查询所有字段列表
     * @param {object} [param] 参数表
     * @param {boolean} [isSelect=false] 是否转换为码表型
     * @param {number} [typeId] 分类id(不传则查询所有样张分类)
     * @param {number} [tid] 样张号(不传则查询所有样张分类)
     * @returns {Promise<[object]>} 失败抛错
     */
    getAllFieldList({ isSelect, typeId, tid } = {}) {
        let compares = {};
        if (isSelect) {
            compares = {
                value: "id",
                label: "name",
                children: "children",
                zlabel: "label",
                type: ["checkId", "nodeType", "NodeType"],
            };
        } else {
            compares = {
                zid: "id",
                zname: "name",
                children: "children",
                type: ["checkId", "nodeType", "NodeType"],
            };
        }
        let url,
            params = {};
        if (typeId && tid) {
            url = "/api/temp/queryTypeAndFieldsByTempId";
            params = { tempId: tid, typeId };
        } else {
            url = "/api/temp/queryTempTree";
        }
        return this.request(
            {
                url,
                params,
                convert: {
                    compares,
                    convertValueHook(param) {
                        // 类型组合转换
                        const { parent, to, value } = param;
                        if (to === "type") {
                            let type = "text-text";
                            const checkId = parent.checkId;
                            const nodeType =
                                parent.nodeType === undefined
                                    ? parent.NodeType
                                    : parent.nodeType;
                            if (
                                checkId !== undefined &&
                                nodeType !== undefined
                            ) {
                                type = FormConfig.getType(nodeType, checkId);
                            } else {
                                console.log("未匹配", parent);
                            }
                            return type;
                        }
                        return value;
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 查询列表信息
     * @param {object} param 参数
     * @param {number} [param.fid] 功能点号
     * @param {string} [param.vlabel] 视图别名
     * @param {number} [param.vid] 视图号
     * @param {number} [param.uid] 指定人内部号查询
     * @param {number} [param.tid] 样张号
     * @param {[object]} [param.filterList] 筛选表
     * @param {string} [param.fuzzyQuery] 模糊查询参数
     * @param {number} [param.currentPage] 当前页码(从1开始)
     * @param {number} [param.pageSize] 一页的个数
     * @param {number} [param.pid] 流程id
     * @param {number} [param.currentProcessZid] 当前节点id
     * @param {[object]} [param.sortList] 排序详情表
     * @param {boolean} [param.isRowset] 是否为行集列表(默认为是)
     * @param {number} [param.prid] 父级的经历号(子孙行集使用)
     * @param {number} [param.frid] 直属行集经历号
     * @param {number} [param.zrid] 直属行集经历号(同frid)
     * @param {number} [param.pzid] 父级的字段号(子孙行集使用)
     * @param {[object]} 成功返回列表详情,失败抛错
     * @param {boolean} virtucalFlag 虚拟人开关(传true只查虚拟人)
     */
    async getList(
        {
            fid,
            vlabel,
            vid,
            uid,
            tid,
            filterList = [],
            fuzzyQuery = "",
            currentPage = 1,
            pageSize = 20,
            pid,
            currentProcessZid,
            sortList = [],
            prid,
            frid,
            zrid,
            pzid,
            isRowset = true,
            virtucalFlag
        }
    ) {
        const title = "参数错误",
            from = "kq.Request.getList",
            current = arguments[0];
        if (!fid && !vid && !vlabel) {
            throw new kq.Err({
                title,
                from,
                current,
                message:
                    "视图号(vid|vlabel),功能点号(fid)不能同时为空~因为接口视图号参数非空",
                console: false,
            });
        }
        if (!vid) {
            vid = await this.getVid({ fid, vlabel }).catch((err) => {
                throw err;
            });
        }
        let url = "/api/common/dict/queryRowsDataByForm";
        if (isRowset) {
            url = "/api/common/dict/queryRowDataList";
        }
        // 筛选结果格式化
        const { conditions, conditionsMapNotEq, conditionsMapIsNull } =
            this._filterFormat(filterList);
        // 请求
        return await this.request(
            {
                url,
                method: "post",
                data: {
                    funcId: fid,
                    viewId: vid,
                    rootBillId: uid,
                    tempId: tid,
                    flowId: pid,
                    functionId: currentProcessZid,
                    defaultQueryValue: fuzzyQuery,
                    conditions,
                    conditionsMapNotEq,
                    conditionsMapIsNull,
                    orders: this._sortFormat(sortList),
                    pageNum: currentPage,
                    pageSize,
                    parentBillId: prid,
                    parentAttrId: pzid,
                    parentTempRowId: frid || zrid,
                    virtucalFlag
                },
                convert: url,
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
    }
    /**
     * 筛选格式化操作
     * @param {[object]} filterList 筛选列表
     * @returns {object} 筛选结果对象(包含精确筛选数组,排它筛选对象,非空筛选对象)
     * @desc conditionsMapNotEq 后端目前不支持多值筛选
     */
    _filterFormat(filterList) {
        /** 筛选结果 */
        const filterRes = {
            conditions: [],
            conditionsMapNotEq: {},
            conditionsMapIsNull: {},
        };
        filterList.forEach((filter) => {
            // 筛选值|筛选值列表, 排除值, 是否为空筛选
            const { value, valueList, exclude, isNull } = filter;
            if (value !== undefined || valueList !== undefined) {
                this._oreciseFilterFormat(filter, filterRes);
            } else if (exclude !== undefined) {
                this._excludeFilterFormat(filter, filterRes);
            } else if (isNull !== undefined) {
                this._isNullFilterFormat(filter, filterRes);
            }
        });
        return filterRes;
    }
    /**
     * 精确筛选格式化
     * @param {object} param 参数表
     * @param {object} filterRes 筛选结果对象
     * @returns {object} 筛选结果对象
     */
    _oreciseFilterFormat({ zid, value, valueList }, filterRes = {}) {
        // 排除空筛选
        if (!_.isArray(value || valueList) || !_.isEmpty(value || valueList)) {
            if (!filterRes.conditions) {
                filterRes.conditions = [];
            }
            if (_.isEmpty(valueList)) {
                if (_.isArray(value)) {
                    valueList = value;
                } else {
                    valueList = [value];
                }
            }
            filterRes.conditions.push({
                nodeId: zid,
                nodeValue: valueList,
            });
        }
        return filterRes;
    }
    /**
     * 排它筛选格式化
     * @param {object} param 筛选对象
     * @param {number} param.zid 字段号
     * @param {number} param.exclude 排除的字段值
     * @param {object} filterRes 筛选结果对象
     * @returns {object} 筛选结果对象
     */
    _excludeFilterFormat({ zid, exclude }, filterRes = {}) {
        if (!filterRes.conditionsMapNotEq) {
            filterRes.conditionsMapNotEq = {};
        }
        filterRes.conditionsMapNotEq[zid] = exclude;
        return filterRes;
    }
    /**
     * 非空筛选格式化
     * @param {object} param 筛选对象
     * @param {number} param.zid 字段号
     * @param {number} param.isNull 是否为空(true:是)
     * @param {object} filterRes 筛选结果对象
     * @returns {object} 筛选结果对象
     */
    _isNullFilterFormat({ zid, isNull }, filterRes = {}) {
        if (!filterRes.conditionsMapIsNull) {
            filterRes.conditionsMapIsNull = {};
        }
        filterRes.conditionsMapIsNull[zid] = isNull;
        return filterRes;
    }
    _sortFormat(sortList) {
        if (!sortList) {
            return [];
        }
        const from = "kqRequest.Field.getList";
        const current = sortList;
        if (sortList.some(({ zid }) => !zid)) {
            throw new kq.Err({
                message: "排序参数每一项不能缺少字段号(zid)",
                current,
                from,
            });
        }
        return sortList.map(({ zid, isAsc = false }, index) => ({
            nodeId: zid,
            sortIndex: index + 1,
            sortType: isAsc ? 0 : 1,
        }));
    }
}
