var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-tooltip',_vm._g(_vm._b({attrs:{"content":_vm.tooltipContent,"hide-after":5000}},'el-tooltip',_vm.btn.tooltip.props,false),_vm.btn.tooltip.listeners),[_c('v-confirm',_vm._g(_vm._b({staticClass:"kq-botton-toolbar--popover",attrs:{"disabled":_vm.btn.confirm.disableHandler(_vm.btn) ||
            !!_vm.btn.props.disableHandler(_vm.btn)}},'v-confirm',_vm.btn.confirm.props,false),_vm.btn.confirm.listeners),[_c('el-popover',_vm._g(_vm._b({ref:"popover",attrs:{"disabled":!_vm.custormPopoverComponentDisabled &&
                _vm.btn.popover.props.disableHandler(_vm.btn),"trigger":_vm.btn.popover.props.trigger || 'manual',"popper-class":"kq-bottom-toolbar--popover-pop"}},'el-popover',_vm.btn.popover.props,false),_vm.btn.popover.listeners),[_vm._t("popover",function(){return [_c(_vm.btn.popover.component.is,_vm._g(_vm._b({tag:"compontent",attrs:{"v-if":_vm.btn.popover.component.lazy !== false}},'compontent',_vm.btn.popover.component.props,false),_vm.btn.popover.component.listeners))]},null,_vm.btn.popover),(!_vm.btn.props.disableHandler())?_vm._t("component",function(){return [(
                        !_vm.customComponentDisabled &&
                        _vm.btn.component.is &&
                        _vm.btn.component.lazy !== false
                    )?_c(_vm.btn.component.is,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.btn.component.vshow !== false),expression:"btn.component.vshow !== false"}],ref:_vm.btn.component.ref,tag:"component"},'component',_vm.btn.component.props,false),_vm.btn.component.listeners)):_vm._e()]},null,_vm.btn.component):_vm._e(),_c(_vm.component,_vm._g(_vm._b({directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.btn.outClick),expression:"btn.outClick"}],tag:"component",class:`kq-bottom-toolbar-container-end-btn
                                            ${_vm.btn.css || ''} ${
                    _vm.btn.class || ''
                }`,attrs:{"slot":"reference","on-click":_vm.btn.onClick,"loading":_vm.btn.loadHandler(_vm.btn),"id":_vm.btn.id,"stop-propagation":false,"disabled":!!_vm.btn.props.disableHandler(_vm.btn),"tooltipDisabled":""},on:{"click":function($event){!_vm.btn.props.disableHandler(_vm.btn) && _vm.btn.onClick()}},slot:"reference"},'component',_vm.btn.props,false),_vm.btn.listeners),[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.btn.name))]})],2)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }