<script>
/**
 * 确认值(需要审核的字段)逻辑混入
 * @desc 使用firstSvalue来存储第一次传入的显示值用以恢复原值(恢复的时候只改svalue_,不改value),然后更改值后设置newValue作为要审核的值,最终实现确认效果
 * @desc 注意:不能更改显示时机
 * @author tonny
 * @date 2022-11-22
 */
</script>

<script>
import moment from "moment";
const SUPER = "Kq_Inputcell_ConfirmMixin";
export default {
    SUPER,
    props: {
        /** 新的值 */
        newValue: String,
        /** 是否为审核字段 */
        isReview: Boolean,
        /** 日期格式 */
        dateFormat: String,
    },
    data() {
        return {
            /** 确认混入变量 */
            confirmMixin: {
                /** 是否保存 */
                isSave: null,
            },
        };
    },
    computed: {
        /**
         * 确认字段提示值
         * @desc 根据存储的新值进行判断
         * @desc 更改值后,则最新的值为更改之后的,否则更改值为传入的newValue,否则就默认
         * @returns {string}
         */
        confirmTip() {
            let tip = "需要审核确认的字段";
            let nextValue;
            if (this.isChange || this.confirmMixin.isSave) {
                nextValue = this.svalue_;
            } else if (!this.isChange) {
                nextValue = this._converNewValue(this.newValue);
            }
            return (
                tip +
                (nextValue !== undefined
                    ? `\n即将修改为:\n<span class="kq-white-color">${
                          nextValue || "(空)"
                      }</span>`
                    : "")
            );
        },
        /**
         * 是否存在确认值
         * @returns {boolean}
         */
        hasConfirmValue() {
            return (
                this.isChange ||
                this.confirmMixin.isSave ||
                this.newValue !== undefined
            );
        },
    },
    watch: {
        /**
         * @desc 自动显示与隐藏确认提示
         */
        async confirmTip(tip) {
            if (tip.includes("<span") && this.isChange) {
                await kq.Tools.delay(500);
                this.confirmTipVisible = true;
                await kq.Tools.delay(5000);
                this.confirmTipVisible = false;
            }
        },
    },
    methods: {
        /**
         * 转换确认值
         * @return {string}
         */
        _converNewValue(newValue) {
            if (
                this.type.includes("date") &&
                this.dateFormat &&
                !this.isEmpty__(newValue)
            ) {
                return moment(newValue).format(
                    this.dateFormat
                        .replace("%Y", "YYYY")
                        .replace("%m", "MM")
                        .replace("%d", "DD")
                        .replace("%H", "HH")
                        .replace("%i", "mm")
                        .replace("%s", "ss")
                );
            }
            return newValue;
        },
        /**
         * 刷新清空(重写)
         * @desc 清空确认保存状态
         * @returns void
         */
        refreshClear() {
            this.$super(SUPER).refreshClear();
            this.confirmMixin.isSave = false;
        },
        /**
         * 保存清空(重写)
         * @desc 当更改状态时,添加保存状态
         * @returns void
         */
        saveClear() {
            if (this.isChange) {
                this.confirmMixin.isSave = true;
            }
            this.$super(SUPER).saveClear();
        },
    },
};
</script>
