import { render, staticRenderFns } from "./ConfirmItem.vue?vue&type=template&id=2fb482e7&scoped=true&"
import script from "./ConfirmItem.vue?vue&type=script&lang=js&"
export * from "./ConfirmItem.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmItem.vue?vue&type=style&index=0&id=2fb482e7&prod&lang=less&scoped=true&"
import style1 from "./ConfirmItem.vue?vue&type=style&index=1&id=2fb482e7&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../kooci-saas-recruit/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fb482e7",
  null
  
)

export default component.exports