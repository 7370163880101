import VueRouter from "vue-router";
import _ from "lodash";
import FtypeRouteCompares from "./FtypeCompares";
import { FtypeCompares } from "../global";
import Tools from "../Utils/tools";

export default class Router extends VueRouter {
    /**
     *  路由中的query参数转换为props
     * @desc 通常在路由注册的props中使用
     * @desc 仅转换query参数,如果需要转换query与params综合请使用kq.Router.convertProps
     * @param {object} route 路由对象
     * @param {object} [query={}] query参数
     * @returns {object}
     * @example
     * export default [{
            path: "/review",
            name: "review",
            meta: { title: "经历审核", loginRequired: true },
            props: kq.Router.convertPropsQuery,
            component: () =>
                import("kooci-mobile/src/views/Review/Review.vue"),
        }]
     */
    static convertPropsQuery({ query = {} } = {}) {
        return { ...location.href.kqQuery(), ...query };
    }
    /**
     * 将路由中query与params参数合并转换为props参数
     * @desc 通常在路由注册的props中使用
     * @desc 转换query与params合并后的参数,parmas优先
     * @param {object} route 路由对象
     * @returns {object}
     */
    static convertProps(route = {}) {
        return {
            ...Router.convertPropsQuery(route),
            ...Router.convertPropsParams(route),
        };
    }
    /**
     * 将路中的params参数转换为props参数
     * @desc 通常在路由注册的props中使用
     * @desc 仅转换params参数,如果需要转换query与params综合请使用kq.Router.convertProps
     * @param {object} route 路由对象
     * @returns {object}
     */
    static convertPropsParams({ params = {} } = {}) {
        return params;
    }
    /**
     * 禁止自动安装(云库)
     */
    static get installDisabled() {
        return true;
    }
    /**
     * 路由主模块类
     * @constructor
     * @param {object} params 路由配置对象(详细参照vue-router)
     * @param {[classFunction]} params.routeModuleList 路由模块列表
     * @param {[function]|function} params.beforeEach 前置路由钩子(数组)
     * @param {object} params.store store对象
     */
    constructor(params) {
        if (
            _.isEmpty(params.modules) ||
            _.isEmpty(params.moduleName) ||
            _.isEmpty(params.store)
        ) {
            throw new kq.Err({
                message: "路由表(modules)或仓库(store)不能为空!",
                from: `Router.constructor`,
                current: params,
            });
        }
        // 先初始化一个空路由表的,然后根据路由模块表来重设路由
        super({ ...params, routes: [] });
        /** 仓库对象 */
        this._store = params.store;
        // 设置路由模块实例列表
        this._setRouteModuleInstanceList(params.modules);
        /** 参数表 */
        this._params = params;
        /** 模块名 */
        this.moduleName = params.moduleName;
        // 挂载路由全局前置守卫
        if (params.beforeEach) {
            this._mountBeforeEach(params.beforeEach);
        }
        // 重设路由(将设置完成的路由注入到当前路由中)
        this.resetRouter();
    }
    /**
     * 错误处理
     * @param {object} error 错误对象
     * @returns void
     */
    handler502(error) {
        console.warn(
            new kq.Err({
                title: "编码错误",
                type: "CODE",
                message: "处理502的方法必须重新实现",
                from: "kq.Router.handler502",
                console: false,
            }).message
        );
    }
    /**
     * 打开功能
     * @param {object} param0 参数对象
     * @return {Promise<void>} 成功跳转对应路由,失败抛错
     */
    async open(params) {
        if (kq.Tools.isEmpty(params.appid, params.fid)) {
            throw new kq.Err({
                message: "打开函数(appid)与功能点号(fid)不能同时为空",
                current: params,
                from: "kq.Router.open",
                console: false,
            });
        }
        if (kq.Tools.isEmpty(params.fid)) {
            return this.openApp(params);
        }
        return this.openFunction(params);
    }
    /**
     * 打开应用
     * @desc 查询应用对应的权限推荐功能或者是前面的功能
     * @param {object} param0 参数对象
     * @param {number} param0.fid 功能点号
     * @param {number} [param0.rid] 经历号
     * @param {number} [param0.uid] 人员号
     * @param {object} [param0.query] query参数
     * @param {string} [param0.device] 设备(mobile:手机端,pc|null:pc端)
     * @param {boolean} [param0.isFilter=false] 当前是否需要过滤隐藏的应用
     * @returns {Promise<void>} 成功直接跳转对应功能点,失败抛错
     */
    async openApp({ appid, query, fid, dataAuth, device, isFilter = false }) {
        const error = {
            console: false,
            from: "kq.Router.openApp",
            current: arguments[0],
            type: false,
        };
        if (kq.Tools.isEmpty(appid)) {
            throw new kq.Err({
                ...error,
                message: "打开应用应用号(appid)不能为空",
            });
        }
        /** app导航信息表(存储所有权限对应所有功能点信息对象或者单个权限的所有功能点列表) */
        const appNavs = await this._store
            .dispatch("app/getRouteInfo", { appid, dataAuth, device })
            .catch((err) => {
                throw err;
            });
        if (Tools.isEmpty(appNavs)) {
            throw new kq.Err({
                ...error,
                title: "权限错误",
                message: "应用下没有可以打开的功能点",
            });
        }
        /** 用来查询的功能点信息列表 */
        let findInfoList;
        // 空权限:取最小权限
        if (Tools.isEmpty(dataAuth)) {
            // 转换为数组后>升序排序>取第一个
            dataAuth = _.map(appNavs, (val, key) => key).sort(
                (prev, next) => prev - next
            )[0];
            findInfoList = appNavs[dataAuth];
        } else {
            findInfoList = appNavs;
        }
        if (Tools.isEmpty(findInfoList)) {
            throw new kq.Err({
                ...error,
                message: "当前数据权限对应的功能不存在,请联系管理员~",
            });
        }
        let firstFuncInfo;
        /** 功能点信息 */
        let funcInfo = findInfoList.kqFindDeep((info) => {
            // 功能点未被隐藏
            if (!info.disabled || !isFilter) {
                const { fid: funcFid, recommend } = info;
                if (!firstFuncInfo) {
                    firstFuncInfo = info;
                }
                // 空功能点号就选默认推荐的功能
                if (Tools.isEmpty(fid)) {
                    return Tools.isEqual(recommend, 1);
                }
                // 有功能点号就选功能点号相同的功能
                else {
                    return Tools.isEqual(fid, funcFid);
                }
            }
        });
        // 功能点号不为空,但是找不到应用信息
        if (!Tools.isEmpty(fid) && Tools.isEmpty(funcInfo)) {
            throw new kq.Err({
                ...error,
                message:
                    "打开的功能点未查询到功能信息~可能没有改功能使用权限,请联系管理员~",
            });
        }
        if (Tools.isEmpty(funcInfo)) {
            funcInfo = firstFuncInfo;
        }
        if (Tools.isEmpty(funcInfo)) {
            throw new kq.Err({
                ...error,
                message: "未查询到任何可以打开的功能点",
                type: "CONFIG",
                title: "查询错误",
            });
        }
        return this.openFunction({
            ...arguments[0],
            fid: funcInfo.fid,
            appid,
            dataAuth,
            query,
        });
    }
    /**
     * 打开功能点
     * @desc 查询功能点对应的模板信息进行路由模板匹配
     * @param {object} param0 参数对象
     * @param {number} param0.fid 功能点号
     * @param {number} [param0.rid] 经历号
     * @param {number} [param0.uid] 人员号
     * @param {object} [param0.query] query参数
     * @param {string} [param0.device] 设备(mobile:手机端,pc|null:pc端)
     * @returns {Promise<void>} 成功直接跳转对应功能点,失败抛错
     */
    async openFunction({ fid, rid, uid, query }) {
        const error = {
            console: false,
            from: "kq.Router.openFunction",
            current: arguments[0],
            type: false,
        };
        if (kq.Tools.isEmpty(fid)) {
            throw new kq.Err({
                ...error,
                message: "打开功能点功能点号(fid)不能为空",
            });
        }
        const vinfo = await this._store
            .dispatch("view/getVinfo", { fid })
            .catch((err) => {
                throw err;
            });
        if (Tools.isEmpty(vinfo)) {
            throw new kq.Err({
                ...error,
                message: "功能点号错误,查询不到功能点号对应应用信息",
            });
        }
        // 判断个人维护人员号参数报错
        // if (
        //     Tools.isEqual(vinfo.ftype, FtypeCompares.N_MAINTAIN) &&
        //     Tools.isEmpty(uid)
        // ) {
        //     if (this._store) {
        //         uid = this._store.getters.uid;
        //     }
        //     if (Tools.isEmpty(uid)) {
        //         throw new kq.Err({
        //             ...error,
        //             message: "当模板号为个人-信息维护时,人员号不能为空",
        //         });
        //     }
        // }
        /** 结果路由 */
        let resultRoute;
        // 模板路由
        if (/^\/?functionview$/.test(vinfo.path)) {
            /** 路由名称 */
            let routeName = Tools.isEmpty(uid)
                ? _.get(FtypeRouteCompares, `${vinfo.ftype}.list`) ||
                  _.get(FtypeRouteCompares, `${vinfo.ftype}.detail`)
                : _.get(FtypeRouteCompares, `${vinfo.ftype}.detail`);
            if (!Tools.isEmpty(routeName)) {
                routeName =
                    vinfo.componentName === "功能视图"
                        ? routeName.kqMatch(/^[^/]+/, 0)
                        : vinfo.componentName;
                resultRoute = {
                    name: routeName,
                    params: {
                        appid: vinfo.appid,
                        dataAuth: vinfo.dataAuth,
                        fid,
                        uid: Tools.isEmpty(uid) ? this._store.getters.uid : uid,
                        rid,
                    },
                    query,
                };
            } else {
                throw new kq.Err({
                    ...error,
                    message: "功能点查询模板未知,请联系管理员",
                    current: vinfo,
                    type: "CONFIG",
                });
            }
        }
        // 个性化路由
        else {
            resultRoute = this._getPeronalPagePath(vinfo);
        }
        this.push(resultRoute);
    }
    _getPeronalPagePath({ appid, fid, dataAuth, path }) {
        return `/pageframe/function-view/${appid}/${fid}/${dataAuth}/${path}`;
    }
    /**
     * 获取当前路由表
     * @returns {[object]}
     */
    get routeList() {
        return this._getRouteList();
    }
    /**
     * 重置当前路由
     * @returns void
     */
    resetRouter() {
        const newRouter = new VueRouter({
            ...this._params,
            routes: this.routeList,
        });
        this.matcher = newRouter.matcher;
    }
    /**
     * 导入一个异步组件
     * @virtucal
     * @see 用于动态指定页面
     * @param {string} path 组件路径(相较于src/views目录)
     * @returns {function} 异步组件函数
     * @desc 以下为写法参照
     * @example return (resolve) =>
            require([
                `模块名称,不可以是变量/src/views${path[0] === "/" ? "" : "/"}${path}`,
            ], resolve);
     */
    static importAsyncComponent(path) {
        console.warn(
            new kq.Err({
                title: "编码错误",
                type: "CODE",
                message: "必须重写导入异步组件的函数用以实现动态导入组件",
                from: "kq.Router.importAsyncComponent",
                console: false,
            })
        );
    }
    /**
     * 设置路由模块实例列表
     * @private
     * @description 根据路由模块表设置this._routeModuleInstanceList
     * @description 将路由模块的实例注入到this中(比如可以通过:this.pageframe.method调用模块实例方法)
     * @param {[FunctionConstructor]} routeModuleList 路由模块列表
     * @returns {[classConstrucor]} 路由模块实例列表
     */
    _setRouteModuleInstanceList(routeModuleList) {
        /** 路由模块实例列表 */
        this._routeModuleInstanceList = routeModuleList.map((Module) => {
            const instance = new Module({
                resetRouter: this.resetRouter.bind(this),
            });
            this[instance.name.toLocaleLowerCase()] = instance;
            return instance;
        });
    }
    /**
     * 获取路由表
     * @private
     * @returns {[object]} 路由表
     */
    _getRouteList() {
        let resultList = [];
        this._routeModuleInstanceList.forEach((instance) => {
            resultList = resultList.concat(instance.routeList);
        });
        return resultList;
    }
    /**
     * 设置路由表
     * @param {[ClassDecorator]} routeModuleList 路由模块列表
     * @returns {[object]} 路由表
     */
    _setRouteList(routeModuleList) {
        let resultList = [];
        routeModuleList.forEach((RouteModule) => {
            if (RouteModule) {
                const module = new RouteModule({
                    resetRouter: this.resetRouter.bind(this),
                });
                this[module.constructor.name.toLowerCase()] = module;
                resultList = resultList.concat(module.routeList);
            }
        });
        return resultList;
    }
    /**
     * 挂载路由导航守卫
     * @see 支持链式钩子挂载
     * @param {function|[function]} hookHanlders 钩子函数(数组)
     * @returns void
     */
    _mountBeforeEach(hookHanlders) {
        if (_.isFunction(hookHanlders)) {
            hookHanlders = [hookHanlders];
        } else if (!_.isArray(hookHanlders)) {
            throw new kq.Err({
                message: `挂载路由钩子参数必须为函数或函数数组`,
                from: "mountRouteHooks",
                current: hookHanlders,
            });
        }
        if (!this._beforeEachList) {
            this._beforeEachList = hookHanlders.map((handler) => {
                const Handler = handler;
                try {
                    const instance = new Handler({ router: this });
                    handler = instance.beforeEach.bind(instance);
                } catch (error) {
                    console.error(error);
                }
                return handler;
            });
        }
        this.beforeEach((to, from, next) => {
            this._routerAsyncChain(to, from, this._beforeEachList)
                .then((res) => {
                    if (_.isFunction(res)) {
                        res();
                    } else if (res !== false) {
                        // 转入了不同的路由
                        if (res?.name !== to.name) {
                            next(res);
                        } else {
                            next();
                        }
                    }
                })
                .catch((err) => {
                    console.error("路由导航错误:", err);
                    // next(err);
                });
        });
    }
    /**
     * (中转函数)处理链式调用的返回结果
     * @returns void
     */
    _handlerChainResult({ handlerList, res, resolve, reject, from, to }) {
        if (res !== false) {
            if (_.isPlainObject(res)) {
                if (!res.name && !res.path) {
                    throw new kq.Err({
                        title: "路由钩子错误",
                        type: "VUE",
                        message: "路由钩子返回对象时必须为:路由类型对象",
                        current: res,
                        from: "Router.beforeEach",
                    });
                }
                to = res;
            }
            if (_.isFunction(handlerList[1]) && !_.isFunction(res)) {
                this._routerAsyncChain(to, from, handlerList.slice(1))
                    .then((res2) => resolve(res2))
                    .catch((err) => reject(err));
            } else {
                resolve(res);
            }
        }
    }
    /**
     * 路由异步链式调用
     * @description 将多个路由钩子组合进行链式调用,当前前一个钩子返回"false|函数|抛错"则中断其他钩子操作
     * @param {object} to 目标路由对象
     * @param {object} from 源路由对象
     * @param {[function]} handlerList 钩子函数列表
     * @returns {Promise<boolean|object|void>} 成功返回结果(false:中断路由,object:目标路由,void:当前路由)
     */
    _routerAsyncChain(to, from, handlerList) {
        return new Promise((resolve, reject) => {
            /** 当前的钩子守卫 */
            const handler = handlerList[0];
            /** 当前钩子处理结果 */
            const result = handler(to, from);
            // 异步结果:进行异步处理
            if (result instanceof Promise) {
                result
                    .then((res) => {
                        this._handlerChainResult({
                            handlerList,
                            res,
                            resolve,
                            reject,
                            from,
                            to,
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    });
            }
            // 非异步结果
            else if (result !== false) {
                this._handlerChainResult({
                    handlerList,
                    res: result,
                    resolve,
                    reject,
                    from,
                    to,
                });
            } else {
                return result;
            }
        });
    }
}
