/**
 * @description 数据导入导出相关接口模块
 * @author tonny
 * @date 2021-12-24
 */

import _ from "lodash";
import View from "./View";
import Field from "./Field";
import kqFile from "../../File/File.js";
export default class ImportExport extends Field {
    /**
     * 导入数据
     * @see 支持导入文本或excel文件
     * @param {object} param 参数对象
     * @param {number} param.fid 功能点号
     * @param {number} [param.vid] 视图号
     * @param {string} [param.vlabel] 视图别名
     * @param {[[string]]} [param.contentList] 文本内容表(和file文件必须传1个)
     * @param { file } [param.file] excel文件(和contentList必须传1个)
     * @param { string|number } [param.type] 上传类型(1|person: 人员导入, 2|list: 普通,行集列表导入)
     * @returns  {Promise<void>} 失败抛错
     */
    async importData({
        fid,
        vid,
        vlabel,
        contentList,
        zidList,
        file,
        type = "list",
    }) {
        const current = arguments[0];
        const from = "kq.Request.ImportExport.importData";
        const console = false;
        if (_.isEmpty(zidList)) {
            throw new kq.Err({
                message: "导入的字段号参照表(zidList)不能为空",
                current,
                from,
                console,
            });
        }
        if (_.isEmpty(file) && _.isEmpty(contentList)) {
            throw new kq.Err({
                message:
                    "导入的数据不能为空,导入文件(file)或导入内容表(contentList)",
                current,
                from,
                console,
            });
        }
        if (!fid) {
            throw new kq.Err({
                message: "功能点号(fid)参数不能为空",
                current,
                from,
                console,
            });
        }
        if (String(type).kqIsNumber()) {
            if (![1, 2].includes(Number(type))) {
                throw new kq.Err({
                    message:
                        "导入类型参数(type)为数字时, 只能为1:人员导入, 2:普通列表导入或行级类别导入",
                    current: type,
                    from,
                    console,
                });
            }
        } else {
            if (!["person", "list"].includes(type)) {
                throw new kq.Err({
                    message:
                        "导入类型参数(type)为字符串时, 只能为: person: 人员导入, list: 普通列表导入或行级类别导入",
                    current: type,
                    from,
                    console,
                });
            }
        }
        // 查询视图号
        if (!vid) {
            vid = await this.getVid({ fid, vlabel }).catch(err => {
                this.errHand__(err);
                throw err;
            });
        }
        // 导入体转换
        let data = {};
        if (file) {
            data = new FormData();
            data.append("funcId", fid);
            data.append("viewId", vid);
            data.append("fields", zidList.join());
            data.append("file", file);
        } else {
            data = {
                body: contentList,
                funcId: fid,
                viewId: vid,
                fields: zidList,
                type: type === "list" ? 2 : 1,
            };
        }
        return this.request(
            {
                url: file
                    ? "/api/dataImport/doImportWithFile"
                    : "/api/dataImport/doImportWithBody",
                method: "post",
                data,
            },
            arguments[0]
        );
    }
    /**
     * 导出数据(模板)
     * @param {object} param0 参数对象
     * @param {number} param0.fid 功能点号
     * @param {number} [param0.vid] 视图号(不传根据功能点号获取)
     * @param {string} [param0.vlabel] 试图别名
     * @param {string} [param0.vname="数据表"] 视图名称
     * @param {string} [param0.type] 导出类型(template:模板, data|void:数据)
     * @param {[number]} [param0.ridList] 选定既定的经历号列表
     * @param {[number]} [param0.uidList] 选定既定的人员号列表
     * @param {string} param0.defaultQueryValue 搜索关键字
     * @param {number} param0.isShowBillStatus 2不显示归档状态 不传则显示
     * @returns {Promise<void>} 失败抛错
     */
    async export({
        fid,
        vid,
        vlabel,
        vname,
        type = "data",
        filterList,
        ridList,
        uidList,
        defaultQueryValue,
        isShowBillStatus,
    }) {
        if (!fid) {
            throw new kq.Err({
                message: "功能点号(fid)参数不能为空",
                current: arguments[0],
                from: "kq.Request.ImportExport.export",
                console: false,
            });
        }
        if (!vid || !vname) {
            const viewInfo = await this.getViewInfo({ fid, vlabel }).catch(
                err => {
                    throw err;
                }
            );
            vid = viewInfo.vid;
            vname = vname || viewInfo.vname + ".xlsx";
        }
        // 筛选结果格式化
        const {
            conditions,
            conditionsMapNotEq,
            conditionsMapIsNull,
        } = this._filterFormat(filterList);
        return kqFile.download({
            url: "/api/dataExport/exportData",
            method: "post",
            name: vname,
            data: {
                isShowBillStatus,
                defaultQueryValue,
                isExportId: Number(type !== "data"),
                name: vname,
                pageNum: 1,
                pageSize: 20000,
                viewId: vid,
                funcId: fid,
                conditions,
                conditionsMapNotEq,
                conditionsMapIsNull,
                billIds: _.uniqWith(ridList, (a, b) => Number(a) === Number(b)),
                rootBillIds: _.uniqWith(
                    uidList,
                    (a, b) => Number(a) === Number(b)
                ),
            },
            mime: "application/json;charset=utf-8",
        });
    }
}
