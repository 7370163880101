/**
 * 系统相关
 * @see 获取系统相关接口
 * @see 配置表
 */

import Login from "./Login";
import Tools from "kooci-lib/src/PubComponents/Utils/tools";
export default class System extends Tools.mixin(Login) {
    /**
     * 获取参数
     * @desc 区别于getConfigList的系统参数表,可以理解为redis存储方式(当前为永久存储)
     * @param {object} param0 入参对象
     * @param {string} param0.key 键
     * @returns {Promise<string>} 成功返回值字符串,失败抛错
     */
    async getItem({ key, schoolId }) {
        if (Tools.isEmptyMaybe(key, schoolId)) {
            throw new kq.Err({
                message: "设置参数键(key)与学校编码(schoolId)不能少",
                current: arguments[0],
                from: "kq.Request.System.getConfig",
            });
        }
        return this.request(
            {
                url: "/appstore/getAppVersion",
                params: { version: key, schoolCode: schoolId },
            },
            arguments[0]
        );
    }
    /**
     * 设置系统参数
     * @desc 通用临时参数,可以理解为redis机制(当前为永久存储)
     * @desc 比如:可以用于打包与客户端进行沟通(打包自动更新版本)
     * @param {object} param0 入参对象
     * @param {string} param0.key 键
     * @param {string} param0.value 值
     * @returns {Promise<void>} 失败抛错
     */
    async setItem({ key, value }) {
        console.warn("设置系统参数暂不可用(因为设置参数是直接使用云端host的)~");
        return;
    }
    /**
     * 查询学校编号
     * @returns {Promise<string>} 学校编号
     * @example 1001
     */
    getSchoolId() {
        return this.request({ url: "/api/common/dict/sysCode" }, arguments[0]);
    }
    /**
     * 获取服务器时间
     * @returns {Promise<string>}
     * @example "2021-11-11 12:00:00"
     */
    getTime() {
        return this.request("/api/common/dict/getServerTime", arguments[0]);
    }
    /**
     * 获取所有打印表信息
     * @see http://47.105.197.143:3000/organization/repository/editor?id=16&mod=61&itf=213
     * @param {object} param
     * @param {number} [param.vid] 视图号(传视图号会根据视图号进行查询)
     * @returns {Promise<[object]>}
     */
    getPrintList({ vid }) {
        if (vid) {
            return this.request(
                {
                    url: "/api/common/dict/printWordByViewId",
                    params: { viewId: vid },
                },
                arguments[0]
            );
        }
        return this.request("/api/common/dict/printWord", arguments[0]);
    }
    /**
     * 获取配置参数表
     * @returns {[object]}
     * @example [{id<number>: 配置id, key<string>: 参数key, value<string>: 参数value}]
     */
    getConfigList() {
        return this.request(
            {
                url: "/baseConfig/selectConfig",
                convert: {
                    compares: {
                        id: "nodeId",
                        key: "label",
                        value: "strValue",
                    },
                },
                timeout: 1000,
                timeoutConfig: {
                    reRequest: {
                        enabled: true,
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取配置参数值
     * @param {object} param0 参数对象
     * @param {string} param0.key 参数键
     * @returns {object} {id<number>: 配置id, key<string>: 参数key, value<string>: 参数value}
     */
    async getConfigValue({ key, id }) {
        const configList = await this.getConfigList(arguments[0]).catch(
            (err) => {
                throw err;
            }
        );
        const result = configList.find(
            (config) => config.key === key || kq.Tools.isEqual(id, config.id)
        );
        if (result) {
            return result.value;
        }
    }
    /**
     * 发送消息
     * @param {object} param 消息参数表
     * @param {string} param.content 发送内容
     * @param {[number]} param.uidList 人员号列表
     * @param {number} fid 功能点号
     * @returns {Promise<void>} 失败抛错
     */
    async sendMsg({ content, uidList, fid }) {
        let message;
        if (!content) {
            message = "发送消息内容不能为空";
        }
        if (_.isEmpty(uidList)) {
            message = "发送消息必须要传入人员号";
        }
        if (!fid) {
            message = "发送消息功能点号不能为空";
        }
        if (message) {
            throw new kq.Err({
                message,
                from: "kq.Request.System.sendMsg",
                current: arguments[0],
            });
        }
        return this.request(
            {
                url: "/api/sendMessage/sendMsg",
                method: "post",
                data: {
                    message: content,
                    funcId: fid,
                    userIds: _.uniqWith(
                        uidList,
                        (a, b) => Number(a) === Number(b)
                    ),
                },
            },
            arguments[0]
        );
    }
    /**
     * 发送错误日志
     * @param {object} param 错误对象
     * @returns {Promise<void>} 失败抛错
     */
    async sendErr({
        schoolId = 0,
        uid = 0,
        fid = 0,
        type,
        url,
        isOk,
        title,
        info,
        wid,
    }) {
        if (!info) {
            throw new Error("错误信息(info)不能为空");
        }
        switch (type) {
        case "API":
            type = 1;
            break;
        case "PARAM":
        case "VUE":
        case "INHERIT":
        case "DEV":
        case "AUTH":
        case "CONFIG":
            type = 2;
            break;
        default:
            type = 3;
            break;
        }
        if (!url) {
            if (process.env.NODE_ENV === "development") {
                url = `${process.env.VUE_APP_BASE_API}${location.pathname}${location.hash}`;
            } else {
                url = location.href;
            }
        }
        return this.request(
            {
                url: "http://weblog.kooci.net/weblog/create",
                method: "post",
                data: {
                    school_code: schoolId,
                    uid,
                    fid,
                    error_id: `1s${schoolId}f${fid}u${uid}w${wid}r${kq.Tools.uuid().slice(
                        -5
                    )}`,
                    web_url: url,
                    error_type: type,
                    error_status: isOk ? 2 : 1,
                    error_info: title,
                    error_remark: info,
                },
            },
            arguments[0]
        );
    }
}
