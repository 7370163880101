/**
 * @description 字符串工具判断类型方法集合
 * @author tonny
 * @date 2021-12-19
 */

import moment from "moment";

/**
 * 是否为日期类型字符串
 * @returns {boolean} true:是
 */
export function isDate() {
    if (!Number.isNaN(Number(this))) {
        return false;
    }
    return moment(this)._isValid;
}

/**
 * 是否为链接
 * @returns {boolean} true:是
 */
export function isUrl() {
    return /(https?|ftp|file|data):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/.test(
        this
    );
}
/**
 * 是否为数字
 * @returns {boolean} true:是
 */
export function isNumber() {
    return this !== "" && !Number.isNaN(Number(this));
}

/**
 * 是否为图片
 * @returns {boolean} true:是
 */
export function isImage() {
    const suffix = this.match(/\w+$/);
    if (suffix && suffix[0]) {
        return ["jpg", "jpeg", "png", "webp", "gif", "bmp", "svg"].includes(
            suffix[0].toLowerCase()
        );
    }
    return /^data:image/.test(this);
}

/**
 * 是否为微软文件
 * @returns {boolean} true:是
 */
export function isMicrosoftFile() {
    const suffix = this.match(/\w+$/);
    if (suffix && suffix[0]) {
        return ["docx", "doc", "xls", "xlsx", "ppt", "pptx"].includes(
            suffix[0].toLowerCase()
        );
    }
    return false;
}
