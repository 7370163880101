/**
 * 样张相关
 * @see 样张的增删改查
 */

import View from "./View";

export default class Template extends View {
    /**
     * 获取样张号
     * @param {object} params
     * @param {string|number} [params.fid] 功能点号
     * @param {string|number} [params.pid] 流程号
     * @returns {Promise<number>} 成功返回样张号,失败抛错
     */
    async getTid(params) {
        const data = await this.getTidInfo(params).catch((err) => {
            throw err;
        });
        return data.tid;
    }
    /**
     * 获取样张号信息对象
     * @param {object} params
     * @param {string|number} [params.fid] 功能点号
     * @param {string|number} [params.pid] 流程号
     * @returns {Promise<object>} 成功返回样张对象,失败抛错
     */
    getTidInfo({ fid, pid, vid }) {
        if (!fid && !pid && !vid) {
            throw new kq.Err({
                title: "参数错误",
                message: "功能点号(fid)与流程号(pid)与视图号(vid)至少传1个值",
                from: "Request.getTid",
                console: false,
                current: arguments[0],
            });
        }
        let url;
        let params = {};
        if (pid) {
            url = "/api/process/queryDetail";
            params = {
                gntype: 381,
                parvalue: `${pid};1000`,
            };
        } else if (fid) {
            url = "/api/common/dict/queryViewsByFuncId";
            params = {
                funcId: fid,
            };
        } else {
            return this.getViewInfo({ vid }, arguments[0]);
        }
        return this.request(
            {
                url,
                params,
                convert: url,
                timeout: 5000,
                timeoutConfig: {
                    reRequest: {
                        enabled: true,
                        maxCount: 3,
                    },
                },
            },
            arguments[0]
        );
    }
}
