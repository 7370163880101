import { FtypeCompares } from "../global.js";

export default {
    /** (普通)本人维护 */
    [FtypeCompares.N_MAINTAIN]: { detail: "n-maintain-detail" },
    /** (普通)人员列表 */
    [FtypeCompares.N_PERSONS]: {
        list: "n-persons-list",
        detail: "n-persons-detail/:uid",
    },
    /** (行集)申请-原:行集-个人查看 */
    [FtypeCompares.R_APPLY]: {
        list: "r-apply-list",
        detail: "r-apply-detail/:rid/:uid",
    },
    /** (行集)经历审核 */
    [FtypeCompares.R_REVIEW]: {
        list: "r-review-list",
        detail: "r-review-detail/:rid/:uid",
    },
    [FtypeCompares.R_APPROVE]: {
        list: "r-approve-list",
        detail: "r-approve-detail/:rid/:uid",
    },
    /** (行集)经历汇总:所有的审核记录 */
    [FtypeCompares.R_SUMMARY]: {
        list: "r-summary-list",
        detail: "r-summary-detail/:rid/:uid",
    },
    /** (行集)管理员申请 */
    [FtypeCompares.R_ADMIN_APPLY]: {
        list: "r-adminapply-list",
        detail: "r-adminapply-detail/:rid/:uid",
    },
};
