<script>
/**
 * 列表页公共混入
 * @desc 此混入仅提供给新系统列表页使用
 * @desc 提供公共请求列表数据逻辑
 * @desc 该列表页自动混入了kq.DataMixin与kq.TurnPageMixin混入
 * @see https://www.tapd.cn/62073498/documents/show/1162073498001004803
 * @author tonny
 * @date 2022-08-31
 */
</script>

<script>
import DataMixin from "../Data/Data.vue";
import PublicMixin from "./DataMixin.vue";
import TurnPageMixin from "../Data/TurnPage.vue";
const SUPER = "KQ_LISTDATA_MIXIN";

export default {
    SUPER,
    mixins: [DataMixin, PublicMixin, TurnPageMixin],
    data() {
        return {
            /** 列表页请求对象 */
            listRequest: kq.Request.mixin({ vm: this, modules: ["field"] }),
            /** 筛选列表 */
            filterList: [],
            /** 排序列表 */
            sortList: [],
            /** 模糊查询关键字 */
            fuzzyQuery: "",
            /** 表头列表 */
            headerList: [],
            /** 内容列表 */
            dataList: [],
            /** 加载状态 */
            loading_: false,
        };
    },
    computed: {
        /**
         * 空数据提示
         * @see this.formatDataList
         * @see this.loading_
         * @return {object}
         */
        emptyInfo() {
            return {
                value: !this.loading_ && this.isEmpty__(this.formatDataList),
                label: this.isEmpty__(this.fuzzyQuery || this.filterList)
                    ? "当前还没有内容"
                    : "当前查询内容为空",
            };
        },
        /**
         * 格式化数据内容
         * @see this.dataList
         * @returns {[object]}
         */
        formatDataList() {
            const resultList = (this.dataList || [])
                .filter(this._formatDataFilter)
                .map((data, index) => this._formatDataMap(data, index, data));
            return resultList;
        },
        /**
         * 格式化的表头对象
         * @desc 将表头进行对象话,方便快速查询
         * @see this.headerList
         * @returns {object}
         */
        formatHeaders() {
            const headers = {};
            (this.headerList || []).forEach((header, index) => {
                headers[header.zid] = this._formatHeaderMap(
                    header,
                    index,
                    header
                );
                headers[header.zlabel || header.label] = headers[header.zid];
            });
            return headers;
        },
        /**
         * 格式化的表头列表
         * @desc 表头进行过滤与转换
         * @see this.headerList
         * @returns {[object]}
         */
        formatHeaderList() {
            const resultList = (this.headerList || [])
                .filter(this._formatHeaderFilter)
                .map((data, index) => this._formatHeaderMap(data, index, data));
            return resultList;
        },
    },
    watch: {
        /**
         * @desc 兼容性赋值(去除对于dataList_使用,但由于pc端过度)
         */
        dataList_(dataList) {
            this.dataList = dataList;
        },
    },
    methods: {
        /**
         * 初始化数据(重写)
         * @desc 加载表头与表内容信息
         * @returns {Promise<void>}
         */
        async init() {
            await this.$super(SUPER)
                .init()
                .catch((err) => {
                    this.errHand__(err);
                    throw err;
                });
            await Promise.all([
                this.refreshPage(),
                this.loadHeaderList(),
            ]).catch((err) => {
                this.errHand__(err);
                throw err;
            });
        },
        /**
         * 加载表头数据
         * @desc 设置this.headerList
         * @returns {Promise<void>} 失败抛错
         */
        async loadHeaderList() {
            this.headerList = await this.listRequest
                .getFieldList({
                    fid: this.fid,
                    vid: this.vid,
                    isPerson: true,
                })
                .catch((err) => {
                    this.errHand__(err);
                    throw err;
                });
        },
        /**
         * 加载表头数据
         * @desc 设置this.headerList
         * @returns {Promise<void>} 失败抛错
         */
        async loadHeaderList() {
            this.headerList = await this.listRequest
                .getFieldList({
                    fid: this.fid,
                    vid: this.vid,
                    isPerson: true,
                })
                .catch((err) => {
                    this.errHand__(err);
                    throw err;
                });
        },
        /**
         * 加载列表页数据(重写)
         * @desc 加入列表页请求逻辑
         * @param {object} param 参数对象
         * @param {number} param.currentPage 当前页吗
         * @param {number} param.onePageSize 一页个数
         * @return {Promise<[object]>} 数据列表
         */
        async loadPage() {
            const res = await this.listRequest
                .getList(this._getLoadParams())
                .catch((err) => {
                    this.errHand__(err);
                    throw err;
                });
            return res;
        },
        /**
         * 当个表头格式化
         * @param {object} header 表头对象
         * @param {number} index 表头索引
         * @param {object} from 表头数据源
         * @return {object} 格式化后的表头对象
         */
        _formatHeaderMap(header, index, from) {
            return {
                ...this._mergeBaseMap(header, SUPER, "_formatHeaderMap", [
                    header,
                    index,
                    from,
                ]),
                index: index,
                prev: this.headerList[index - 1],
                next: this.headerList[index + 1],
            };
        },
        /**
         * 单个表头过滤
         * @desc 判断是否表头
         * @param {object} header 表头对象
         * @param {number} index 表头索引
         * @return {boolean} true:通过
         */
        _formatHeaderFilter(header, index) {
            return this._mergeBaseFilter(
                header.isTitle,
                SUPER,
                "_formatHeaderFilter",
                [header, index]
            );
        },
        /**
         * 获取加载页面参数
         * @virtucal
         * @returns {object} 参数表
         */
        _getLoadParams() {
            return {
                fid: this.fid,
                vid: this.vid,
                currentPage: this.currentPage,
                pageSize: this.onePageSize_,
                filterList: this.filterList,
                sortList: this.sortList,
                fuzzyQuery: this.fuzzyQuery,
            };
        },
    },
};
</script>
