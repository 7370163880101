/**
 * @description 颜色自定义指令
 * @see 通过该指定可以给元素指定特定预设号的颜色与皮肤
 * @see https://www.tapd.cn/62073498/documents/show/1162073498001004756
 * @author tonny
 * @date 2021-08-13
 */
import _ from "lodash";
import $ from "jquery";

/**
 * 获取组合颜色类名
 * @param {HTMLElement} el 被绑定颜色的dom元素
 * @param {Object} modifiers 静态参数对象
 * @param {String} arg 动态参数
 * @returns {String} 组合后的颜色类名
 */
function getBindColor(el, { modifiers, arg }) {
    if (arg && !_.isString(arg)) {
        console.warn(`[DirectiveBind-color]arg参数必须为字符串!`);
        return;
    }
    let theme = arg || "", // 主题
        func, // 功能(border)
        number, // 数值(比如border的宽度)
        shade, // 明暗
        interactive, // 交互
        important, // 强调(同css的!important)
        module = "color"; // 模块,
    _.forEach(modifiers, (on, name) => {
        if (!on) {
            return;
        }
        if (
            [
                "primary",
                "warn",
                "warning",
                "error",
                "err",
                "info",
                "success",
                "green",
                "purple",
                "red",
                "qing",
                "gray",
                "wbg",
                "yellow",
                "orange",
                "purple",
                "label",
                "title",
                "black",
                "white",
            ].includes(name)
        ) {
            theme = name;
        } else if (["border"].includes(name)) {
            func = name;
        } else if (["bg", "color"].includes(name)) {
            module = name;
        } else if (["dark", "light"].includes(name)) {
            shade = name;
        } else if (["focus", "hover", "active"].includes(name)) {
            interactive = name;
        } else if (["important"].includes(name)) {
            important = name;
        } else if (!_.isNaN(Number(name)) && modifiers.border) {
            number = name;
        }
    });
    const className = [
        "v",
        theme,
        func,
        number,
        shade,
        module,
        interactive,
        important,
    ]
        .filter((s) => s)
        .join("-");
    return className;
}

export default {
    install(Vue) {
        Vue.directive("kq-color", {
            bind(el, params) {
                if (!el.kqColor) {
                    el.kqColor = {
                        colors: [],
                        tempColors: [],
                    };
                }
                const className = getBindColor(el, params);
                el.kqColor.colors.push(className);
                let enabled = params.value;
                if (_.isFunction(params.value)) {
                    enabled = params.value();
                }
                if (enabled !== false) {
                    $(el).addClass(className);
                }
            },
            update(el, params) {
                const className = getBindColor(el, params);
                const tempColors = el.kqColor.tempColors;
                tempColors.push(className);
                // 判断:零时颜色组合和原颜色组相同->替换全部颜色
                if (tempColors.length === el.kqColor.colors.length) {
                    let enabled = params.value;
                    if (_.isFunction(params.value)) {
                        enabled = params.value();
                    }
                    if (enabled !== false) {
                        $(el).removeClass(el.kqColor.colors.join(" "));
                        $(el).addClass(tempColors.join(" "));
                    } else {
                        $(el).removeClass(el.kqColor.colors.join(" "));
                    }
                    el.kqColor.colors = tempColors;
                    // 清空以便下一次组合更新
                    el.kqColor.tempColors = [];
                }
            },
        });
    },
};
