/**
 * @desc 参数表仓库模块
 * @desc 仅适用于酷奇v10系统后台机制,其它后端机制不适用
 * @author tonny
 * @date 2022-08-13
 */

import Tools from "../../Utils/tools";
import Request from "../../Request";
import Module from "../Module";

export default class Config extends Module {
    constructor(...params) {
        super(...params);
        /** 请求对象 */
        this._request = new Request();
    }
    /**
     * vuex仓库存储对象(重写)
     * @returns {object}
     */
    mapState() {
        return {
            /** 系统配置对象 */
            system: {},
            /** 应用配置对象 */
            app: {},
        };
    }
    /**
     * 设置系统配置
     * @param {object} state 仓库对象
     * @param {[object]} configList 配置对象
     * @returns void
     */
    mutationSetSystem(state, configList) {
        (configList || []).forEach(({ key, config }) => {
            state.system[key] = this._parseConfig(config);
        });
    }
    /**
     * 设置app配置表
     * @param {object} state
     * @param {object} payload 入参
     * @param {object} payload.configStr 对应的app配置参数
     * @param {number} payload.appid 应用号
     * @returns void
     */
    mutationSetApp(state, { config, appid }) {
        // 两个参数都不为空
        if (!Tools.isEmptyMaybe(config, appid)) {
            if (Tools.isEmpty(state.app)) {
                state.app = {};
            }
            state.app[appid] = this._parseConfig(config);
            if (!Tools.isEmpty(fid)) {
                state.app[fid] = state.app[appid];
            }
        }
    }
    /**
     * 清空所有值
     * @desc 注销账号时被调用
     * @param {object} state 仓库存储对象
     * @returns void
     */
    mutationClear(state) {
        state.system = {};
        state.app = {};
    }
    /**
     * 获取系统参数表
     * @param {object} state 仓库存储对象
     * @returns {object} 注意:对返回的参数表直接修改值将不会影响到参数存储
     */
    getterSystem(state) {
        return _.cloneDeep(state.system);
    }
    /**
     * 获取应用参数表
     * @param {object} state 仓库存储对象
     * @returns {object} 注意:对返回的参数表直接修改值将不会影响到参数存储
     */
    getterApp(state) {
        return _.cloneDeep(state.app);
    }
    /**
     * 获取系统参数表
     * @param {object} param vuex.actions参数对象
     * @param {object} param.getters 计算属性
     * @param {function} param.commit 仓库提交函数
     * @param {string} [key] 配置表具体参数(key不传则返回整个配置对象)
     * @returns {any}
     */
    actionGetSystem({ commit, state }, key) {
        return Tools.promise(async () => {
            let configInfo = state.system;
            if (Tools.isEmpty(configInfo)) {
                configInfo = await this._request
                    .request({
                        url: "/baseConfig/selectConfig",
                        convert: {
                            compares: {
                                key: "label",
                                config: "strValue",
                            },
                        },
                    })
                    .catch((err) => {
                        throw err;
                    });
                commit("setSystem", configInfo);
            }
            return this._getValueByKey(key, state.system);
        });
    }
    /**
     * 获取应用参数表
     * @param {object} param vuex.actions首页参数对象
     * @param {object} param.getters 仓库计算属性
     * @param {function} param.commit 仓库提交函数
     * @param {function} param.rootDispatch 仓库全局请求函数
     * @param {object} payload 入参
     * @param {number} [payload.appid] 应用号
     * @param {number} [payload.fid] 功能点号
     * @param {string} [payload.key] 配置关键字
     * @returns {any}
     */
    actionGetApp({ commit, dispatch, state }, { appid, fid, key }) {
        return Tools.promise(async () => {
            let configInfo = state.app[appid || fid];
            if (Tools.isEmpty(configInfo)) {
                const appInfo = await dispatch(
                    "kqApp/getBaseInfo",
                    {
                        appid,
                        fid,
                    },
                    { root: true }
                ).catch((err) => {
                    throw err;
                });
                configInfo = appInfo.config;
                commit("setApp", { fid, appid, configInfo });
            }
            return this._getValueByKey(key, configInfo);
        });
    }
    /**
     * 配置转换
     * @param {string} configStr 配置字符串
     * @returns {object}
     */
    _parseConfig(configStr) {
        let value = configStr;
        if (_.isString(configStr)) {
            try {
                value = JSON.parse(
                    configStr.replace(/\\\\/g, "").kqClearHtml()
                );
            } catch (err) {
                if (process.env.NODE_ENV === "development") {
                    console.warn(configStr);
                }
                if (/^\{.+\}$/.test(configStr)) {
                    new kq.Err({
                        title: "参数表错误",
                        message: `当前可能配置了不正确的参数表,请复制信息后联系管理员`,
                        type: "CONFIG",
                        from: "vuex.modules.config",
                        current: configStr,
                    });
                    value = null;
                }
            }
        }
        return value;
    }
    /**
     * 根据取值关键词获取值
     * @param {string} key 取值关键字
     * @param {object} config 配置对象
     * @returns {object|void} 有值则返回值,没有则返回undefined
     */
    _getValueByKey(key, config) {
        if (Tools.isEmpty(config)) {
            return null;
        }
        if (Tools.isEmpty(key)) {
            return config;
        }
        const keyList = key.split(".");
        if (keyList.length === 1) {
            return config[key];
        }
        let result = config;
        keyList.some((k) => {
            result = result[k];
            return !result;
        });
        return result;
    }
}
