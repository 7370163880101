/**
 * 增删改查相关工具
 * @author tonny
 * @date 2021-11-15
 */

import _ from "lodash";

function callbackConvert(callback) {
    if (!_.isFunction(callback)) {
        let value = callback;
        /** 对象:判断每个成员 */
        if (_.isPlainObject(value)) {
            callback = function(item) {
                return _.every(
                    value,
                    (val, key) => item[key] && item[key] === val
                );
            };
        }
        // 全等判断
        else {
            callback = function(item) {
                return item === value;
            };
        }
    }
    return callback;
}

/**
 * 删除指定值
 * @see 符合vue动态监听标准的删除与插入值
 * @param {function} callback 回调函数
 * @param {array|object|number|boolean|string} callback 全等对比
 * @param {*} insertItem 删除指定值后插入的额外值
 * @returns {*} 被删除的元素(没有则返回undefined)
 */
export function splice(callback, insertItem) {
    callback = callbackConvert(callback);
    const index = this.findIndex(callback);
    if (index >= 0) {
        const temp = this[index];
        if (insertItem !== undefined) {
            this.splice(index, 1, insertItem);
        } else {
            this.splice(index, 1);
        }
        return temp;
    }
}
