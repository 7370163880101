import { render, staticRenderFns } from "./ScrollNav.vue?vue&type=template&id=16395a48&"
import script from "./ScrollNav.vue?vue&type=script&lang=js&"
export * from "./ScrollNav.vue?vue&type=script&lang=js&"
import style0 from "./ScrollNav.vue?vue&type=style&index=0&id=16395a48&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../kooci-saas-recruit/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports