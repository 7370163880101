/**
 * @description 用户信息管理仓库
 * @author tonny
 * @date 2022-08-11
 */

import Module from "../Module";
import Tools from "../../Utils/tools";

export default class User extends Module {
    constructor(...params) {
        super(...params);
        /** 请求实例 */
        this._request = kq.Request.mixin({ modules: ["login"] });
    }
    mapState() {
        /** 缓存key */
        this._cacheKey = "KQLIB_STORE_TOKEN";
        return {
            /** 登录秘钥 */
            token: localStorage.getItem(this._cacheKey),
            /** 用户号 */
            uid: null,
            /** 用户姓名 */
            uname: null,
            /** 工号 */
            wid: null,
            /** 部门 */
            dname: null,
            /** 部门号 */
            did: null,
            /** 头像 */
            himg: null,
            /** 总权限表 */
            roleList: [],
            /** 系统编号 */
            syscode: null,
        };
    }
    /**
     * 提交设置token
     * @param {object} state 仓库存储对象
     * @param {string} token token值
     */
    mutationSetToken(state, token) {
        state.token = token;
        if (Tools.isEmpty(token)) {
            localStorage.removeItem(this._cacheKey);
        } else {
            localStorage.setItem(this._cacheKey, token);
        }
    }
    /**
     * 登录信息写入
     * @param {object} state 仓库对象
     * @param {object} payload 参数对象
     * @param {string} token 登录秘钥
     * @param {string} uname 姓名
     * @param {number} uid 用户号
     * @param {number} wid 工号
     * @param {string} [department] 部门
     * @param {number} [did] 部门号
     * @param {string} [himg] 头像
     * @returns void
     */
    mutationLogin(
        state,
        { token, uname, uid, wid, dname, did, himg, roleList, syscode }
    ) {
        if (!syscode) {
            console.warn(`vuex.user.commit:登录信息缺少系统编号(syscode)信息~`);
        }
        if (!roleList) {
            console.warn(`vuex.user.commit:登录信息缺少总权限表(roles)信息~`);
        }
        if (!token) {
            console.warn(`vuex.user.commit:登录信息缺少登录验证(token)信息~`);
        }
        if (!uname) {
            console.warn(`vuex.user.commit:登录信息缺少姓名(uname)信息~`);
        }
        if (!uid) {
            console.warn(`vuex.user.commit:登录信息缺少用户号(uid)信息~`);
        }
        if (!wid) {
            console.warn(`vuex.user.commit:登录信息缺少工号(wid)信息~`);
        }
        state.token = token;
        state.uname = uname;
        state.uid = uid;
        if (!Tools.isEmptyMaybe(token, uid)) {
            localStorage.setItem(this._cacheKey, token);
        }
        state.wid = wid;
        state.dname = dname;
        state.did = did;
        state.himg = himg;
        state.roleList = roleList;
        state.syscode = syscode;
    }
    /**
     * 登出
     * @desc 清空用户信息
     * @desc 逐个调用所有子模块清空函数
     * @param {object} state 仓库对象
     * @returns void
     */
    mutationLogout(state) {
        state.token = null;
        localStorage.removeItem(this._cacheKey);
        state.uid = null;
        state.wid = null;
        state.dname = null;
        state.did = null;
        state.himg = null;
        state.roleList = [];
        state.syscode = null;
        _.forEach(this.modules, (module, name) => {
            if (_.isFunction(module.mClear || module.mutationClear)) {
                this.store.commit(`${name}/clear`);
            }
        });
    }
    /**
     * 获取登录秘钥
     * @param {object} state 仓库对象
     * @returns {string}
     */
    getterToken() {
        return this.state.token || localStorage.getItem(this._cacheKey) || null;
    }
    /**
     * 获取用户号
     * @param {object} state 仓库对象
     * @returns {number} 用户id
     */
    getterUid() {
        return this.state.uid;
    }
    /**
     * 获取姓名
     * @param {object} state 仓库对象
     * @returns {string}
     */
    getterUname() {
        return this.state.uname;
    }
    /**
     * 获取部门号
     * @param {object} state 仓库对象
     * @returns {number}
     */
    getterDid() {
        return this.state.did;
    }
    /**
     * 获取总权限表
     * @param {object} state 仓库对象
     * @returns {[string]}
     */
    getterRoleList() {
        return this.state.roleList;
    }
    /**
     * 获取是否登录
     * @returns {boolean} true:登录了
     */
    getterIsLogin() {
        return !Tools.isEmpty(this.state.uid);
    }
    /**
     * 获取用户信息
     * @param {object} state 仓库对象
     * @returns {object}
     * @example {uname<string>:用户姓名, token<string>:登录身份, uid<number>:用户号, wid<number>:工号, department<string>:部门, did<number>:部门号}
     */
    getterUinfo(state) {
        return state;
    }
    /**
     * 全局getters(重写)
     * @returns {object}
     */
    get globalGetters() {
        return {
            uid: () => this.getterUid(),
            token: () => this.getterToken(),
            isLogin: () => this.getterIsLogin(),
            uname: () => this.getterUname(),
        };
    }
    /**
     * 登录
     * @param {object} param vuex.actions参数对象
     * @param {function} commit 提交函数
     * @param {object} state 仓库对象
     * @param {object} payload 入参
     * @returns {Promise<object>} 成功返回用户信息对象,失败抛错
     */
    actionLogin({ commit, state, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            if (Tools.isEmpty(state.token)) {
                this._request
                    .login({
                        ...payload,
                        convert: false,
                    })
                    .then((dataInfo) => {
                        const info = _.get(dataInfo, "user.user") || {};
                        const userInfo = {
                            token: dataInfo.token,
                            roleList: _.get(dataInfo, "user.roles"),
                            uid: info.id,
                            did: _.get(info, "dept.id"),
                            dname: _.get(info, "dept.name"),
                            uname: info.nickName,
                            wid: info.username,
                            himg: info.avatarPath,
                            syscode: info.sysCode,
                        };
                        commit("login", userInfo);
                        resolve(state);
                    })
                    .catch(reject);
            } else if (Tools.isEmpty(state.uid)) {
                dispatch("getUinfo")
                    .then(resolve)
                    .catch((err) => {
                        // token失效
                        if (Tools.isEqual(401, err.code)) {
                            // 删除失效的token
                            commit("setToken", null);
                            // 再次登录
                            dispatch("login", payload)
                                .then(resolve)
                                .catch(reject);
                        } else {
                            reject(err);
                        }
                    });
            } else {
                resolve(state);
            }
        });
    }
    /**
     * 注销登录
     * @desc 清空登录信息
     * @param {object} param vuex.actions参数对象
     * @param {function} commit 提交函数
     * @param {object} [payload] 注销参数
     * @param {boolean} [payload.isSchool] 是否是校端微信公众号注销(和payload.isWx同时传)
     * @param {boolean} [payload.isWx] 是否是微信端注销
     * @returns {Promise<void>} 失败抛错
     */
    actionLogout({ commit }, payload) {
        return Tools.promise(async () => {
            await this._request.logout(payload).catch((err) => {
                throw err;
            });
            commit("logout");
        });
    }
    actionGetUinfo({ commit, state }) {
        return Tools.promise(async () => {
            let userInfo = state;
            if (Tools.isEmpty(userInfo.uid)) {
                userInfo = await this._request
                    .getLoginInfo({
                        convert: false,
                    })
                    .catch((err) => {
                        throw err;
                    });
                const user = userInfo.user || {};
                userInfo = {
                    token: state.token,
                    roleList: userInfo.roles,
                    himg: user.avatarPath,
                    did: _.get(user, "dept.id"),
                    dname: _.get(user, "dept.name"),
                    uname: user.nickName,
                    uid: user.id,
                    syscode: user.sysCode,
                    wid: user.username,
                };
                commit("login", userInfo);
            }
            return userInfo;
        });
    }
}
