/* eslint-disabled */

import { baseInfo } from "../CardChart/config.js";
import { getValue } from "../Tools/index.js";

export default {
    name: "进度环形图组件",
    component: "kq-lowcode-component-card-circle-chart",
    type: "统计",
    demo: {
        name: "统计项名称",
        subname: "统计项描述",
        icon: "icon-progress-full",
        /** 总数名称 */
        sumLabel: "总值名称",
        /** 总共值 */
        sumValue: 100,
        /** 已完成名称 */
        completeLabel: "已完成",
        /** 已完成值 */
        completeValue: 70,
        /** 未完成名称 */
        uncompleteLabel: "未完成",
    },
    propsFormat(fromDataList) {
        let {
            baseinfo,
            async: {
                type,
                dataName,
                totalName: sumLabel,
                unName: uncompleteLabel,
                edName: completeLabel,
            },
            async_2: { type: type2, dataName: name2 } = {},
            async_3: { type: type3, dataName: name3 } = {},
        } = getValue(null, fromDataList);
        sumLabel =
            type === "total"
                ? dataName
                : type2 === "total"
                ? name2
                : type3 === "total"
                ? name3
                : sumLabel;
        uncompleteLabel =
            type === "un"
                ? dataName
                : type2 === "un"
                ? name2
                : type3 === "un"
                ? name3
                : uncompleteLabel;
        completeLabel =
            type === "ed"
                ? dataName
                : type2 === "ed"
                ? name2
                : type3 === "ed"
                ? name3
                : completeLabel;
        return {
            ...baseinfo,
            sumLabel,
            uncompleteLabel,
            completeLabel,
        };
    },
    configList: [
        {
            ...baseInfo,
            formList: [
                ...baseInfo.formList,
                {
                    zid: "chartLightColor",
                    zname: "图标已完成颜色",
                    type: "color",
                    value: "#BDE2FE",
                },
                {
                    zid: "suffix",
                    zname: "数值后缀",
                    type: "text-text",
                    required: true,
                    value: "人",
                },
            ],
        },
        {
            zid: "async",
            zname: "数据源",
            type: "multi",
            znameField: { zid: "dataName" },
            formList: [
                {
                    zid: "type",
                    zname: "数据类型",
                    type: "select-single-1",
                    value: "",
                    svalue: "",
                    required: true,
                    selectList: [
                        {
                            label: "全部数据",
                            value: "all",
                        },
                        {
                            label: "单项数据",
                            children: [
                                {
                                    label: "总计",
                                    value: "total",
                                },
                                {
                                    label: "已完成",
                                    value: "ed",
                                },
                                {
                                    label: "未完成",
                                    value: "un",
                                },
                            ],
                        },
                    ],
                },
                {
                    zid: "dataName",
                    zname: "数据名称",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "from",
                    zname: "数据来源",
                    type: "select-single-1",
                    required: true,
                    value: "",
                    svalue: "",
                    selectList: [
                        { label: "接口", value: "api" },
                        { label: "功能点", value: "function" },
                    ],
                },
                {
                    zid: "apiRoute",
                    zname: "接口地址",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "apiConvert",
                    zname: "接口转换",
                    type: "code",
                    required: true,
                    value: "",
                    codeFormat: "javascript",
                },
                {
                    zid: "fid",
                    zname: "功能点",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "valueType",
                    zname: "值类型",
                    type: "select-single-1",
                    required: true,
                    svalue: "",
                    value: "",
                    selectList: [{ label: "列表页总数", value: "list-total" }],
                },
                {
                    zid: "totalName",
                    zname: "总计数据名称",
                    help: "组件中总数据的名字,比如:全校总人数",
                    type: "text-text",
                    required: true,
                    value: "总计",
                },
                {
                    zid: "edName",
                    zname: "未完成数据名称",
                    help: "组件中未完成数据的名字,比如:已完成人数",
                    type: "text-text",
                    required: true,
                    value: "已完成",
                },
                {
                    zid: "unName",
                    zname: "已完成数据名称",
                    help: "组件中未完成数据的名字,比如:未完成人数",
                    type: "text-text",
                    required: true,
                    value: "未完成",
                },
            ],
            linkage: [
                {
                    name: "数据类型-数据源联动",
                    subname: "选择数据类型后,联动数据源字段显示",
                    from: [
                        {
                            zid: "type",
                            value: [true],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
                {
                    name: "数据类型-数据名称联动",
                    subname: "数据类型:单项数据关联数据名称显示",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "totalName",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "unName",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "edName",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["function"],
                        },
                    ],
                },
                {
                    name: "数据来源联动",
                    subname: "数据来源-api显示对应api的相关",
                    from: [
                        {
                            zid: "from",
                            value: ["api"],
                        },
                    ],
                    to: [
                        {
                            zid: "apiRoute",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "apiConvert",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
                {
                    name: "数据来源-字段联动",
                    subname: "数据来源码值:功能点,关联显示功能点号与值类型",
                    type: "visible",
                    from: [
                        {
                            zid: "from",
                            value: ["function"],
                        },
                    ],
                    to: [
                        {
                            zid: "fid",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "valueType",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
                {
                    name: "总计数据类型联动",
                    subname: "数据类型:单项-总计|已完成|未完成->显示数据名称",
                    from: [
                        {
                            zid: "type",
                            value: ["un", "total", "ed"],
                        },
                    ],
                    to: [
                        {
                            zid: "dataName",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
            ],
        },
    ],
};
