/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "kooci-lib/src/PubComponents/index.js";
import "kooci-lib/src/PcComponents/index.js";
import "kooci-lib/src/styles/index.less";
// import "jzl-web-components/lib/jwcomps/jwcomps.css";
import JzlWebApi from "jzl-web-api";
import JzlWebComponents from "jzl-web-components";
import "./mock";
import VueSuper from "vue-supers";
import axios from "axios";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueTippy, { TippyComponent } from "vue-tippy";
import VueGridLayout from 'vue-grid-layout';
import VueResize from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import QrcodeVue from "qrcode.vue";
import ScrollSeamless from 'vue-seamless-scroll'
import VueAwesomeSwiper  from 'vue-awesome-swiper';
import Vant from "vant";
import "vant/lib/index.css";

Vue.config.devtools = true

Vue.component('GridLayout', VueGridLayout.GridLayout);
Vue.component('GridItem', VueGridLayout.GridItem);
Vue.component("tippy", TippyComponent);
Vue.component("PerfectScrollbar", VuePerfectScrollbar);
Vue.component("QrcodeVue", QrcodeVue);
Vue.component("ScrollSeamless", ScrollSeamless);
Vue.use(VueTippy)
Vue.use(VueResize);
// 超类继承
Vue.use(VueSuper);
Vue.use(Vant);

// 云库
Vue.use(kqPub);
Vue.use(kqPc);
Vue.use(VueAwesomeSwiper, /* { default global options } */)

Vue.prototype.$request = new kq.Request();

window.kqConfig = {
  getToken() {
    return "";
  },
  loginOut() {
    console.log("401了");
    Vue.prototype.$bus.$emit("loginout");
  },
  postsInit() {
    Vue.prototype.$bus.$emit("postsinit");
  },
  personalInit() {
    Vue.prototype.$bus.$emit("personalInit");
  },
  getBaseUrl() {
    return "http://127.0.0.1:8888/";
  },
  /** 统一跳转路由机制
   * @see 附加：将页面名称以及系统名称存放vuex中，方便在路由中使用
   * @returns <void>
   */
  openNav({ route, params, name, query }) {
    if (route) {
        if (params && query) {
            router.push({
                ...route,
                params: { ...params },
                query: {...query}
            });
        } else if (query) {
            router.push({
                ...route,
                query: {...query}
            });
        } else {
            router.push(route);
        }
    }
    /** 当前页面名称 */
    store.commit("system/set_current_page_name", name);
  },
};

// 广播
Vue.prototype.$EventBus = new Vue();
Vue.prototype.$bus = Vue.prototype.$EventBus;


// axios.interceptors.response.use(
//   (response) => {
//     return response
//   }, (error) => {
//     return Promise.reject(error)
//   }
// );


// 每次切换页面的时候，让页面滚动到最顶部
router.afterEach((to, from, next) => { window.scrollTo(0, 0)});

const $axiosNoCache = axios.create()

JzlWebComponents.AxiosInterceptor(
  [$axiosNoCache],
  store,
  function (pid) {
    if (!pid) {
      if (Vue.prototype.$utils) {
        pid = Vue.prototype.$utils.getProjectId();
      }
    }
    Vue.prototype.$bus.$emit("loginout");
  },
  function (error, pid) {
    console.log(error, "error")
    let msg =
      _.get(error, "response.data") ||
      _.get(error, "message") ||
      "接口调用错误，请稍后再试！";
    Vue.prototype.$message({
      message: `${msg}`,
      type: 'warning',
      duration: 1500
    })
  }
);
JzlWebComponents.IconLib(store);

// 低码平台
Vue.use(JzlWebApi);
Vue.use(JzlWebComponents);
// 饿了么组件
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
