/**
 * @description 用户的相关信息
 * @date 2023-02-08
 * @author maxi
 */

import Vue from "vue";
import _ from "lodash"
import { getToken, setToken } from "kooci-saas-recruit/src/utils/cookie"

export default {
    namespaced: true,
    state: {
        /** 是否是登录状态 */
        isLogin: false,
        /** 用户id */
        uid: "",
        token: "",
        /** 用户名 */
        uname: "",
        menus: [],
        currentMenuType: "",
        currentMenu: {},
        loginUser: {},
        loginCust: {},
        project: null,
        dyncCompLoaded: {},
        processPageId: "",
    },
    mutations: {
        INIT_MENUS(state, menus) {
            state.menus = menus;
        },
        initIsLogin(state, isLogin) {
            state.isLogin = isLogin
        },
        INIT_LOGIN_USER(state, user) {
            state.loginUser = user;
            if (!state.project && user.project) {
                state.project = user.project;
            }
        },
        INIT_LOGIN_CUSTOMER(state, customer) {
            state.loginCust = customer;
        },
        INIT_LOGIN_TYPE(state, loginType) {
            state.loginType = loginType;
        },
        INIT_CURRENT_PROJECT(state, project) {
            state.project = project;
            const project_id = _.get(project, "project_id");
            if (localStorage && project_id) {
                localStorage.setItem("project_id", project_id);
            }
        },
        INIT_TOKEN(state, token) {
            state.token = token;
        },
        /** 流程id */
        set_process_pageId(state, pageId) {
            state.processPageId = pageId;
        },
    },
    actions: {
        async initLoginUser(store, loginInfo) {
            const commit = store.commit;
            let info = loginInfo;

            if (!info || !info.admin) {
                info = await jzlWebApi.admin.getLoginAdmin();
            }

            if (!info || !info.admin) return;

            const admin = _.get(info, "admin");
            const project = _.get(info, "project");
            const customer = _.get(info, "customer");
            const token = _.get(info, "token");
            const loginType = _.get(info, "loginType");
            commit("initIsLogin", true);
            if (admin) commit("INIT_LOGIN_USER", admin);
            if (project) commit("INIT_CURRENT_PROJECT", project);
            if (customer) commit("INIT_LOGIN_CUSTOMER", customer);
            if (loginType) commit("INIT_LOGIN_TYPE", customer);
            if (token) await store.dispatch("initToken", token);
        },
        initToken({ commit }, token) {
            if (!token) return;
            commit("INIT_TOKEN", token);
            !getToken() && setToken(token);
        },
    },
};
