/**
 * 消息请求类
 * @author tonny
 * @date 2022-09-07
 */
import Base from "../base";
import Tools from "../../Utils/tools";

export default class Message extends Base {
    /**
     * 获取发起事项列表
     * @param {object} param0
     * @param {number} [param0.systemSid] 子系统码值
     * @returns {Promise<[object]>}
     */
    async getApplyList({ systemSid }) {
        return this.request(
            {
                url: "/api/process/getMyList",
                params: {
                    appSystem: systemSid,
                },
                convert: {
                    compares: {
                        himg: "picture",
                        name: ["TemplateName"],
                        subname: ["NodeName", "title"],
                        uid: "RootBillID",
                        fid: ["FunctionID", "funcId"],
                        rid: "BillID",
                        date: ["EnabledDate", "createDate"],
                        vid: "ViewID",
                        uname: "userName",
                        path: "url",
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取审核事项列表
     * @param {object} param0
     * @param {number} [param0.systemSid] 子系统功码值
     * @param {number} [param0.stat="todo"] 办理状态(todo|0:待办,done|1:已办)
     * @returns {[object]}
     */
    async getReviewList({ systemSid, stat = "todo" }) {
        return this.request(
            {
                url: "/api/process/getList",
                params: {
                    appSystem: systemSid,
                    status: stat === "todo" || 0 ? undefined : 1,
                },
                convert: {
                    compares: {
                        himg: "picture",
                        name: ["TemplateName"],
                        subname: ["NodeName", "title"],
                        uid: "RootBillID",
                        fid: ["FunctionID", "funcId"],
                        rid: "BillID",
                        date: ["EnabledDate", "createDate"],
                        vid: "ViewID",
                        uname: "userName",
                        path: "url",
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取待办事项列表
     * @param {object} param0
     * @param {number} [param0.systemSid] 子系统码值
     * @returns {Promise<[object]>}
     */
    async getTodoList({ systemSid }) {
        return this.getReviewList({ ...arguments[0], systemSid, stat: "todo" });
    }
    /**
     * 获取已办事项列表
     * @param {object} param0
     * @param {number} [param0.systemSid] 子系统码值
     * @returns {Promise<[object]>}
     */
    async getDoneList({ systemSid }) {
        return this.getReviewList({ ...arguments[0], systemSid, stat: "done" });
    }
    /**
     * 消息已阅
     * @param {object} param0 参数对象
     * @param {number} param0.id 消息id
     * @param {number} [param0.systemSid] 当前对应子系统码值id
     * @returns {Promise<number>} 成功返回(数值:表示取消的条数),失败抛错
     */
    async readCommit({ id, systemSid }) {
        if (Tools.isEmpty(id)) {
            throw new kq.Err({
                message: "消息id(id)不能少",
                current: arguments[0],
                from: "kq.Request.Message",
            });
        }
        return this.request(
            {
                url: "/api/earlyWarn/status",
                method: "post",
                data: { id, appSystem: systemSid },
            },
            arguments[0]
        );
    }
    /**
     * 获取预警信息列表
     * @param {param} param0
     * @param {number} param.systemSid 子系统码值
     * @returns {Promise<[object]>} 成功返回对象数组,失败抛错
     * @example
     * const warnList = await new kq.Request.Message().getWarnList({systemSid: 1});
     * console.log(warnList);
     */
    async getWarnList({ systemSid }) {
        return this.request(
            {
                url: "/api/earlyWarn/all",
                params: { appSystem: systemSid },
                convert: {
                    compares: {
                        name: "title",
                        path: "url",
                        type: "type",
                        childType: "warnType",
                        params: "warnContent",
                        warnId: "id",
                        date: "createDate",
                    },
                    convertValueHook({ to, value }) {
                        if (to === "params") {
                            return new kq.Convert({
                                compares: {
                                    fid: "funcId",
                                    rid: "billId",
                                    appid: "appId",
                                    vid: ["viewID", "viewId"],
                                    did: "deptId",
                                    ftype: "funType",
                                    uid: ["userId", "RootBillID", "uid"],
                                    /** 转单号 */
                                    transferId: "svcId",
                                },
                            }).convert(JSON.parse(value));
                        }
                        return value;
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取通知公告信息列表
     * @returns {Promise<[object]>} 成功返回对象数组，失败抛错
     */
    async getNoticeList() {
        return this.request({
            url: "/api/common/dict/queryNoticeList"
        }, arguments[0])
    }
    /**
     * 查询预警消息配置
     * @param {object} param0 消息对象
     * @returns {object|void}
     * @example
     * kq.Request.Message.findWarn({childType: 2})
     */
    static findWarn({ warnType, childType, type }) {
        childType = childType || warnType;
        if (!childType && !type) {
            return;
        }
        return _.find(
            Message.warnType,
            ({ type: $type, childType: $childType }) => {
                if (childType) {
                    return Tools.isEqual(childType, $childType);
                }
                return Tools.isEqual(type, $type);
            }
        );
    }
    /**
     * 预警消息类型
     * @desc 规范化预警消息
     * @returns {object}
     */
    static get warnType() {
        return {
            /** 功能点类型-锁定驳回(被解锁) */
            FUNCTION_LOCK_REJECT: {
                /** 子分类(对应接口返回数据的warnType) */
                childType: 4,
                /** 直接类型(对应接口返回数据的type) */
                type: "COMMON",
                /**
                 * 打开类型
                 * @param function 直接打开功能点
                 * @param app 直接打开应用
                 * @param state 使用wxpush进行页面跳转
                 * @param route 打开指定路由
                 */
                openType: "function",
            },
            /** 转单 */
            TRANSFER: {
                type: "COMMON_NOTIFY_ZHUANDAN",
                childType: 1,
                openType: "route",
                route({ warnId, params }) {
                    return {
                        name: "r-transfer-read",
                        query: { ...params, warnId },
                    };
                },
            },
            /** 下一个流程 */
            FUNCTION_NEXT_PROCESS: {
                type: "NEXT_FLOW_WAR",
                childType: 2,
                openType: "function",
            },
            /** 社保公积金 */
            SHEBAO: {
                type: "COMMON_NOTIFY_SOCIAL",
                openType: "route",
                route: { name: "shebao", query: true },
            },
            /** 日报周报 */
            DAILY_AND_WEEEKLY: {
                type: "DAILY_AND_WEEEKLY",
                childType: 9,
                openType: "dialog",
            },
            /** 查看转单打印表 */
            TRANSFERREVIEW: {
                type: "COMMON_NOTIFY_ZHUANDAN_COPY",
                childType: 8,
                openType: "route",
                route({ warnId, params }) {
                    return {
                        name: "r-transfer-reivew",
                        query: { ...params, warnId },
                    };
                },
            },
        };
    }
}