const Config = {
    /**
     * 系统的key列表
     * @desc 通过更改此配置来增加或减少关键项
     */
    systemKeys: {
        key: {
            zname: "KEY",
            help: "配置项的唯一key值(一但定好,无需维护)",
            type: "text-text",
            sort: 0,
            readonly: true,
            value: "",
        },
        type: {
            zname: "类型(type)",
            help: "配置项的类型(一但定好,无需维护)",
            type: "text-text",
            sort: 1,
            readonly: true,
            value: "",
        },
        name: {
            zname: "配置名称(name)",
            help: "配置项简称",
            type: "text-text",
            sort: 2,
            required: true,
            value: "",
        },
        explain: {
            zname: "说明(explain)",
            help: `针对配置项的说明\n以下点很重要:\n<span class="v-error-color">1.比如是对配置项的详细说明(包含用途,针对功能)\n2.如果值为功能点号或码值号等等数字型的配置一定要说明数据的获取方法</span>`,
            type: "text-big",
            sort: 3,
            required: true,
            value: "",
        },
        test: {
            zname: "测试方法(test)",
            help: `针对配置项如果测试方法的补充说明\n<span class="v-warning-color">配置项涉及到复杂的业务逻辑时,请务必维护此项,否则可能会出现更改后莫名的bug</span>`,
            type: "text-big",
            sort: 4,
            value: "",
        },
        value: {
            zname: "值",
        },
    },
    /**
     * 配置项值类型对照表
     * @desc 通过增减此配置项来扩展值类型
     */
    valueTypeCompares: {
        string: {
            key: "string",
            /** 字段名 */
            zname: "值-字符串",
            /** inputcell字段类型 */
            type: "text-text",
            /** 码表排序 */
            sort: 10,
            /** 默认值 */
            value: "",
            /**
             * 判断是否是当前类型
             * @param {object} param 参数对象
             * @param {string|void} [param.type] 参数类型
             * @param {any} param.value 参数值
             * @returns {boolean} true:是当前类型
             */
            is: ({ type, value }) =>
                type === "string" ||
                (type === undefined &&
                    _.isString(value) &&
                    !/^http/.test(value)),
        },
        boolean: {
            key: "boolean",
            zname: "值-开关",
            type: "select-single-1",
            selectList: [
                { label: "开", value: true },
                { label: "关", value: false },
            ],
            sort: 20,
            value: false,
            is: ({ type, value }) =>
                type === "boolean" ||
                value === "true" ||
                value === "false" ||
                type === "boolean" ||
                _.isBoolean(value),
            formatValue: ({ value }) =>
                value === "true" || value === true ? true : false,
        },
        number: {
            key: "number",
            zname: "值-数值",
            type: "text-number",
            sort: 21,
            value: "",
            is: ({ type, value }) =>
                type === "number" || (type === undefined && _.isNumber(value)),
        },
        array: {
            key: "array",
            zname: "值-数组",
            type: "array",
            value: { headers: [], values: [] },
            sort: 30,
            is: ({ type, value }) =>
                type === "array" ||
                (type === undefined &&
                    value &&
                    _.isPlainObject(value) &&
                    value.headers),
            config: {
                valueTypeCompares: {},
                systemKeys: {},
            },
        },
        file: {
            key: "file",
            zname: "值-附件",
            type: "file-file-single",
            sort: 40,
            value: "",
            is: ({ type, value }) =>
                type === "file" ||
                (type === undefined &&
                    _.isString(value) &&
                    /^http/.test(value)),
        },
        children: {
            key: "children",
            zname: "值-子对象",
            sort: 50,
            is: ({ type, value }) =>
                type === "children" ||
                (type === undefined && value === undefined),
        },
    },
};

Config.valueTypeCompares.array.config.valueTypeCompares = {
    string: Config.valueTypeCompares.string,
    boolean: Config.valueTypeCompares.boolean,
    file: Config.valueTypeCompares.file,
};

Config.systemKeys = _.cloneDeep(Config.systemKeys);

/**
 * 获取值类型
 * @param {object} config 参数对象
 * @returns {object|void}
 */
function getValueType(config, compares = Config.valueTypeCompares) {
    let result = null;
    _.some(compares, ({ is }, key) => {
        if (_.isFunction(is) && is(config)) {
            result = compares[key];
        }
        return result;
    });
    return result;
}

export default Config;
export { getValueType };
