/**
 * @description 系统级的相关信息
 * @date 2023-02-08
 * @author maxi
 */

export default {
    namespaced: true,
    state: {
        /** 当前所在页面名称 */
        pageName: "",
        /** 当前系统名称 */
        systemName: "",
    },
    mutations: {
        /** 当前页面名称 */
        set_current_page_name(state, pageName) {
            state.pageName = pageName;
        },
        /** 当前系统名称 */
        set_system_name(state, systemName) {
            state.systemName = systemName;
        },
    },
};
