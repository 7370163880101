// import "./Utils/fetch";
import "./Jquery";
import "./Prototype";
import Base from "./Mixin/Base/KqBase.vue";
import Notify from "./Notify/Notify.vue";
import Loading from "./Loading/Loading.vue";
import vModel from "./Mixin/vModel/vModel.vue";
import Nest from "./Mixin/Nest/Nest.vue";
import NestItem from "./Mixin/Nest/NestItem.vue";
import Tab from "./Mixin/Tab/Tab.vue";
import TabItem from "./Mixin/Tab/TabItem.vue";
import Empty from "./Empty/Empty.vue";
import { registerCmp } from "../Tool/tool.js";
import ColorDirective from "./Directive/Color.js";
import IconDirective from "./Directive/Icon";
import TurnPage from "./Mixin/Data/Page.vue";
import TurnPageMixin from "./Mixin/Data/TurnPage.vue";
import FormChangeMixin from "./Mixin/Data/FormChange.vue";
import FormLogin from "./Form/Login/Login.vue";
import EasyTip from "./Tip/EasyTip/EasyTip.vue";
import Tools from "./Utils/tools";
import MountDirective from "./Directive/Mount.js";
import SearchScale from "./Search/Scale.vue";
import Square from "./Square/Square.vue";
import Data from "./Mixin/Data/Data.vue";
import DataPage from "./Mixin/Data/DataPage.vue";
import Request from "./Request/index.js";
import ScrollTab from "./Mixin/Tab/ScrollTab.vue";
import ScrollTabItem from "./Mixin/Tab/ScrollTabItem.vue";
import Console from "./Utils/console";
import ScrollNav from "./Mixin/Nav/ScrollNav.vue";
import ScrollNavItem from "./Mixin/Nav/ScrollNavItem.vue";
import Err from "./Utils/Err";
import Convert from "./Mixin/Data/Convert/index.js";
import ConvertExtend from "./Mixin/Data/Convert/Extend.js";
import FormConfig from "./Form/config.js";
import ConvertConfig from "./config.js";
import Preload from "./Preload/Preload.vue";
import Plugin from "./Utils/Plugin";
import Custom from "./Utils/Plugin/custom.js";
import PluginOld from "./Utils/PluginOld";
import CustomOld from "./Utils/PluginOld/custom.js";
import Files from "./File/File.js";
import Filter from "./Filter/Filter.js";
import PermissionMixin from "./Permission/Permission.vue";
import ProcessMixin from "./Mixin/Process/Process.vue";
import ProcessMixin2 from "./Mixin/Process/KqProcessMixin.vue";
import Course from "./Course/Course.vue";
import FormLoginForm from "./Form/Login/Form.vue";
import Cache from "./cache/cache.js";
import FormValidator from "kooci-lib/src/PubComponents/Form/Validator/Validator";
import FormHistoryMixin from "./Mixin/Form/History/History.vue";
import LinkageMixin from "./Mixin/Form/Linkage/KqLinkage.vue";
import ValidatorMixin from "./Mixin/Form/Validator/KqValidator.vue";
import SearchBottom from "./Search/Bottom.vue";
import Router from "./Router/Router.js";
import RouterModule from "./Router/Module.js";
import RouterNavgation from "./Router/Navgation.js";
import DirectiveResize from "./Directive/Resize.js";
import Store from "./Store/Store.js";
import StoreModule from "./Store/Module";
import StoreUser from "./Store/User";
import StoreConfig from "./Store/Config";
import StoreChange from "./Store/Change";
import StoreApp from "./Store/App";
import StoreView from "./Store/View";
import StoreChildSystem from "./Store/ChildSystem";
import Button from "./Button/Button";
import ListDataMixin from "./Mixin/List/KqListMixin.vue";
import DetailDataMixin from "./Mixin/Detail/KqDetailMixin.vue";
import BarBtnMixin from "./Mixin/BarBtn/BarBtnMixin.vue";
import AutoScrollOldMixin from "./Mixin/Scroll/AutoScrollOld.vue";
import LoadScript from "./Utils/LoadScript";
import CacheInterval from "./cache/CacheInterval";

const components = {
    Base,
    vModel,
    Notify,
    Loading,
    Nest,
    NestItem,
    Tab,
    TabItem,
    Empty,
    ColorDirective,
    IconDirective,
    FormChangeMixin,
    FormLogin,
    FormLoginForm,
    EasyTip,
    Tools,
    MountDirective,
    SearchScale,
    Square,
    TurnPage,
    TurnPageMixin,
    Data,
    DataMixin: Data,
    DataPage,
    Request,
    ScrollTab,
    ScrollTabItem,
    Console,
    ScrollNav,
    ScrollNavItem,
    Err,
    Convert,
    ConvertExtend,
    FormConfig,
    Preload, // 预渲染组件
    Plugin,
    Custom,
    PluginOld, // js个性化插件
    CustomOld, // 自定义操作类
    File: Files,
    Filter,
    PermissionMixin, // 权限混入类
    ProcessMixin, // 流程混入
    ProcessMixin2,
    Course, // 教程组件
    Cache, // 缓存机制
    FormValidator, // 表单验证规则
    FormHistoryMixin, // 表单历史记录
    LinkageMixin,
    ValidatorMixin,
    SearchBottom, // 底部查询栏
    ConvertConfig,
    Router,
    RouterModule,
    RouterNavgation,
    DirectiveResize, // dom重置尺寸监听指令
    Store,
    StoreModule,
    StoreUser,
    StoreConfig,
    StoreChange,
    StoreApp,
    StoreView,
    StoreChildSystem,
    Button,
    ListDataMixin,
    DetailDataMixin,
    BarBtnMixin,
    AutoScrollOldMixin,
    LoadScript,
    CacheInterval,
};

const kqPub = registerCmp("kqPub", components);

export default kqPub;
export {
    Base,
    vModel,
    Notify,
    Loading,
    Nest,
    NestItem,
    Tab,
    TabItem,
    Empty,
    DataPage,
    FormLogin,
    EasyTip,
    Tools,
    MountDirective,
    SearchScale,
    Square,
    ScrollTab,
    ScrollTabItem,
    Console,
    ScrollNav,
    ScrollNavItem,
    Err,
    DirectiveResize,
    ListDataMixin,
    BarBtnMixin,
};
