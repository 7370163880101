/**
 * 码表相关
 * @see 码表查询,修改,配置
 */

export default class Select {
    /**
     * 获取码表项
     * @param {object} param
     * @param {string|number} [param.zid] 字段号
     * @param {string} [param.zlabel] 字段别名
     * @returns {Promise<[object]>} 成功返回码表数据表,失败抛错
     */
    getSelectList({ zid, zlabel }) {
        if (!zid && !zlabel) {
            throw new kq.Err({
                title: "参数错误",
                message: "字段号(zid)与字段别名(zlabel)不能同时为空字符串",
                from: "Request.getSelectList",
                console: false,
            });
        }
        let url, params;
        if (zid) {
            url = "/api/common/dict/getDict";
            params = {
                nodeId: zid,
            };
        } else {
            url = "/api/common/dict/labelDict";
            params = {
                label: zlabel,
            };
        }
        return this.request({ url, params, convert: url }, arguments[0]);
    }
    /**
     * 获取用户表
     * @param {object} param 参数表
     * @param {string} [param.fuzzyQuery] 模糊查询参数
     * @returns {Promise<[object]>} 失败抛错
     * @example [{uname<string>: 用户名, wid<number>: 工号, dname<string>: "部门", info<string>: 组合字符串, uid<number>: 用户号, label<string>: 提供给selectList使用的label(info), value<number>: 提供给selectList使用的value值(uid)}]
     */
    async getUserList({ fuzzyQuery, uidList }) {
        if (fuzzyQuery === undefined && _.isEmpty(uidList)) {
            return [];
        } else {
            if (fuzzyQuery !== undefined) {
                const userList = await this.request(
                    {
                        url: `/api/process/getPersonJson?gntype=225&pagesize=-1&page=-1&encode=1&parvalue=-2000$${fuzzyQuery}`,
                        method: "post",
                    },
                    arguments[0]
                ).catch((err) => {
                    throw err;
                });
                return userList.map(({ col1: info, col2: uid }) => {
                    const [uname, wid, dname] = (info || "").split(",");
                    return {
                        uname,
                        wid,
                        dname,
                        info,
                        uid,
                        label: info,
                        value: uid,
                    };
                });
            } else {
                return this.request(
                    {
                        url: "/api/process/getPersonByIds",
                        method: "post",
                        data: uidList,
                        convert: {
                            compares: {
                                label: "xm",
                                uname: "userName",
                                wid: "userCode",
                                dname: "deptName",
                                value: "nodeid",
                            },
                        },
                    },
                    arguments[0]
                );
            }
        }
    }
}
