export default {
    /**
     * 按钮格式化
     * @param {object} btn 按钮对象
     * @returns {object} 格式化后的按钮对象
     */
    formatBtn(btn) {
        let {
            props = {},
            tooltip,
            component,
            popover,
            confirm,
            loading,
            outClick,
            onClick,
            css = "",
            child,
        } = btn;
        // 转换props参数
        const { props: formatProps } = this._formatProps(btn);
        // 转换提示参数
        tooltip = this._formatTooltip(tooltip);
        // 转换确认参数
        confirm = this._formatConfirm(confirm);
        // 没有注册yes的prop则使用onClick
        if (!_.isFunction(confirm.props.yes)) {
            confirm.props.yes = onClick;
        }
        let btnClick = onClick;
        // 需要确认:屏蔽默认点击函数
        btnClick = (param) => {
            if (confirm.disableHandler(btn)) {
                return onClick && onClick(param);
            }
        };
        child = this._formatChild(child);
        loading = props.loading || loading;
        return {
            from: btn,
            ...btn,
            css,
            outClick: outClick || (() => {}),
            onClick: btnClick || (() => {}),
            loading,
            loadHandler: _.isFunction(loading) ? loading : () => loading,
            props: formatProps,
            component: this._formatComponent(component),
            confirm,
            tooltip,
            child,
            popover: this._formatPopover(popover, btn),
        };
    },
    _formatPopover(popover = {}, btn) {
        const component = this._formatComponent(popover.component);
        const { props = {}, listeners = {} } = popover;
        const result = {
            props: { ...props },
            listeners: { ...listeners },
            component,
        };
        // 合并props属性
        _.forEach(popover, (value, key) => {
            if (!["props", "listeners", "component"].includes(key)) {
                result.props[key] = value;
            }
        });
        // 转换禁用属性
        let disableHandler = () => false;
        if (_.isBoolean(result.props.disabled)) {
            disableHandler = () => result.props.disabled;
        } else if (_.isFunction(result.props.disabled)) {
            disableHandler = () => result.props.disabled(btn);
        }
        result.props.disableHandler = disableHandler;
        return result;
    },
    _formatChild(child = {}) {
        let disableHandler;
        let btnList = child.btnList || [];
        const btnEmpty = _.isEmpty(btnList);
        const props = child.props || {};
        const listeners = child.listeners || {};
        const disabled = props.disabled || child.disabled;
        if (disabled === undefined) {
            disableHandler = () => btnEmpty;
        } else if (_.isFunction(disabled)) {
            disableHandler = (arg) => btnEmpty || disabled(arg);
        } else {
            disableHandler = () => btnEmpty || disabled;
        }
        if (!_.isEmpty(btnList)) {
            btnList = btnList.map((btn) => this.formatBtn(btn));
        }
        props.disableHandler = disableHandler;
        return {
            ...child,
            props,
            listeners,
            btnList,
        };
    },
    _formatTooltip(tooltip = {}) {
        const { props = {}, listeners = {} } = tooltip;
        const result = {
            props: { ...props },
            listeners: { ...listeners },
        };
        _.forEach(tooltip, (value, prop) => {
            if (!["props", "listeners"].includes(prop)) {
                result.props[prop] = value;
            }
        });
        return result;
    },
    _formatComponent(component = {}) {
        const {
            props = {},
            is = "",
            listeners = {},
            lazy,
            vshow,
            ref,
        } = component;
        const result = {
            props: { ...props },
            listeners: { ...listeners },
            is,
            lazy,
            vshow,
            ref,
        };
        _.forEach(component, (value, prop) => {
            // 保留字排除
            if (
                !["props", "listeners", "is", "lazy", "vshow", "ref"].includes(
                    prop
                )
            ) {
                result.props[prop] = value;
            }
        });
        return result;
    },
    _formatConfirm(confirm) {
        const { props = {}, listeners = {} } = confirm || {};
        const result = {
            props: { ...props },
            listeners: { ...listeners },
        };
        let disabled;
        if (confirm) {
            disabled = confirm.disabled;
            if (confirm.props && confirm.props.disabled !== undefined) {
                disabled = confirm.props.disabled;
            }
        }
        let disableHandler = disabled;
        if (!_.isFunction(disabled)) {
            disableHandler = () => {
                // disabled未赋值
                if (confirm === undefined) {
                    return true;
                }
                // disabled赋值
                else {
                    return disabled;
                }
            };
        }
        result.disableHandler = disableHandler;
        _.forEach(confirm, (value, prop) => {
            if (!["props", "listeners"].includes(prop)) {
                result.props[prop] = value;
            }
        });
        // 未指定禁用属性: 设为禁用
        if (_.isEmpty(result.props)) {
            result.props.disabled = true;
        }
        return result;
    },
    _formatProps(btn) {
        const { props = {} } = btn;
        const { disabled } = props;
        let disableHandler = props.disableHandler || disabled;
        if (!_.isFunction(disabled)) {
            disableHandler = () => disabled;
        }
        const result = { props: { disableHandler } };
        _.forEach(props, (value, prop) => {
            // loading作为保留字是因为吧loading抽到外面了
            if (!["disabled", "loading"].includes(prop)) {
                result.props[prop] = value;
            }
        });
        _.forEach(btn, (prop, key) => {
            if (
                ![
                    "disabled",
                    "confirm",
                    "component",
                    "props",
                    "popover",
                    "onClick",
                    "child",
                    "outClick",
                ].includes(key)
            ) {
                result.props[key] = prop;
            }
        });
        return result;
    },
};
